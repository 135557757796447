import React, { useState, useContext, useEffect } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import Button from '@mui/material/Button';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import { indigo, green, red } from '@mui/material/colors';
import { useFieldChangeEsp } from './useFieldChangeEsp';
import api from '@/services/api';
import { useParams } from 'react-router-dom';
import AlertContext from '../Alert/AlertContext';
import { useCustomQueryClient } from './hooks/useCustomQueryClient';
import { useQueryClient } from '@tanstack/react-query';

function NotificationsPosForm({ initialNotifications = [], handleChange, expanded, panel, path, useLauncherfyById }) {
  const [btnStatus, setBtnStatus] = useState(false);
  const { showAlert } = useContext(AlertContext);
  const { id } = useParams();
  const { data: launching, isSuccess } = useLauncherfyById(id);
  const queryClient = useQueryClient();
  const invalidate = useCustomQueryClient(queryClient)

  const { register, handleSubmit, control, setValue, getValues, reset } = useForm({
    defaultValues: {
      notificationsPos: initialNotifications.length > 0
        ? initialNotifications.map(notification => ({ notification }))
        : [{ notification: '' }],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'notificationsPos',
  });
  const { fields: fieldsWa, append: appendWa, remove: removeWa } = useFieldArray({
    control,
    name: 'notificationsPosWa',
  });

  const { handleFieldChange, totalCharacterCount, allFieldsFilled } = useFieldChangeEsp(getValues, setValue, setBtnStatus, 'notification');

  const handleAddNotification = () => {
    if (fields.length < 15) {
      append({ notification: '' });
    }
  };

  const handleAddNotificationWa = () => {
    if (fieldsWa.length < 15) {
      appendWa({ notification: '' });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isSuccess && launching && launching.notificationsPos) {
          const notificationArray = JSON.parse(launching.notificationsPos);
          const notificationsPos = notificationArray.notification.slice(0, 6).map(notification => ({ notification: notification }));
          const notificationsPosWa = notificationArray.notificationsPosWa.slice(0, 6).map(notification => ({ notification: notification }));

          reset({
            notificationsPos: notificationsPos.length > 0 ? notificationsPos : [{ notification: '' }],
            notificationsPosWa: notificationsPosWa.length > 0 ? notificationsPosWa : [{ notification: '' }],
          });

          handleFieldChange();
          setBtnStatus(false);
        }
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };
    fetchData();
  }, [id, reset, handleFieldChange, launching, isSuccess]);



  const onSubmit = async (data) => {
    try {
      const notificationsPos = data.notificationsPos.map(notification => notification.notification);
      const notificationsPosWa = data.notificationsPosWa.map(notification => notification.notification);
      const combinedObject = { notification: notificationsPos, notificationsPosWa: notificationsPosWa };
      const combinedString = JSON.stringify(combinedObject);

      console.log(combinedString)

      await api.patch(`/api/${path}/${id}/`, {
        notificationsPos: combinedString,
      });

      invalidate.invalidateQueries()
      setBtnStatus(false);
      showAlert("Dados atualizados com sucesso!", "success");

    } catch (error) {
      console.error("Erro ao atualizar os dados: ", error);
      showAlert("Ocorreu um erro ao tentar atualizar os dados. Por favor, tente novamente.", "error");
    }
  };

  return (
    <Accordion expanded={expanded === `panel${panel}`} onChange={handleChange(`panel${panel}`)}
      sx={{ borderRadius: 2, mb: 0.2, p: 0.5, position: 'initial', border: `solid 1px ${indigo[100]}` }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${panel}bh-content`}
        id={`panel${panel}bh-header`}
        sx={{
          // background: `linear-gradient(to right, ${indigo[200]}, ${indigo[400]})`,
          borderRadius: 2,
          minHeight: 0,
          ml:1,
          '&.Mui-expanded': {
            minHeight: 0,
          },
          // '&::before': {content: '""', position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)', width: 10, height: 10, borderRadius: '50%', backgroundColor: allFieldsFilled ? green[500] : red[400]},
          '&::before': {content: '""', position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)', width: 10, height: 10, borderRadius: '50%', backgroundColor: allFieldsFilled ? green[500] : red[400]},
        }}
      >
        <Typography sx={{ width: '100%', flexShrink: 0 }}>
          Notificações pós lançamento (Última chamado)
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 1, pt: 2 }}>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            gap: 1,            
          }}
        > 
          <Box sx={{ backgroundColor: indigo[50], p: 2, borderRadius: 2 }}>
            <Grid container spacing={1}>
              {fields.map((item, index) => (
                <Grid key={item.id} item xs={12} md={6}>
                  <Typography variant="h6" sx={{ alignSelf: 'flex-start' }}>
                    {index === 0 ? 'Notificação e-mail' : `Notificação e-mail ${index + 1}`}
                  </Typography>
                  <TextareaAutosize
                    {...register(`notificationsPos.${index}.notification`)}
                    placeholder={index === 0 ? 'Notificação e-mail' : `Notificação e-mail ${index + 1}`}
                    minRows={3}
                    style={{
                      width: '100%',
                      height: '100px',
                      borderRadius: '4px',
                      borderColor: indigo[400],
                      padding: '8px',
                      fontFamily: 'Roboto',
                      font: 'inherit',
                    }}
                    onChange={(event) => {
                      handleFieldChange(`notificationsPos.${index}.notification`, event.target.value);
                    }}
                  />
                  {index !== 0 && (
                    <Button
                      variant="outlined"
                      color="error"
                      size="medium"
                      onClick={() => {
                        remove(index);
                        setBtnStatus(true);
                      }}
                    >
                      <Typography variant="h1">-</Typography>
                    </Button>
                  )}
                </Grid>
              ))}
              {fieldsWa.map((item, index) => (
                <Grid key={item.id} item xs={12} md={6}>
                  <Typography variant="h6" sx={{ alignSelf: 'flex-start' }}>
                    {index === 0 ? 'Notificação whatsapp' : `Notificação whatsapp ${index + 1}`}
                  </Typography>
                  <TextareaAutosize
                    {...register(`notificationsPosWa.${index}.notification`)}
                    placeholder={index === 0 ? 'Notificação whatsapp' : `Notificação whatsapp ${index + 1}`}
                    minRows={3}
                    style={{
                      width: '100%',
                      height: '100px',
                      borderRadius: '4px',
                      borderColor: '#25D366',
                      padding: '8px',
                      fontFamily: 'Roboto',
                      font: 'inherit',
                    }}
                    onChange={(event) => {
                      handleFieldChange(`notificationsPosWa.${index}.notification`, event.target.value);
                    }}
                  />
                  {index !== 0 && (
                    <Button
                      variant="outlined"
                      color="error"
                      size="medium"
                      onClick={() => {
                        removeWa(index);
                        setBtnStatus(true);
                      }}
                    >
                      <Typography variant="h1">-</Typography>
                    </Button>
                  )}
                </Grid>
              ))}
            </Grid>

          </Box>
          
          
          <Typography>
            Caracteres: {10000 - totalCharacterCount}
          </Typography>
          <Divider />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              gap: 1,
            }}
          >
            <Button variant="contained" onClick={handleAddNotification} disabled={fields.length === 40}>
              + Adicionar Notificação
            </Button>
            <Button variant="contained" onClick={handleAddNotificationWa} disabled={fieldsWa.length === 40} sx={{ backgroundColor: '#25D366', '&:hover': { backgroundColor: "#139445"}}}>
              + Adicionar Notificação whatsapp
            </Button>
            {btnStatus ? (
              <Button type="submit">Salvar</Button>
            ) : (
              <Button disabled>Salvar</Button>
            )}
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

export default NotificationsPosForm;
