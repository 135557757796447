import { useQuery } from "@tanstack/react-query";
import api from "@/services/api";

function useFormulaById(id) {
  return useQuery({
    queryKey: ["formulaById",id],
    queryFn: () => api.get(`/api/formulalaunchings/${id}`).then((response) => response.data),
    enabled: !!id
  });
}

export default useFormulaById;
