import React from "react";
import Creatives from "./Creatives";
import RegistrationListForm from "./RegistrationListForm";
import EventForm from "./EventForm";
import RegistrationDomainForm from "./RegistrationDomainForm";
import CreativesRecForm from "./CreativesRecForm";
import CapPageForm from "./CapPageForm";
import ThankPageForm from "./ThankPageForm";
import WhatsForm from "./WhatsForm";
import TrafficTable from "./TrafficTable";
import LaunchingScriptForm from "./LaunchingScriptForm";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useMapaLxById from "../../queries/useMapaLxById";
import useSharedMListId from "@/pages/Shared/queries/useSharedMListId";
import CreateWhastsForm from "./CreateWhastsForm";
import { useParams } from "react-router-dom";
import Positioning from "./00-Positioning";
import Approach from "./00-Approach";
import Negotiation from "./00-Negotiation";
import Search from "./01-Search";
import NicheForm from "./NicheForm";
import PromiseForm from "./PromiseForm";
import Traffic from "./01-Traffic";
import PastTrafficTable from "./PastTrafficTable";
import Meta from "./01-Meta";
import Google from "./01-Google";
import Design from "./01-Design";
import Content from "./01-Content";
import Taccoh from "./01-Taccoh";
import Funil from "./02-Funil";
import TrafficTwo from "./02-Traffic";
import Infra from "./02-Infra";
import ContentTwo from "./02-Content";
import DesignWeb from "./02-DesignWeb";
import Comunication from "./03-Comunication";
import Anticipation from "./03-Anticipation";
import Pages from "./03-Pages";
import Remarketing from "./03-Remarketing";
import Today from "./03-Today";
import TrafficThree from "./03-Traffic";
import Heating from "./03-Heating";
import InfraTwo from "./03-Infra";
import DesignThree from "./03-Design";
import Event from "./04-Event";
import EventAdv from "./04-EventAdv";
import EventAdvRmk from "./04-EventAdvRmk";
import SalesPageForm from "./SalesPageForm";
import SalesVideoScriptForm from "./SalesVideoScriptForm";
import Sales from "./04-Sales";
import TrafficFour from "./04-Traffic";
import DesignFour from "./04-Design";
import InfraThree from "./04-Infra";
import Copy from "./05-Copy";
import InfraFour from "./05-Infra";
import TrafficFive from "./05-Traffic";
import Tools from "./05-Tools";
import Last from "./06-Last";
import PersonaForm from "./PersonaForm";

function RenderStepMapaLx({steps}) {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const { option } = useParams();

  const apiHook = option === "launchings" ? useMapaLxById : useSharedMListId;

  let launching = apiHook;
  let path

  if (option === "launchings") {
    path = "mapalxlaunchings"
  }else{
    path = "mapalxshared"
  }

  switch (steps) {
    case 0:
      return (
        <Box sx={{ gap: 1, display: 'flex', flexDirection: "column" }}>
          <Box sx={{ p: 1, borderRadius: 2, border: `solid 1px #000`, backgroundColor: "#212121", color: "#fff" }}>
            <Typography variant="h4" sx={{ p: 0.5 }}>P.A.N</Typography>
            <Positioning path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="1" />
            <Approach path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="2" />
            <Negotiation path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="3" />
          </Box>
        </Box>
      )
    case 1:
      return (
        <Box sx={{ gap: 1, display: 'flex', flexDirection: "column" }}>
          <Box sx={{ p: 1, borderRadius: 2, border: `solid 1px #63C84A`, backgroundColor: "#c7edbd" }}>
            <Typography variant="h4" sx={{ p: 0.5 }}>Copy</Typography>
            <Search path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="1" />
            <NicheForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="2" />
            <PersonaForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="5" />
            <EventForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="3" />
            <PromiseForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="4" />
          </Box>
          <Box sx={{ p: 1, borderRadius: 2, border: `solid 1px #63C84A`, backgroundColor: "#c7edbd" }}>
            <Typography variant="h4" sx={{ p: 0.5 }}>Tráfego</Typography>
            <PastTrafficTable path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="5" />
            <Traffic path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="6" />
            <Meta path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="7" />
            <Google path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="10" />
            <RegistrationListForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="8" />
            <TrafficTable path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="9" />
          </Box>
          <Box sx={{ p: 1, borderRadius: 2, border: `solid 1px #63C84A`, backgroundColor: "#c7edbd" }}>
            <Typography variant="h4" sx={{ p: 0.5 }}>Design</Typography>
            <Design path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="11" />
          </Box>
          <Box sx={{ p: 1, borderRadius: 2, border: `solid 1px #63C84A`, backgroundColor: "#c7edbd" }}>
            <Typography variant="h4" sx={{ p: 0.5 }}>Conteúdo</Typography>
            <Content path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="12" />
            <Taccoh path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="13" />
          </Box>
        </Box>
      )
    case 2:
      return (
        <Box sx={{ gap: 1, display: 'flex', flexDirection: "column" }}>
          <Box sx={{ p: 1, borderRadius: 2, border: `solid 1px #ccc`, backgroundColor: "#eee" }}>
            <Typography variant="h4" sx={{ p: 0.5 }}>Funil</Typography>
            {/* <ConfigForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="1" /> */}
            <CreateWhastsForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="2" />
            <CapPageForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="3" />
            <ThankPageForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="4" />
            <WhatsForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="5" />
            <Funil path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="7" />
            <Creatives path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="8" />
            <CreativesRecForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="9" />
          </Box>
          <Box sx={{ p: 1, borderRadius: 2, border: `solid 1px #ccc`, backgroundColor: "#eee" }}>
            <Typography variant="h4" sx={{ p: 0.5 }}>Tráfego</Typography>
            <TrafficTwo path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="10" />
            <TrafficTable path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="11" />
          </Box>
          <Box sx={{ p: 1, borderRadius: 2, border: `solid 1px #ccc`, backgroundColor: "#eee" }}>
            <Typography variant="h4" sx={{ p: 0.5 }}>Infraestrutura</Typography>
            <RegistrationDomainForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="12" />
            <Infra path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="13" />
          </Box>
          <Box sx={{ p: 1, borderRadius: 2, border: `solid 1px #ccc`, backgroundColor: "#eee" }}>
            <Typography variant="h4" sx={{ p: 0.5 }}>Conteúdo</Typography>
            <ContentTwo path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="14" />
          </Box>
          <Box sx={{ p: 1, borderRadius: 2, border: `solid 1px #ccc`, backgroundColor: "#eee" }}>
            <Typography variant="h4" sx={{ p: 0.5 }}>Design</Typography>
            <DesignWeb path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="15" />
          </Box>
        </Box>
      )
    case 3:
      return (
        <Box sx={{ gap: 1, display: 'flex', flexDirection: "column" }}>
          <Box sx={{ p: 1, borderRadius: 2, border: `solid 1px #db74f3`, backgroundColor: "#eebefa" }}>
            <Typography variant="h4" sx={{ p: 0.5 }}>Copy</Typography>
            <Anticipation path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="1" />
            <Comunication path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="2" />
            <Pages path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="3" />
            <Remarketing path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="4" />
            <Today path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="6" />
          </Box>
          <Box sx={{ p: 1, borderRadius: 2, border: `solid 1px #db74f3`, backgroundColor: "#eebefa" }}>
            <Typography variant="h4" sx={{ p: 0.5 }}>Tráfego</Typography>
            <TrafficThree path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="7" />
          </Box>
          <Box sx={{ p: 1, borderRadius: 2, border: `solid 1px #db74f3`, backgroundColor: "#eebefa" }}>
            <Typography variant="h4" sx={{ p: 0.5 }}>Infra</Typography>
            <InfraTwo path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="8" />
          </Box>
          <Box sx={{ p: 1, borderRadius: 2, border: `solid 1px #db74f3`, backgroundColor: "#eebefa" }}>
            <Typography variant="h4" sx={{ p: 0.5 }}>Aquecimento</Typography>
            <Heating path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="9" />
          </Box>
          <Box sx={{ p: 1, borderRadius: 2, border: `solid 1px #db74f3`, backgroundColor: "#eebefa" }}>
            <Typography variant="h4" sx={{ p: 0.5 }}>Design</Typography>
            <DesignThree path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="10" />
          </Box>          
        </Box>
      ) 
    case 4:
      return (
        <Box sx={{ gap: 1, display: 'flex', flexDirection: "column" }}>
          <Box sx={{ p: 1, borderRadius: 2, border: `solid 1px #a06ee3`, backgroundColor: "#bca3de" }}>
            <Typography variant="h4" sx={{ p: 0.5 }}>Copy</Typography>
            <Event path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="1" />
            <LaunchingScriptForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="2" />
            <EventAdv path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="3" />
            <SalesPageForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="4" />
            <SalesVideoScriptForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="5" />
            <EventAdvRmk path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="6" />
            <Sales path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="7" />
          </Box>
          <Box sx={{ p: 1, borderRadius: 2, border: `solid 1px #a06ee3`, backgroundColor: "#bca3de" }}>
            <Typography variant="h4" sx={{ p: 0.5 }}>Tráfego</Typography>
            <TrafficFour path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="8" />
          </Box>
          <Box sx={{ p: 1, borderRadius: 2, border: `solid 1px #a06ee3`, backgroundColor: "#bca3de" }}>
            <Typography variant="h4" sx={{ p: 0.5 }}>Design / Web</Typography>
            <DesignFour path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="9" />
          </Box>
          <Box sx={{ p: 1, borderRadius: 2, border: `solid 1px #a06ee3`, backgroundColor: "#bca3de" }}>
            <Typography variant="h4" sx={{ p: 0.5 }}>Infra</Typography>
            <InfraThree path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="10" />
          </Box>
        </Box>
      )   
    case 5:
      return (
        <Box sx={{ gap: 1, display: 'flex', flexDirection: "column" }}>
          <Box sx={{ p: 1, borderRadius: 2, border: `solid 1px #94d5e4`, backgroundColor: "#ccf5ff" }}>
            <Typography variant="h4" sx={{ p: 0.5 }}>Copy</Typography>
            <Copy path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="1" />            
          </Box>
          <Box sx={{ p: 1, borderRadius: 2, border: `solid 1px #94d5e4`, backgroundColor: "#ccf5ff" }}>
            <Typography variant="h4" sx={{ p: 0.5 }}>Tráfego</Typography>
            <TrafficFive path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="2" />
          </Box>
          <Box sx={{ p: 1, borderRadius: 2, border: `solid 1px #94d5e4`, backgroundColor: "#ccf5ff" }}>
            <Typography variant="h4" sx={{ p: 0.5 }}>Infra</Typography>
            <InfraFour path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="3" />
          </Box>
          <Box sx={{ p: 1, borderRadius: 2, border: `solid 1px #94d5e4`, backgroundColor: "#ccf5ff" }}>
            <Typography variant="h4" sx={{ p: 0.5 }}>Ferramentas</Typography>
            <Tools path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="4" />
          </Box>
        </Box>
      ) 
    case 6:
      return (
        <Box sx={{ gap: 1, display: 'flex', flexDirection: "column" }}>
          <Box sx={{ p: 1, borderRadius: 2, border: `solid 1px #cfa158`, backgroundColor: "#fcca7b" }}>
            <Typography variant="h4" sx={{ p: 0.5 }}></Typography>
            <Last path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="1" />
          </Box>
        </Box>
      ) 
    default:
      return (
        <Box sx={{ gap: 1, display: 'flex', flexDirection: "column" }}>
          <Box sx={{ p: 1, borderRadius: 2, border: `solid 1px #000`, backgroundColor: "#212121", color: "#fff" }}>
            <Typography variant="h4" sx={{ p: 0.5 }}>P.A.N</Typography>
            <Positioning path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="1" />
            <Approach path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="2" />
            <Negotiation path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="3" />
          </Box>
        </Box>
      );
  }
}

export default RenderStepMapaLx
