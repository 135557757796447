import React, { useState, useEffect, useContext } from 'react';
import { useForm, useController } from 'react-hook-form';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import { green, grey, indigo, purple, red } from '@mui/material/colors';
import { useFieldChange } from './useFieldChange';
import api from '@/services/api';
import { useParams } from 'react-router-dom';
import AlertContext from '../Alert/AlertContext';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import moment from "moment";
import { useCustomQueryClient } from './hooks/useCustomQueryClient';
import { useQueryClient } from '@tanstack/react-query';

function RegistrationListForm({ handleChange, expanded, panel, path, useLauncherfyById }) {
  const { id } = useParams();
  const [btnStatus, setBtnStatus] = useState(false);
  const { showAlert } = useContext(AlertContext);
  const [period, setPeriod] = useState('');
  const { data: launching, isSuccess } = useLauncherfyById(id);
  const queryClient = useQueryClient();
  const invalidate = useCustomQueryClient(queryClient)

  const handleSelectChange = (event) => {
    const newPeriod = event.target.value;
    periodCapt.onChange(event);
    setPeriod(newPeriod);

    const calculatedStartDate = calculateStartDate(launching?.launchDate, newPeriod);

    setStartDate(calculatedStartDate);
    setBtnStatus(true);
  };

  const { handleSubmit, register, control, getValues, setValue, watch } = useForm({
    defaultValues: {
      periodCapt: '',
      endCapt: '',
      creationDate: '',
      budget: '',
      costLead: '',
      mtck: false,
      ggck: false,
    },
  });

  const { field: mtck } = useController({
    name: 'mtck',
    control,
    defaultValue: false,
  });

  const { field: ggck } = useController({
    name: 'ggck',
    control,
    defaultValue: false,
  });

  const { field: periodCapt } = useController({
    name: 'periodCapt',
    control,
    defaultValue: '',
  });

  const { field: endCapt } = useController({
    name: 'endCapt',
    control,
    defaultValue: '',
  });

  const { field: creationDate } = useController({
    name: 'creationDate',
    control,
    defaultValue: '',
  });

  function calculateWeeksBetweenDates(start, end) {
    const startDate = moment(start, "YYYY-MM-DD");
    const endDate = moment(end, "YYYY-MM-DD");
    const daysDifference = endDate.diff(startDate, 'days');
    return Math.floor(daysDifference / 7);
  }

  const weeksBetween = calculateWeeksBetweenDates(moment(creationDate.value).format("YYYY-MM-DD"), endCapt.value);

  const { allFieldsFilled } = useFieldChange(getValues, setValue, setBtnStatus);
  const onSubmit = async (data) => {
    setBtnStatus(false)
    try {
      await api.patch(`/api/${path}/${id}/`, {        
        periodCapt: data.periodCapt,
        endCapt: launching?.launchDate,
        budget: data.budget,
        costLead: data.costLead,
        mtck: data.mtck,
        ggck: data.ggck     
      });
      invalidate.invalidateQueries()

      setBtnStatus(false);
      showAlert("Dados atualizados com sucesso!", "success");

    } catch (error) {
      console.error("Erro ao atualizar os dados: ", error);
      showAlert("Ocorreu um erro ao tentar atualizar os dados. Por favor, tente novamente.", "error");
    }
  };

  const [startDate, setStartDate] = useState("");

  useEffect(() => {
    if (endCapt.value && period) {
      setStartDate(calculateStartDate(endCapt.value, period));
    }
    
  }, [endCapt.value, period]);

  function calculateStartDate(endDateStr, weeks) {
    const endDate = moment(endDateStr, "YYYY-MM-DD");

    if (!endDate.isValid()) {
      return;
    }

    const startDate = endDate.subtract(weeks, 'weeks');
    return startDate.format("DD/MM/YYYY");
  }

  const [leadsPerDay, setLeadsPerDay] = useState(0);
  const [leadsTotal, setLeadsTotal] = useState(0);

  const budget = watch('budget');
  const costLead = watch('costLead');

  useEffect(() => {
    if (budget && costLead && period) {
      const parsedBudget = parseFloat(budget);
      const parsedCostLead = parseFloat(costLead);
      const parsedPeriod = parseFloat(period);

      if (!isNaN(parsedBudget) && !isNaN(parsedCostLead) && !isNaN(parsedPeriod)) {
        const leads = calculateLeadsPerDay(parsedBudget, parsedCostLead, parsedPeriod);
        const leadsT = calculateLeadsTotal(parsedBudget, parsedCostLead);
        setLeadsPerDay(leads);
        setLeadsTotal(leadsT);
      } else {
        setLeadsPerDay(0);
        setLeadsTotal(0);
      }
    } else {
      setLeadsPerDay(0);
      setLeadsTotal(0);
    }
  }, [budget, costLead, period]);


  const calculateLeadsPerDay = (budget, costLead, period) => {
    const days = period * 7;
    return Math.floor(budget / costLead / days);
  };

  const calculateLeadsTotal = (budget, costLead) => {
    return Math.floor(budget / costLead);
  };

  const [, setFormData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isSuccess && launching) {
          const data = launching;
          setValue('periodCapt', data.periodCapt);
          if (launching.launchDate !== null) {
            setValue('endCapt', launching.launchDate);
          } else {
            setValue('endCapt', data.endCapt);
            }
          setValue('budget', data.budget);
          setValue('costLead', data.costLead);
          setValue('creationDate', data.creationDate);
          setValue('mtck', data.mtck);
          setValue('ggck', data.ggck);
          setPeriod(data.periodCapt);

          setStartDate(calculateStartDate(data.endCapt, data.periodCapt));
          if (data.budget && data.costLead && data.periodCapt) {
            const parsedBudget = parseFloat(data.budget);
            const parsedCostLead = parseFloat(data.costLead);

            if (parsedBudget > 0 && parsedCostLead > 0 && data.periodCapt > 0) {
              const leads = calculateLeadsPerDay(parsedBudget, parsedCostLead, data.periodCapt);
              const leadsT = calculateLeadsTotal(parsedBudget, parsedCostLead);
              setLeadsPerDay(leads);
              setLeadsTotal(leadsT);
            }
          }
        }

        setFormData(launching);
      } catch (error) {
        console.error("Erro ao buscar os dados: ", error);
        showAlert("Ocorreu um erro ao buscar os dados. Por favor, tente novamente.", "error");
      }
    };

    fetchData();
  }, [id, setValue, showAlert, launching, isSuccess]);


  return (
    <Accordion expanded={expanded === `panel${panel}`} onChange={handleChange(`panel${panel}`)}
      sx={{ borderRadius: 2, mb: 0.2, p: 0.5, position: 'initial', border: `solid 1px ${indigo[100]}` }}
    >
      <AccordionSummary
        key={allFieldsFilled && budget && costLead ? 'true' : 'false'}
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${panel}bh-content`}
        id={`panel${panel}bh-header`}
        sx={{
          borderRadius: 2, minHeight: 0, ml: 1, '&.Mui-expanded': { minHeight: 0}, '&::before': { content: '""', position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)', width: 10, height: 10, borderRadius: '50%', backgroundColor: allFieldsFilled && budget && costLead ? green[500] : red[400] },
        }}        
      >
        <Typography sx={{ width: '100%', flexShrink: 0 }}>
          Lista de Inscrição
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 1, pt: 2 }}>
        <Box
          component="form" onSubmit={handleSubmit(onSubmit)}
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: 1,
          }}>
          <Box sx={{ display: "flex", flexDirection: "column", alignSelf: "center", gap: 1, backgroundColor: indigo[50], p: 2, borderRadius: 2, width: 'inherit' }}>
            <Grid container gap={1} display="flex" flexDirection="column" justifyContent="center">
              <Grid item >
                <Grid container display="flex" flexDirection="row" justifyContent="center" sx={{gap: 2}}>
                  <Box display="flex" alignItems="center" flexDirection="row" sx={{ gap: 1 }}>
                    <FormControl size="small" sx={{ m: 0, minWidth: 194 }}>
                      <InputLabel
                        id="demo-simple-select-autowidth-label"
                        sx={{
                          backgroundColor: indigo[50], borderRadius: 2,
                          '& label.MuiInputLabel-shrink': {
                            backgroundColor: indigo[50],
                            borderRadius: 3, px: 0.8,
                          }
                        }}
                      >Período de captação</InputLabel>
                      <Select
                        labelId="demo-simple-select-autowidth-label"
                        id="demo-simple-select-autowidth"
                        value={periodCapt.value || ""}
                        onChange={handleSelectChange}
                        autoWidth
                        label="Período de captação"
                      >
                        {
                          Array.from({ length: weeksBetween }).map((_, index) => (
                            <MenuItem key={index} value={index + 1}>
                              {index + 1} semana{index > 0 ? 's' : ''}
                            </MenuItem>
                          ))
                        }
                      </Select>
                    </FormControl>
                  </Box>
                  <Box display="flex" alignItems="center" sx={{ gap: 1 }}>
                    <TextField
                      label="Fim da captação"
                      type="date"    
                      value={launching?.launchDate || ""}
                      variant="outlined"
                      disabled
                      size="small"
                    />
                  </Box>
                  <Box display="flex" alignItems="center" sx={{ gap: 1 }}>
                    <TextField
                      {...register('budget')}
                      onChange={(e) => {
                        setValue('budget', e.target.value)
                        setBtnStatus(true)
                      }}
                      label="Orçamento"
                      InputProps={{
                        startAdornment: <InputAdornment sx={{ fontSize: '10px' }} position="start">R$</InputAdornment>,
                      }}
                      variant="outlined"
                      size="small"
                      sx={{
                        width: '100%', backgroundColor: '#fff', borderRadius: 2,
                        '& label.MuiInputLabel-shrink': {
                          backgroundColor: indigo[50],
                          borderRadius: 3, px: 0.8,
                        } }}
                    />
                  </Box>
                </Grid>
                
              </Grid>
              <Grid item>
                <Grid container display="flex" flexDirection="row" justifyContent="center" sx={{ gap: 1 }}>
                  <Box display="flex" alignItems="center" sx={{ gap: 1 }}>
                    <TextField
                      value={startDate || ""}
                      label="Início da captação"
                      disabled
                      variant="outlined"
                      size="small"
                      sx={{
                        mt:1, width: '100%', borderRadius: 2, backgroundColor: grey[400], color: '#fff', '& .MuiFilledInput-root': { bgcolor: 'grey', }, '& .MuiInputLabel-filled': { color: 'white', }, '& label.MuiInputLabel-shrink': {
                          backgroundColor: indigo[50],
                          borderRadius: 3, px: 0.8,
                      }}}
                    />
                  </Box>
                </Grid>                
              </Grid>
              <Divider/>
              <Typography>Metas</Typography>
              <Grid item>
                <Grid container display="flex" flexDirection="row" justifyContent="center" sx={{ gap: 1 }}>
                  <Box display="flex" alignItems="center" sx={{ gap: 1 }}>
                    <TextField
                      {...register('costLead')}
                      onChange={(e) => {
                        setValue('costLead', e.target.value)
                        setBtnStatus(true)
                        }}
                      label="Custo por leads"
                      variant="outlined"
                      size="small"
                      sx={{
                        width: '100%', backgroundColor: '#fff', borderRadius: 2,
                        '& label.MuiInputLabel-shrink': {
                          backgroundColor: indigo[50],
                          borderRadius: 3, px: 0.8,
                        } }}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                      }}
                    />
                  </Box>
                  <Box display="flex" alignItems="center" sx={{ gap: 1 }}>
                    <TextField
                      value={leadsTotal || ""}
                      label="Meta total de leads"
                      disabled
                      variant="outlined"
                      size="small"
                      sx={{
                        width: '100%', borderRadius: 2, backgroundColor: grey[400], color: '#fff', '& .MuiFilledInput-root': { bgcolor: 'grey', }, '& .MuiInputLabel-filled': { color: 'white', }, '& label.MuiInputLabel-shrink': {
                          backgroundColor: indigo[50],
                          borderRadius: 3, px: 0.8,
                        }
                      }}
                    />
                  </Box>
                  <Box display="flex" alignItems="center" sx={{ gap: 1 }}>
                    <TextField
                      value={leadsPerDay || ""}
                      label="Leads por dia"
                      disabled
                      variant="outlined"
                      size="small"
                      sx={{
                        width: '100%', borderRadius: 2, backgroundColor: grey[400], color: '#fff', '& .MuiFilledInput-root': { bgcolor: 'grey', }, '& .MuiInputLabel-filled': { color: 'white', }, '& label.MuiInputLabel-shrink': {
                          backgroundColor: indigo[50],
                          borderRadius: 3, px: 0.8,
                        }
                      }}
                    />
                  </Box>         
                </Grid>
              </Grid>
              <Divider />
              <Typography>Plataformas</Typography>  
              <Grid item>
                <Grid container display="flex" flexDirection="row" justifyContent="center" sx={{ gap: 1 }}>
                  <FormGroup>
                    <FormControlLabel control={<Checkbox {...mtck}
                      checked={mtck.value}
                      onChange={(event) => {
                        mtck.onChange(event);
                        setValue('mtck', event.target.checked);
                        setBtnStatus(true)
                      }}
                      sx={{
                        color: purple[800],
                        '&.Mui-checked': {
                          color: purple[600],
                        },
                      }} />} label="Meta (Facebook / Instagram)" />
                    <FormControlLabel control={<Checkbox {...ggck}
                      checked={ggck.value}
                      onChange={(event) => {
                        ggck.onChange(event);
                        setValue('ggck', event.target.checked);
                        setBtnStatus(true)
                      }}
                      sx={{
                        color: '#ff0000',
                        '&.Mui-checked': {
                          color: '#ff0000',
                        },
                      }} />} label="Google (Youtube / Link patrocinado)" />
                  </FormGroup>
                </Grid>
              </Grid>
            </Grid>            
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              gap: 1,
            }}>
            {btnStatus ?
              <Button type="submit">Salvar</Button>
              :
              <Button disabled>Salvar</Button>
            }
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

export default RegistrationListForm;
