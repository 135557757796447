import React, {useMemo} from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Subtitle from "@/components/Subtitle";
import TeamCard from "./TeamCard";
import useOrganization from "@/queries/useOrganization";
import { useParams } from "react-router-dom";

function Team({ launching }) {
  const { option } = useParams();

  let team
  const apiHook = option === "shared" ? launching : useOrganization;

  if (typeof apiHook === 'object') {
    team = apiHook.group_lists;
  } else {
    const fetchTeam = apiHook();
    if (fetchTeam.isSuccess) {
      team = fetchTeam.data[0].group.grouplist_set;
    } else {
      team = [];
    }
  }

  const positionMapping = {
    expert: "Expert",
    launcher: "Lançador(a)",
    copywriter: "Copywriter",
    socialMedia: "Social Media",
    trafficManager: "Gestor(a) de Tráfego",
    webdesigner: "Webdesigner",
    designer: "Designer",
    videomaker: "Videomaker"
  };

  const memorizedTeam = useMemo(() => team, [team]);

  return(
    <Paper
      sx={{ borderRadius: 3, display: 'flex', flexDirection: 'column'}}
    >
      <Subtitle name="Equipe" colorName="indigo" />
      <Box sx={{ width: '100%' }}>
        <Grid container spacing={1} sx={{p: 1}}>
          <Grid item xs={12} sx={{ overflow: 'auto' }}>
            <Grid container spacing={0.5}>
              {memorizedTeam && memorizedTeam.map((user, index) => (
                <Grid key={`team_${index}`} item sm={6} md={4}>
                  <TeamCard
                    statTitle={user.user.name}
                    statSubtitle={user.user.email}
                    statImg={user.user.image}
                    statPosition={positionMapping[user.position] || user.position}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}

export default Team