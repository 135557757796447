import React, { useContext } from 'react';
import { loadStripe } from "@stripe/stripe-js";
import Button from '@mui/material/Button';
import AlertContext from '@/components/Alert/AlertContext';
import api from '@/services/api';

const Checkout = ({ email, price, type, color, disabledBtn, cancelAtPeriodEnd }) => {
  const { showAlert } = useContext(AlertContext);
  const redirectToCheckout = async (event) => {
    event.preventDefault();

    const stripe = await loadStripe(process.env.REACT_APP_STRIPE_KEY);
    try {
      const data = {
        userEmail: email,
        price: price,
        type: type,
      };

      const response = await api.post(`${process.env.REACT_APP_API_URL}/create-checkout-session/`, data);

      if (response.data.message) {
        showAlert(response.data.message, "warning");
        return;
      }

      const { error } = await stripe.redirectToCheckout({
        sessionId: response.data.id,
      });

      if (error) {
        console.warn('Error:', error);
      }
    } catch (error) {
      console.warn('Error:', error);
      showAlert("Erro ao redirecionar para o checkout.", "error"); 
    }
  };

  const colorName = color[0];
  const colorIndex = color[1];

  const buttonStyle = {
    backgroundColor: colorName[colorIndex],
    '&:hover': { backgroundColor: colorName[colorIndex + 200] },
  };

  return (
    <>
    {disabledBtn ? 
      <Button size="small" disabled>
        { cancelAtPeriodEnd ? 
        "Plano cancelado"
        :
        "Adquirido"
        }
      </Button >
    :
    <Button size="small" variant="contained" sx={buttonStyle} onClick={redirectToCheckout}>
      Comprar
    </Button>}
    </>
  );
};

export default Checkout;
