import React from "react";
import { useUser } from "@/reducers/user";
import { Navigate } from "react-router-dom";
import SplashScreen from "../SplashScreen";

const ProtectedRoute = ({ children, path = "/login" }) => {
  const [user] = useUser();

  if (user.logged === undefined) {
    return <SplashScreen/>
  }
 
  if (!user.logged || user.name === "" ) {
    return <Navigate to={path} replace />;
  }

  return children;
};

export default ProtectedRoute;
