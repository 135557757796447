import React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { useUser } from "@/reducers/user";
import Card from '@mui/material/Card';
import { styled } from "@mui/system";
import { darken } from '@mui/system';
import { indigo } from "@mui/material/colors";
import formatString from "./FormatString"

function UserContaint() {
  const [user] = useUser();

  const StyledImg = styled('img')({
    width: '100%',
    height: '100%',
    display: 'block',
    objectFit: 'cover',
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 1,
        py: 4,
      }}
    >
      <Box>
        {user?.image && user.image !== "" ?
          <Box
            sx={{
              width: "120px",
              height: "120px",
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: "100%",
              backgroundColor: "#000",
              border: "2px solid #6366F1",
              overflow: "hidden",
            }}          
          >
            <StyledImg
              src={user.image}
              alt=""
            />
          </Box>
        :
          <Avatar sx={{ width: 100, height: 100, fontSize: 30, fontWeight: 500 }}>{user?.name[0]}</Avatar> 
        }
      </Box>
      {!!user.subscriptionStatus && user.hasAccess ? (
        <Card sx={{
          borderRadius: 2,
          padding: 0.4,
          margin: 2,
          backgroundImage: `linear-gradient(0deg, 
            ${darken('#e5d8b0', 0.05)}, 
            ${darken('#e5d8b0', 0.05)} 10%, 
            ${darken('#d4c59a', 0.05)} 30%, 
            ${darken('#d4c59a', 0.05)} 50%, 
            ${darken('#e5d8b0', 0.05)} 70%, 
            ${darken('#e5d8b0', 0.05)} 90%, 
            ${darken('#d4c59a', 0.05)})`,
          boxShadow: '0 3px 5px 2px rgba(229, 216, 176, .3)',
          width: 120,
          textAlign: 'center',
          fontWeight: 700,
          color: 'white',
          border: '1px solid #d4c59a',
        }}
        >
          <Typography 
            sx={{ color: '#6a5514'}}
            variant="h5" component="div"
          >
            { formatString(user.subscriptionStatus) }
          </Typography>
        </Card>
      ) : (
        <Card sx={{
          borderRadius: 2,
          padding: 0.4,
          margin: 2,
          backgroundImage: `linear-gradient(0deg, 
                    ${darken('#aaaaaa', 0.05)}, 
                    ${darken('#999', 0.05)} 10%, 
                    ${darken('#888', 0.05)} 30%, 
                    ${darken('#999', 0.05)} 50%, 
                    ${darken('#888', 0.05)} 70%, 
                    ${darken('#666', 0.05)} 90%, 
                    ${darken('#777', 0.05)})`,
          boxShadow: '0 3px 5px 2px rgba(90, 90, 90, .3)',
          width: 100,
          textAlign: 'center',
          fontWeight: 700,
          color: 'white',
          border: '1px solid #888',
        }}
        >
          <Typography
            sx={{ color: '#fff' }}
            variant="h5" component="div"
          >
            Padrão
          </Typography>
        </Card>
      )
      
      }
      <Box>
        <Typography
        variant="h2"
          sx={{textTransform: "capitalize", color: indigo[700] }}
        >
          {user.name}
        </Typography>
      </Box>
      <Box>
        <Typography variant="h4" sx={{ color: indigo[600]}}>
          {user.email}
        </Typography>
      </Box>
    </Box>
  );
}

export default UserContaint;
