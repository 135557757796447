import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

function hexToRgb(hex) {
  const bigint = parseInt(hex.substring(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return [r, g, b];
}

function rgbToHex(r, g, b) {
  return (
    "#" +
    (1 << 24 | r << 16 | g << 8 | b)
      .toString(16)
      .slice(1)
      .toUpperCase()
  );
}

function darkenColor(hexColor, percent) {
  const [r, g, b] = hexToRgb(hexColor);
  const factor = (100 - percent) / 100;
  return rgbToHex(Math.floor(r * factor), Math.floor(g * factor), Math.floor(b * factor));
}

function SubtitleCustom({ name, colorName }) {
  const colors = {
    "#000": "#fff",
    "#7CFB5D": "#000",
    "#fff": "#000",
    "#CF2CF6": "#fff",
    "#8F4DF6": "#fff",
    "#89E6FC": "#000",
    "#F3AC3C": "#000",
  };

  const baseColor = colorName || "#000";
  const darkenedColor = darkenColor(baseColor, 20);

  return (
    <Box
      sx={{
        padding: "4px 16px",
        background: `linear-gradient(to right, ${baseColor}, ${baseColor !== "#fff" ? darkenedColor : "#ddd"})`,
        borderRadius: 2,
        border: `solid 1px ${baseColor !== "#fff" ? baseColor : "#ccc" }`,
        color: colors[baseColor] || "#fff",
        mx: 1,
        marginTop: "8px",
      }}
    >
      <Typography variant="h5" >
        {name}
      </Typography>
    </Box>
  );
}

export default SubtitleCustom;
