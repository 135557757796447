import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { grey, indigo } from '@mui/material/colors';
import Checkout from "./Checkout";
import CheckoutUpdate from "./CheckoutUpdate";
import ModalSeed from './ModalSeed';
import ModalLauncherfy from '@/pages/ProfileStore/components/ModalLauncherfy';

function ProductCard({ icon, title, description, priceOne, priceIdOne, typeOne, priceTwo, priceIdTwo, typeTwo, priceThree, priceIdThree, typeThree, priceFour, priceIdFour, typeFour, email, subscription, disabledAll, cancelAtPeriodEnd }) {

  let isDisabled = disabledAll;

  return (
    <Card sx={{ maxWidth: 345, m: 1, border: `1px solid ${indigo[200]}` }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 140, bgcolor: grey[700] }}>
        {icon}
      </Box>
      <CardContent sx={{p:0}}>
        <Typography gutterBottom variant="h5" component="div" sx={{backgroundColor: indigo[500], color: "#fff", padding: 2}}>
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ width: 'full', p: 2}}>
          {description}
        </Typography>
        {typeOne.includes("semente") && <ModalSeed />}
        {typeOne.includes("fundacao") && <ModalLauncherfy />}

      </CardContent>
      <Divider />
      <CardActions sx={{ justifyContent: 'center' }}>
        <Typography variant="h6" component="div" color={indigo[900]} >
          Escolha o melhor plano
        </Typography>
      </CardActions>
      <CardActions sx={{ justifyContent: 'space-between' }}>
        <Typography variant="h6" component="div" color={indigo[500]}>
          R$ {priceOne}/Mês
        </Typography>
        {isDisabled ? (
          <Button disabled size="small">Indisponível</Button>
        ) : (
            subscription?.includes(typeOne) ?
              <Checkout disabledBtn={true} cancelAtPeriodEnd={cancelAtPeriodEnd} color={[indigo, 400]} />
          :
          <Checkout email={email} price={priceIdOne} type={typeOne} color={[indigo, 400]} />
        )}
      </CardActions>
      <CardActions sx={{ justifyContent: 'space-between' }}>
        <Typography variant="h6" component="div" color={indigo[700]}>
          R$ {priceTwo}/Trimestre
        </Typography>
        {isDisabled ? (
          <Button disabled size="small">Indisponível</Button>
        ) : (
          subscription === typeOne && !cancelAtPeriodEnd ?
          <CheckoutUpdate email={email} price={priceIdTwo} type={typeTwo} color={[indigo, 500]} />
              : (subscription?.includes(typeOne) ?
                <Checkout disabledBtn={true} cancelAtPeriodEnd={cancelAtPeriodEnd} color={[indigo, 400]} />
            :
            <Checkout email={email} price={priceIdTwo} type={typeTwo} color={[indigo, 500]} />
          )
        )}
      </CardActions>
      {/* <CardActions sx={{ justifyContent: 'space-between' }}>
        <Typography variant="h6" component="div" color={indigo[900]}>
          R$ {priceThree}/semestre
        </Typography>
        {isDisabled ? (
          <Button disabled size="small">Indisponível</Button>
        ) : (
            (subscription === typeOne && !cancelAtPeriodEnd) || (subscription === typeTwo && !cancelAtPeriodEnd) ?
          <CheckoutUpdate email={email} price={priceIdThree} type={typeThree} color={[indigo, 700]} />
              : (subscription === typeOne || subscription === typeTwo || subscription === typeFour ?
                <Checkout disabledBtn={true} cancelAtPeriodEnd={cancelAtPeriodEnd} color={[indigo, 400]} />
            :
            <Checkout email={email} price={priceIdThree} type={typeThree} color={[indigo, 700]} />
          )
        )}
      </CardActions> */}
      {/* <CardActions sx={{ justifyContent: 'space-between' }}>
        <Typography variant="h6" component="div" color={indigo[900]}>
          R$ {priceFour}/Ano
        </Typography>
        {isDisabled ? (
          <Button disabled size="small">Indisponível</Button>
        ) : (
            (subscription === typeOne && !cancelAtPeriodEnd) || (subscription === typeTwo && !cancelAtPeriodEnd) || (subscription === typeThree && !cancelAtPeriodEnd) ?
          <CheckoutUpdate email={email} price={priceIdFour} type={typeFour} color={[green, 700]} />
              : (subscription === typeOne || subscription === typeTwo || subscription === typeThree ?
              <Checkout disabledBtn={true} cancelAtPeriodEnd={cancelAtPeriodEnd} color={[indigo, 400]} />
            :
            <Checkout email={email} price={priceIdFour} type={typeFour} color={[green, 700]} />
          )
        )}
      </CardActions> */}
      {/* {subscription?.includes(typeOne) && subscription !== typeFour && !cancelAtPeriodEnd &&
      <Typography variant="h6" color={red[900]} sx={{ textAlign: 'right', px:2, pb:1}} >Pague mais barato!</Typography>
      } */}
    </Card>
  );
}

export default ProductCard;
