import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import { green, indigo, red } from '@mui/material/colors';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import api from '@/services/api';
import { useParams } from 'react-router-dom';
import AlertContext from '../Alert/AlertContext';
import { useFieldChange } from './useFieldChange';
import { useCustomQueryClient } from './hooks/useCustomQueryClient';
import { useQueryClient } from '@tanstack/react-query';
import { Link } from "react-router-dom";

function Sales({ handleChange, expanded, panel, path, useLauncherfyById }) {
  const { id } = useParams();
  const [btnStatus, setBtnStatus] = useState(false);
  const queryClient = useQueryClient();
  const invalidate = useCustomQueryClient(queryClient)

  const accessCheckboxToKeyMap = useMemo(() => ({
    "E-mail": { key: "", url: "" },
    "Inscrições abertas": { key: "fcp0ck", url: "https://docs.google.com/document/d/1x0-hh8tO60G6quZEbM-j2NpCJAEvPjJVJ5BRVilM6B4/edit" },
    "Faltam 48 horas": { key: "fcp1ck", url: "https://docs.google.com/document/d/1NmhWbdw9KyPqq2V_nz1Fmu3O6fZExLFEXMA1iTrjdaI/edit" },
    "Último dia / Últimas horas / Últimas vagas": { key: "fcp2ck", url: "https://docs.google.com/document/d/1H2kwFEj27l47jnNWVwTBsSZW8gFs6nmHTIhc1wCNU2M/edit#heading=h.snwmhsssmbp6" },
    "Recuperação (ListBoss)": { key: "fcp3ck", url: "https://drive.google.com/drive/folders/1kOkltsy1CZE6HSN_zCa4kavk_b8DqpYO?usp=sharing" },
    "WhatsApp / Telegram": { key: "", url: "" },
    "Inscrições abertas wp": { key: "fcp4ck", url: "https://docs.google.com/document/d/19D6xj--V2oV07Y1y6H0-zu7PSuUIz32Ef_Hkyd3HFGk/edit?usp=sharing" },
    "Faltam 48 horas wp": { key: "fcp5ck", url: "https://docs.google.com/document/d/1NDZwQs5ZbVK7ZQUttO4YPUqBmFBPxWnumuOcdrNjXiQ/edit" },
    "Último dia / Últimas horas / Últimas vagas wp": { key: "fcp6ck", url: "https://docs.google.com/document/d/1BscSTNbuEBqv9_sqDxbsQQ3c-rpcn3KvBTHWs-t8wWY/edit#heading=h.tz0o61dvwexf" },
    "Recuperação (Compras não concluidas) wp": { key: "fcp7ck", url: "https://docs.google.com/document/d/1HRdvQEvusLXGPEpdWIgkbTjeSNdnQA5qTl6OyXkPwnA/edit" },
    "Ura": { key: "adimck", url: "" },
    "Inscrições abertas ur": { key: "fcp8ck", url: "https://docs.google.com/document/d/14rQXp7Ye8t2W15XOt5vUK7LMB_Z-bEm40ZTNgWp7aWQ/edit" },
    "Faltam 48 horas ur": { key: "fcp9ck", url: "https://docs.google.com/document/d/1rLt-4bfBG3riVLac9lpTIWQ_XUnnfK-p92WXPH_xGvk/edithttps://docs.google.com/document/d/1rLt-4bfBG3riVLac9lpTIWQ_XUnnfK-p92WXPH_xGvk/edit" },
    "Último dia / Últimas horas / Últimas vagas ur": { key: "fcp10ck", url: "https://docs.google.com/document/d/11iQVOFwQtrFWpYg8Apbm3jUJ3sbtiVUamJ_-JuqeeYw/edit" },
    "Recuperação (Compras não concluidas) ur": { key: "fcp11ck", url: "https://docs.google.com/document/d/1dqfPS_1IosSwn8nLxjtW3FE5gay-geVcm8xreaL5vqE/edit" },
    "FAQ": { key: "", url: "" },
    "Preparar a FAQ": { key: "fcp12ck", url: "https://docs.google.com/document/d/19D6xj--V2oV07Y1y6H0-zu7PSuUIz32Ef_Hkyd3HFGk/edit?usp=sharing" },
  }), []);

  const defaultAccessCheckbox = useMemo(() => ([
    "E-mail", "Inscrições abertas",
    "Faltam 48 horas", 
    "Último dia / Últimas horas / Últimas vagas",
    "Recuperação (ListBoss)",
    "WhatsApp / Telegram", 
    "Inscrições abertas wp", 
    "Faltam 48 horas wp", "Último dia / Últimas horas / Últimas vagas wp",
    "Recuperação (Compras não concluidas) wp",
    "Ura",
    "Inscrições abertas ur",
    "Faltam 48 horas ur", "Último dia / Últimas horas / Últimas vagas ur",
    "Recuperação (Compras não concluidas) ur", "FAQ", "Preparar a FAQ"
  ]), []);

  const { showAlert } = useContext(AlertContext);
  const { data: launching, isSuccess } = useLauncherfyById(id);

  const { handleSubmit, control, getValues, setValue, reset } = useForm({
    defaultValues: {
      accessFieldList: defaultAccessCheckbox.map(item => ({ label: item, checked: false })),
    },
  });

  const isEmptyKeyForLabel = (label) => {
    const item = accessCheckboxToKeyMap[label];
    return item && item.key === "";
  };

  function getProcessedValues() {
    const values = getValues();

    if (values.accessFieldList) {
      return {
        ...values,
        accessFieldList: values.accessFieldList.map(item => {
          if (isEmptyKeyForLabel(item.label)) {
            return { ...item, checked: true };
          }
          return item;
        })
      };
    }

    return values;
  }
  
  const { handleFieldChange, allFieldsFilled, updateValues } = useFieldChange(getProcessedValues, setValue, setBtnStatus);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isSuccess && launching) {
          const accessFieldList = defaultAccessCheckbox.map(label => {
            const isModelo = label.toLowerCase().includes("modelo");

            return {
              label,
              checked: isModelo || !!launching[accessCheckboxToKeyMap[label].key]
            };
          });
          reset({ accessFieldList });

          setBtnStatus(false);
        }
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };

    fetchData();
    updateValues();
  }, [id, reset, launching, isSuccess, defaultAccessCheckbox, accessCheckboxToKeyMap]);

  const { fields: accessFieldFields } = useFieldArray({
    control,
    name: 'accessFieldList',
  });

  const onSubmit = async (data) => {
    try {
      const accessFieldListValues = data.accessFieldList.map((field, index) => ({
        key: accessCheckboxToKeyMap[defaultAccessCheckbox[index]].key,
        value: field.checked
      }));

      const fieldData = Object.fromEntries(accessFieldListValues.map(({ key, value }) => [key, value]));

      console.log(fieldData)

      await api.patch(`/api/${path}/${id}/`, fieldData);

      invalidate.invalidateQueries()

      setBtnStatus(false);
      showAlert("Dados atualizados com sucesso!", "success");

    } catch (error) {
      console.error("Erro ao atualizar os dados: ", error);
      showAlert("Ocorreu um erro ao tentar atualizar os dados. Por favor, tente novamente.", "error");
    }
  };


  return (
    <Accordion expanded={expanded === `panel${panel}`} onChange={handleChange(`panel${panel}`)}
      sx={{ borderRadius: 2, mb: 0.2, p: 0.5, position: 'initial', border: `solid 1px ${indigo[100]}` }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${panel}bh-content`}
        id={`panel${panel}bh-header`}
        sx={{
          // background: `linear-gradient(to right, ${indigo[200]}, ${indigo[400]})`,
          borderRadius: 2,
          minHeight: 0,
          ml: 1,
          '&.Mui-expanded': {
            minHeight: 0,
          },
          '&::before': { content: '""', position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)', width: 10, height: 10, borderRadius: '50%', backgroundColor: allFieldsFilled ? green[500] : red[400] },
        }}
      >
        <Typography sx={{ width: '100%', flexShrink: 0 }}>
          Copys de Venda
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 1, pt: 2 }}>
        <Box
          component="form" onSubmit={handleSubmit(onSubmit)}
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "auto",
          }}>
          <Box sx={{ display: "flex", flexDirection: "row", alignSelf: "center", backgroundColor: indigo[50], p: 1, borderRadius: 2 }} >
            <Grid container display="flex" justifyContent="center" spacing={1} >
              <Grid item >
                <Grid container display="flex" direction="column" sx={{ bgcolor: "#fff", borderRadius: 2, height: '100%' }}>
                  <Typography variant="h5" sx={{ bgcolor: '#000', p: 1, borderRadius: 2, color: '#CF2CF6', fontWeight: "800", marginBottom: 1 }}>...</Typography>
                  {accessFieldFields.map((field, index) => (
                    <Box display="flex" alignItems="center" sx={{ gap: 1, px: 1 }} key={field.id}>
                      <Controller
                        name={`accessFieldList.${index}.checked`}
                        control={control}
                        defaultValue={field.checked}
                        render={({ field }) => (
                          <FormControlLabel
                            control={
                              (index === 0 || index === 5 || index === 10 || index === 15 )
                                ? (
                                  <Box sx={{
                                    '&::before': {
                                      content: '""',
                                      left: 0,
                                      width: 1,
                                      marginLeft: 1,
                                      marginRight: 1,
                                      backgroundColor: "#CF2CF6",
                                    },
                                    color: "#CF2CF6"
                                  }} />
                                )
                                : <Checkbox
                                  checked={field.value}
                                  onChange={(e) => {
                                    field.onChange(e.target.checked);
                                    handleFieldChange(e, index, "accessFieldList");
                                    setBtnStatus(true);
                                  }}
                                  sx={{
                                    color: '#CF2CF6',
                                    '&.Mui-checked': {
                                      color: '#CF2CF6',
                                    },
                                    '&::before': {
                                      content: '""',
                                      left: 0,
                                      width: 1,
                                      marginLeft: 1,
                                      marginRight: 1,
                                      backgroundColor: "#CF2CF6",
                                    },
                                  }}
                                />
                            }
                            label={
                              accessCheckboxToKeyMap[defaultAccessCheckbox[index]]?.url
                                ? (
                                  <Button
                                    // variant="outlined"
                                    target='_blank'
                                    size='small'
                                    sx={{
                                      borderColor: '#CF2CF6',
                                      color: '#CF2CF6',
                                      '&.Mui-checked': {
                                        borderColor: '#CF2CF6',
                                      },
                                      paddingLeft: 0
                                    }}
                                    component={Link}
                                    to={accessCheckboxToKeyMap[defaultAccessCheckbox[index]].url}
                                  >
                                    {defaultAccessCheckbox[index]}
                                  </Button>
                                )
                                : defaultAccessCheckbox[index]
                            }
                            sx={{ width: '100%' }}
                          />
                        )}
                      />
                    </Box>
                  ))}
                </Grid>
              </Grid>

            </Grid>
          </Box>
          <Divider sx={{ my: 1 }} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              gap: 1,
            }}>
            {btnStatus ?
              <Button type="submit">Salvar</Button>
              :
              <Button disabled>Salvar</Button>
            }
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

export default Sales;
