import React from 'react';
// import { loadStripe } from "@stripe/stripe-js";
import Button from '@mui/material/Button';
// import AlertContext from '@/components/Alert/AlertContext';

const CheckoutUpdate = ({ email, price, type, color }) => {
  // const { showAlert } = useContext(AlertContext);

  // console.log("disable: ", disabledBtn)
  // const redirectToCheckout = async (event) => {
  //   event.preventDefault();

  //   const stripe = await loadStripe(process.env.REACT_APP_STRIPE_KEY);

  //   const response = await fetch(`${process.env.REACT_APP_API_URL}/create-checkout-session/`, {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({
  //       userEmail: email,
  //       price: price,
  //       type: type,
  //     }),
  //   });

  //   const data = await response.json();

  //   if (data.message) {
  //     showAlert(data.message, "warning");
  //     return;
  //   }

  //   const { error } = await stripe.redirectToCheckout({
  //     sessionId: data.id,
  //   });

  //   if (error) {
  //     console.warn('Error:', error);
  //   }
  // };

  return (
    <Button size="small" variant="contained" disabled>
      Upgrade de plano
    </Button>
  );
};

export default CheckoutUpdate;
