import React, { useState, useMemo } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { green, blue, brown } from "@mui/material/colors";
import SubtitleLaunchings from "@/components/SubtitleLaunchings";
import CardStats from "./Card";
import SpaIcon from '@mui/icons-material/Spa';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import InventoryIcon from '@mui/icons-material/Inventory';
import useSharedFList from '../queries/useSharedFList';
import moment from 'moment';
import { Link } from "react-router-dom";

function Formula() {
  const [hideBaseLaunch, setHideBaseLaunch] = useState(false);
  const [hideCompleteLaunch, setHideCompleteLaunch] = useState(false);
  const [hideBox, setHideBox] = useState(false);

  const handleToggleBaseLauchView = () => {
    setHideBaseLaunch(!hideBaseLaunch)
  }

  const handleToggleCompleteView = () => {
    setHideCompleteLaunch(!hideCompleteLaunch)
  }

  const handleToggleBoxView = () => {
    setHideBox(!hideBox)
  }

  const fetchLaunchingList = useSharedFList();
  const list = useMemo(() => {
    if (fetchLaunchingList.isSuccess) {
      return fetchLaunchingList.data;
    }
    return [];
  }, [fetchLaunchingList]);

  const listSeed = list && list.filter(item => item.launchType === "seed");
  const listClassic = list && list.filter(item => item.launchType === "classic");
  const listBox = list && list.filter(item => item.launchType === "box");

  return (
    <Paper
      sx={{ borderRadius: 3, display: 'flex', flexDirection: 'column', gap: 2 }}
    >
      <SubtitleLaunchings name="Fórmula de Lançamento" colorName="blue" />
      <Box sx={{ width: '100%', display: 'flex', px: 1, pb: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} sx={{ display: 'flex', flexDirection: 'column', height: 'fit-content' }}>
            <Grid item xs={12} sx={{
              display: 'flex', justifyContent: 'space-between',
              bgcolor: green[100],
              borderRadius: 2,
              border: `solid 1px ${green[300]}`,
              px: 2,
            }}>
              <Typography variant="h6" color={green[700]} sx={{ alignSelf: 'center' }}>Lançamento Semente</Typography>
              <Button sx={{ px: 1 }} onClick={handleToggleBaseLauchView}>
                {hideBaseLaunch ? "Mostrar" : "Esconder"}
              </Button>
            </Grid>
            {!hideBaseLaunch && <Grid item xs={12}>
            {listSeed && listSeed.length > 0 ? listSeed.map((item, index) => (
              <Button key={`seed_bt_${index}`} component={Link} sx={{ width: '100%', p: 0, my: 1 }} to={`/shared/formula/${item.id}`}>
                <CardStats bgColorName="green" colorName="green" StatIconComponent={SpaIcon} statTitle={item.name} date={moment(item.launchDate, "YYYY-MM-DD").format("DD/MM/YYYY")} progressValue={item.progress} />
              </Button>
            ))
              :
              <Box sx={{ width: '100%', p: 0, my: 1 }}>
                <CardStats colorName="grey" progressValue={0} StatIconComponent={SpaIcon} />
              </Box>
            } 
            </Grid>}
          </Grid>

          <Grid item xs={12} sm={4} sx={{ display: 'flex', flexDirection: 'column', height: 'fit-content' }}>
            <Grid item xs={12} sx={{
              display: 'flex', justifyContent: 'space-between',
              bgcolor: blue[300],
              borderRadius: 2,
              border: `solid 1px ${blue[500]}`,
              px: 2,
            }}>
              <Typography variant="h6" color={blue[900]} sx={{ alignSelf: 'center' }}>Lançamento Clássico</Typography>
              <Button sx={{ px: 1 }} onClick={handleToggleCompleteView}>
                {hideCompleteLaunch ? "Mostrar" : "Esconder"}
              </Button>
            </Grid>
            {!hideCompleteLaunch && <Grid item xs={12}>
              {listClassic && listClassic.length > 0 ? listClassic.map((item) => (
                <Button sx={{ width: '100%', p: 0, my: 1 }}>
                  <CardStats colorName="blue" StatIconComponent={AccountBalanceIcon} statTitle={item.name} date={moment(item.launchDate, "YYYY-MM-DD").format("DD/MM/YYYY")} progressValue={item.progress} />
                </Button>
              ))
                :
                <Box sx={{ width: '100%', p: 0, my: 1 }}>
                  <CardStats colorName="grey" progressValue={0} StatIconComponent={AccountBalanceIcon} />
                </Box>
              } 
            </Grid>}
          </Grid>

          <Grid item xs={12} sm={4} sx={{ display: 'flex', flexDirection: 'column', height: 'fit-content' }}>
            <Grid item xs={12} sx={{
              display: 'flex', justifyContent: 'space-between',
              bgcolor: brown[300],
              borderRadius: 2,
              border: `solid 1px ${brown[500]}`,
              px: 2,
            }}>
              <Typography variant="h6" color={brown[900]} sx={{ alignSelf: 'center' }}>Lançamento Caixa</Typography>
              <Button sx={{ px: 1 }} onClick={handleToggleBoxView}>
                {hideBox ? "Mostrar" : "Esconder"}
              </Button>
            </Grid>
            {!hideBox && <Grid item xs={12}>
              {listBox && listBox.length > 0 ? listBox.map((item) => (
                <Button sx={{ width: '100%', p: 0, my: 1 }}>
                  <CardStats colorName="brown" StatIconComponent={InventoryIcon} statTitle={item.name} date={moment(item.launchDate, "YYYY-MM-DD").format("DD/MM/YYYY")} progressValue={item.progress} />
                </Button>
              ))
                :
                <Box sx={{ width: '100%', p: 0, my: 1 }}>
                  <CardStats colorName="grey" progressValue={0} StatIconComponent={InventoryIcon} />
                </Box>
              } 
            </Grid>}
          </Grid>

        </Grid>
      </Box>
    </Paper>
  )
}

export default Formula
