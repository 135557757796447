import React, { useState, useEffect, useContext } from 'react';
import { useForm, useController, Controller } from 'react-hook-form';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import { green, indigo, purple, red } from '@mui/material/colors';
import { useFieldCheckChange } from './useFieldCheckChange';
import api from '@/services/api';
import { useParams } from 'react-router-dom';
import AlertContext from '../Alert/AlertContext';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useCustomQueryClient } from './hooks/useCustomQueryClient';
import { useQueryClient } from '@tanstack/react-query';

function RegistrationDomainForm({ handleChange, expanded, panel, path, useLauncherfyById }) {
  const { id } = useParams();
  const [btnStatus, setBtnStatus] = useState(false);
  const { showAlert } = useContext(AlertContext);
  const { data: launching, isSuccess } = useLauncherfyById(id);
  const queryClient = useQueryClient();
  const invalidate = useCustomQueryClient(queryClient)

  const { handleSubmit, control, getValues, setValue, watch } = useForm({
    defaultValues: {
      domain: '',
      domainck: false,
    },
  });

  const domain = watch('domain');

  const { field: domainck } = useController({
    name: 'domainck',
    control,
    defaultValue: false,
  });

  const { handleFieldChange, allFieldsFilled, updateValues } = useFieldCheckChange(getValues, setValue, setBtnStatus);

  useEffect(() => {
    updateValues();
  }, [domain, domainck, updateValues]);

  const onSubmit = async (data) => {
    try {
      await api.patch(`/api/${path}/${id}/`, {        
        domainck: data.domainck,
        domain: data.domain,   
      });
      invalidate.invalidateQueries()

      setBtnStatus(false);
      showAlert("Dados atualizados com sucesso!", "success");

    } catch (error) {
      console.error("Erro ao atualizar os dados: ", error);
      showAlert("Ocorreu um erro ao tentar atualizar os dados. Por favor, tente novamente.", "error");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isSuccess && launching && launching.domain) {        
          setValue('domain', launching.domain);
          setValue('domainck', launching.domainck);
        }
      } catch (error) {
        console.error("Erro ao buscar os dados: ", error);
        showAlert("Ocorreu um erro ao buscar os dados. Por favor, tente novamente.", "error");
      }
    };

    fetchData();
  }, [id, setValue, showAlert, launching, isSuccess]);

  return (
    <Accordion expanded={expanded === `panel${panel}`} onChange={handleChange(`panel${panel}`)}
      sx={{ borderRadius: 2, mb: 0.2, p: 0.5, position: 'initial', border: `solid 1px ${indigo[100]}` }}
    >
      <AccordionSummary
        key={allFieldsFilled ? 'true' : 'false'}
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${panel}bh-content`}
        id={`panel${panel}bh-header`}
        sx={{
          // background: `linear-gradient(to right, ${indigo[200]}, ${indigo[400]})`,
          borderRadius: 2,
          minHeight: 0,
          ml:1,
          '&.Mui-expanded': {
            minHeight: 0,
          },
          '&::before': {content: '""', position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)', width: 10, height: 10, borderRadius: '50%', backgroundColor: allFieldsFilled ? green[500] : red[400]},
        }}
      >
        <Typography sx={{ width: '60%', flexShrink: 0 }}>
          Registrar domínio
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 1, pt: 2 }}>
        <Box
          component="form" onSubmit={handleSubmit(onSubmit)}
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: 1,
          }}>
          <Box sx={{ display: "flex", flexDirection: "column", alignSelf: "center", gap: 1, backgroundColor: indigo[50], p: 2, borderRadius: 2 }}>
            <Grid container gap={1} display="flex" flexDirection="column" justifyContent="center">              
              <Grid item>
                <Grid container display="flex" flexDirection="row" justifyContent="center" sx={{ gap: 1 }}>
                  <FormGroup>
                    <FormControlLabel control={<Checkbox {...domainck}
                      checked={domainck.value}
                      onChange={(event) => {
                        domainck.onChange(event);
                        setValue('domainck', event.target.checked);
                        setBtnStatus(true)
                      }}
                      sx={{
                        color: purple[800],
                        '&.Mui-checked': {
                          color: purple[600],
                        },
                      }} />} label="Domínio criado ?" />
                  </FormGroup>
                </Grid>
              </Grid>
              <Divider sx={{my:0.5}}/>
              <Grid item>
                <Grid container display="flex" flexDirection="row" justifyContent="center" sx={{ gap: 1 }}>
                  <Box display="flex" alignItems="center" sx={{ gap: 1 }}>
                    <Controller
                      name='domain'
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          onChange={(e) => {
                            handleFieldChange('domain', e.target.value);
                            field.onChange(e);
                          }}
                          label="Domínio"
                          variant="outlined"
                          size="small"
                          sx={{
                            width: '100%', backgroundColor: '#fff', borderRadius: 2,
                            '& label.MuiInputLabel-shrink': {
                              backgroundColor: indigo[50],
                              borderRadius: 3, px: 0.8,
                            } }}
                        />
                      )}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>            
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              gap: 1,
            }}>
            {btnStatus ?
              <Button type="submit">Salvar</Button>
              :
              <Button disabled>Salvar</Button>
            }
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

export default RegistrationDomainForm;
