import axios from "axios";
import { auth } from "@/services/firebase";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: "json",
});

api.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Interceptador de resposta para lidar com erros de token expirado
api.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;

    // Se a resposta foi um erro 401 e não tentamos renovar o token ainda
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      // Obter um novo token do Firebase
      const user = auth.currentUser;
      if (!user) {
        return Promise.reject(error);
      }

      const newToken = await user.getIdToken(true);
      localStorage.setItem('token', newToken);
      
      // Atualizar o header de autorização com o novo token
      api.defaults.headers['Authorization'] = `Bearer ${newToken}`;
      
      // Repetir a solicitação original com o novo token
      originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
      return api(originalRequest);
    }

    return Promise.reject(error);
  }
);

export default api;
