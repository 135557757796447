import React, { useState, useContext } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Title from "@/components/Title";
import { red } from "@mui/material/colors";
import AlertContext from '@/components/Alert/AlertContext';
import api from '@/services/api';
import { useNavigate } from "react-router-dom";


function ModalLauncherfy({ launching }) {
  const [,setBtnStatus] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { showAlert } = useContext(AlertContext);
  const navigate = useNavigate();

  const onSubmit = async () => {
    try {
      const id = launching.id
      await api.delete(`/api/formulalaunchings/${id}/`);

      navigate("/launchings");
      setBtnStatus(false);
      showAlert("Dados atualizados com sucesso!", "success");

    } catch (error) {
      console.error("Erro ao atualizar os dados: ", error);
      showAlert("Ocorreu um erro ao tentar atualizar os dados. Por favor, tente novamente.", "error");
    }
  };

  return (
    <Box sx={{ borderRadius: 2 }}>
      <Button
        variant="contained"
        color="error"
        onClick={handleOpen}
        startIcon={<DeleteIcon />}
      >
        Deletar lançamento
      </Button>

      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            width: 400,
            bgcolor: "background.paper",
            margin: "auto",
            marginTop: "10%",
            outline: "none",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            borderRadius: 3,
            boxShadow:
              "rgb(255 255 255 / 10 %) 0px 1px 24px, rgb(255 255 255 / 9 %) 0px 15px 12px",
          }}
        >
          <Title name="Criar Lançamento" colorName="red" />
          <Box sx={{ padding: 2, gap: 1, display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                gap: 1,
              }}>
              <Typography variant="h5">
                Tem certeza que quer deletar o lançamento <b style={{color: red[700] }}>{launching.name}</b> ?
              </Typography>

              <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
                <Button variant="outlined" color="error" onClick={handleClose}>
                  Fechar
                </Button>
                <Button variant="contained" color="error" type="submit" onClick={onSubmit} >
                  Deletar
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default ModalLauncherfy;
