import React from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import SubtitleCustom from "@/components/SubtitleCustom";
import RenderStepMapaLx from "@/components/Steps/RenderStepMapaLx";
import { useStep } from "@/reducers/step";

function Steps() {
  const [step] = useStep();

  const textColorMapping = {
    "#212121": "#fff",
    "#7CFB5D": "black",
    "#fff": "black",
    "#CF2CF6": "#fff",
    "#6310D6": "#fff",
    "#89E6FC": "black",
    "#F3AC3C": "black"
  };

  const color = Object.keys(textColorMapping)[step.step];

  return(
    <Paper sx={{ borderRadius: 3 }}>
      <SubtitleCustom name={step?.title || "Início"} colorName={color} />
      <Box sx={{ p:2}}>
        <Grid container sx={{direction: 'flex', justifyContent: 'center'}}>
          <Grid item xs={12} lg={9}>
            <RenderStepMapaLx steps={step.step}/>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}

export default Steps