import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";

import SplashScreen from "./components/SplashScreen";
import ProtectedRoute from "./components/ProtectedRoute";

import Layout from "@/Layout";

import Profile from "@/pages/Profile";
import Launchings from "@/pages/Launchings";
import Shared from "@/pages/Shared";
import ProfileStore from "@/pages/ProfileStore";

import Launcherfy from "@/pages/Launcherfy"; 
import Formula from "@/pages/Formula";
import MapaLx from "@/pages/MapaLx";

import Attributions from "@/pages/Attributions";

// import Traffic from "@/ignored/Traffic";
// import Meta from "@/ignored/Traffic/components/Meta";
// import MetaPublic from "@/ignored/Publics/Meta";
// import MetaCampaign from "@/ignored/Campaigns/Meta";

import Privacity from "@/pages/Home/pages/privacyPolicy"

const Login = lazy(() => import("@/pages/Login"));
const Register = lazy(() => import("@/pages/Register"));
const EditProfile = lazy(() => import("@/pages/EditProfile"));
const SuccessPage = lazy(() => import("@/pages/ProfileStore/components/Success"));
const CancelPage = lazy(() => import("@/pages/ProfileStore/components/CancelPage"));
const Contract = lazy(() => import("@/pages/Contract"));
const ProfileContact = lazy(() => import("@/pages/ProfileContact"));
const Home = lazy(() => import("@/pages/Home"));
const Method = lazy(() => import("@/pages/Method"));
const Schedule = lazy(() => import("@/pages/Schedule"));
const Debriefing = lazy(() => import("@/pages/Debriefing"));

function Router() {
  return (
    <Suspense fallback={<SplashScreen />}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/privacy-policy" element={<Privacity />} />
        <Route element={<Layout />}>          
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/store"
            element={
              <ProtectedRoute>
                <ProfileStore />
              </ProtectedRoute>
            }
          />
          <Route
            path="/store/success"
            element={
              <ProtectedRoute>
                <SuccessPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/store/cancel"
            element={
              <ProtectedRoute>
                <CancelPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile/contact"
            element={
              <ProtectedRoute>
                <ProfileContact />
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile/edit"
            element={
              <ProtectedRoute>
                <EditProfile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/launchings"
            element={
              <ProtectedRoute>
                <Launchings />
              </ProtectedRoute>
            }
          />
          <Route
            path="/shared"
            element={
              <ProtectedRoute>
                <Shared />
              </ProtectedRoute>
            }
          />
          <Route
            path="/:option/launcherfy/:id"
            element={
              <ProtectedRoute>
                <Launcherfy />
              </ProtectedRoute>
            }
          />
          <Route
            path="/:option/formula/:id"
            element={
              <ProtectedRoute>
                <Formula />
              </ProtectedRoute>
            }
          />
          <Route
            path="/:option/mapalx/:id"
            element={
              <ProtectedRoute>
                <MapaLx />
              </ProtectedRoute>
            }
          />
          <Route
            path="/:option/:product/:id/method"
            element={
              <ProtectedRoute>
                <Method />
              </ProtectedRoute>
            }
          />
          <Route
            path="/:option/:product/:id/debriefing"
            element={
              <ProtectedRoute>
                <Debriefing />
              </ProtectedRoute>
            }
          />
          <Route
            path="/:option/:product/:id/schedule"
            element={
              <ProtectedRoute>
                <Schedule />
              </ProtectedRoute>
            }
          />
          <Route
            path="/:option/:product/:id/attributions"
            element={
              <ProtectedRoute>
                <Attributions />
              </ProtectedRoute>
            }
          />
          <Route
            path="/:option/:product/:id/contract"
            element={
              <ProtectedRoute>
                <Contract />
              </ProtectedRoute>
            }
          />
          {/* <Route
            path="/traffic"
            element={
              <ProtectedRoute>
                <Traffic />
              </ProtectedRoute>
            }
          />
          <Route
            path="/traffic/meta"
            element={
              <ProtectedRoute>
                <Meta />
              </ProtectedRoute>
            }
          />
          <Route
            path="/traffic/meta/public/:user_id/:acc_id"
            element={
              <ProtectedRoute>
                <MetaPublic />
              </ProtectedRoute>
            }
          />
          <Route
            path="/traffic/meta/campaign/:user_id/:acc_id"
            element={
              <ProtectedRoute>
                <MetaCampaign />
              </ProtectedRoute>
            }
          /> */}
        </Route>
      </Routes>
    </Suspense>
  );
}

export default Router;