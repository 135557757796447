import { useQuery } from "@tanstack/react-query";
import api from "@/services/api";

function useLauncherfyById(id) {
  return useQuery({
    queryKey: ["launchingById", id],
    queryFn: () => api.get(`/api/launcherfylaunchings/${id}`).then((response) => response.data),
    enabled: !!id
  });
}

export default useLauncherfyById;
