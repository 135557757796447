import React, { useMemo, useContext, useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useParams } from "react-router-dom";
import { styled } from "@mui/system";
import { grey } from "@mui/material/colors";
import AlertContext from '@/components/Alert/AlertContext';
import api from '@/services/api';
import { useLocation } from 'react-router-dom';

function Drive({ launching }) {
  const fetchLaunchingList = launching;
  const memorizedLaunching = useMemo(() => {
    if (fetchLaunchingList) {
      return fetchLaunchingList;
    }
    return null;
  }, [fetchLaunchingList]);

  const StyledImg = styled('img')({
    width: '32px',
  });

  const { option, id } = useParams();
  let path

  const location = useLocation();
  const pathParts = location.pathname.split('/');
  const product = pathParts[2];

  if (option === "launchings") {
    path = `${product}${option}`
  }

  const[anchorEl, setAnchorEl] = useState(null);
  const [url, setUrl] = useState('');
  const [savedUrl, setSavedUrl] = useState(''); 
  const { showAlert } = useContext(AlertContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (memorizedLaunching?.driveUrl) {
          const url = memorizedLaunching.driveUrl;
          setUrl(url)
          setSavedUrl(url)
        }
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };
    fetchData();
  }, [id, memorizedLaunching]);

  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleInputChange = (event) => {
    setUrl(event.target.value);
  };

  const handleSubmit = async () => {
    setSavedUrl(url); 

    try {
      await api.patch(`/api/${path}/${id}/`, {
        driveUrl: url,
      });

      showAlert("Dados atualizados com sucesso!", "success");

    } catch (error) {
      console.error("Erro ao atualizar os dados: ", error);
      showAlert("Ocorreu um erro ao tentar atualizar os dados. Por favor, tente novamente.", "error");
    }

    handleClose();
  };

  const handleAccess = () => {
    if (savedUrl) {
      window.open(savedUrl, '_blank')
    } else {
      showAlert("Por favor, insira uma URL antes de tentar acessá-la.\n Clique com o botão direito para definir a url começando com http:// ou https://", "error");
    }
  };


  return (
    <Paper
      sx={{ borderRadius: 3, display: 'flex', flexDirection: 'column' }}
    >
      <Grid container sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', px: 2, py:1, flexDirection: 'row', alignItems: 'center' }}>
        <Grid item xs={12} sm={6} md={6} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: { xs: 'center', sm: 'flex-start', md: 'flex-start'}}}>
          <StyledImg
            src="https://kstatic.googleusercontent.com/files/f4b4fbcc6119576da7ab3f68270196009fc1b16f1927910842d793c385115593b6dd5fbe9a1e21fe64f3cbbc509c3a02c95ebc9635f76c355282482986f1fe7d"
            alt=""
          />
          <Typography variant="h4" sx={{pl: 0.5, color: grey[700]}}>
            Google Drive
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={6} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: { xs: 'center', sm: 'flex-end', md: 'flex-end' } }}>
          <Button onContextMenu={handleClick} onClick={handleAccess}>Acessar</Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem>
              <TextField
                label="Url"
                size="small"
                value={url}
                onChange={handleInputChange}
                onKeyDown={event => event.key === 'Enter' && handleSubmit()}
              />
              <Button
                onClick={handleSubmit}
              >
                Salvar
              </Button>
            </MenuItem>
          </Menu>
        </Grid>

      </Grid>
    </Paper>
  )
}

export default Drive