import { useContext } from 'react';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import AlertContext from './AlertContext';

function AlertSnackbar() {

  const { alertData, showAlert } = useContext(AlertContext);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    showAlert('', '', false);
  };

  const validSeverities = ["error", "info", "success", "warning"];
  const severity = validSeverities.includes(alertData.severity) ? alertData.severity : "success";

  return (
    <Snackbar open={alertData.open} autoHideDuration={6000} onClose={handleClose}>
      <MuiAlert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
        {alertData.message}
      </MuiAlert>
    </Snackbar>
  );
}

export default AlertSnackbar;
