import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import { auth } from "@/services/firebase";
import { signOut, sendEmailVerification } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useUser } from "@/reducers/user";
import { Outlet } from "react-router-dom";
import DrawerComponent from "./components/DrawerComponent";
import { indigo, orange } from "@mui/material/colors";
import AppBarConainer from "./components/AppBar";
import MuiAppBar from "@mui/material/AppBar";
import AlertContext from '@/components/Alert/AlertContext';
import AlertSnackbar from '@/components/Alert/AlertSnackbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

const drawerWidth = 200;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  border: "none",
  padding: 0,
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(5)} + 8px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(6)} +20px)`,
  },
  border: "none",
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  boxShadow: "none",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  boxShadow: "none",
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  '& .MuiToolbar-root': {
    paddingLeft: "20px",
    paddingRight: "20px",
  }
}));

function Layout() {
  const [alertData, setAlertData] = useState({ open: false, message: '', severity: 'success' });

  const showAlert = (message, severity = 'success', open = true) => {
    setAlertData({ open, message, severity });
  };

  const navigate = useNavigate();
  const [user, setUser] = useUser();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElLink, setAnchorElLink] = useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuLink = (event) => {
    setAnchorElLink(event.currentTarget);
  };

  const handleCloseLink = () => {
    setAnchorElLink(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    signOut(auth).then(() => {
      navigate("/login");
      setUser({ type: "USER_RESET" });
    });
  };


  const handleVerification = () => {
    const lastSent = localStorage.getItem('lastEmailSent') || 0

    const now = new Date().getTime();

    if (lastSent && now - lastSent < 10 * 60 * 1000) {
      console.log("caiu no if")
      showAlert("Aguarde 10 minutos para solicitar um novo e-mail de verificação.", "warning");
      return;
    } 

    sendEmailVerification(auth.currentUser).then(() => {
      showAlert("E-mail de verificação enviado!", "success");
      localStorage.setItem('lastEmailSent', now.toString());
    }).catch((error) => {
      showAlert(`Erro ao enviar e-mail de verificação: ${error} `, "error");
    });
  }

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => setWindowHeight(window.innerHeight);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const props = { handleDrawerClose, DrawerHeader, open, Drawer, handleMenu, handleClose, handleMenuLink, handleCloseLink, handleDrawerOpen, handleLogout, anchorEl, anchorElLink, AppBar }
  return (
    <AlertContext.Provider value={{ alertData, showAlert }}>
      <AlertSnackbar />
      <Box sx={{ display: "flex"}}>
        <CssBaseline />
        <AppBarConainer  {...props}/>
        <DrawerComponent {...props}/>
        <Box component="main" sx={{ flexGrow: 1 }}>
          <DrawerHeader />
          <Box sx={{ 
            background: indigo['100'], 
            p: 1, 
            borderRadius: "16px 16px 0 0 ", 
            border: `solid 1px ${indigo['200']}`, 
            marginRight: 1.4, 
            overflow: 'auto',
            // height: 'calc(100vh - 64px)',
            height: `${windowHeight - 64}px`,
            marginTop: 'env(safe-area-inset-top)',
            width: open ? 'calc(100vw - 210px)' : 'calc(100vw - 60px)'
          }}>
            {!user.emailVerified &&
              <Stack sx={{ position: 'absolute', mt: 1, width: open ? 'calc(100vw - 238px)' : 'calc(100vw - 88px)', '& .MuiPaper-elevation':{backgroundColor: 'rgb(255, 244, 229, 0.9)', border: `1px solid ${orange[200]}`, alignItems: 'center', alignSelf: 'center', ml: '10px', zIndex: 1} }} spacing={2}>
                <Alert sx={{ mb: 1, borderRadius: 2, display: 'flex', flexDirection: 'row', justifyContent: 'center', textAlign: 'center', '& .MuiAlert-icon': { alignItems: 'center' }, '& .MuiAlert-message': { display: 'contents' } }} severity="warning">
                  <Typography variant="h6" sx={{ float: 'left', alignSelf: 'center', color: orange[800] }}>Por favor, verifique seu e-mail para completar o cadastro.</Typography>
                  <Button
                    size="small"
                    onClick={handleVerification}
                    variant="contained"
                    sx={{
                      background: `linear-gradient(to right, ${orange[300]}, ${orange[400]})`, border: `1px solid ${orange[500]}`, p: 1, borderRadius: 2, '&:hover': {
                        background: `linear-gradient(to right, ${orange[300]}, ${orange[500]})`,
                      },
                      py: 0,
                      mx: 1,                      
                    }}
                  >
                    Reenviar e-mail
                  </Button>
                  </Alert>
              </Stack>
            }
            <Outlet />
          </Box>
        </Box>
      </Box>
    </AlertContext.Provider>
  );
}

export default Layout;
