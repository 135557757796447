import React, {useMemo} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as colors from "@mui/material/colors";

function SubtitleLaunchings({ name, colorName }) {
  const color = useMemo(() => colors[colorName] || colors["indigo"], [colorName]);
  
  return (
    <Box
      sx={{
        padding: "4px 16px",
        background: `linear-gradient(to right, ${color[600]}, #000)`,
        borderRadius: 2,
        border: `solid 1px ${color[800]}`,
        mx: 1,
        marginTop: "8px",
      }}
    >
      <Typography variant="h5" sx={{ color: "#ffffff" }}>
        {name}
      </Typography>
    </Box>
  );
}

export default SubtitleLaunchings;
