import React from "react";
import Box from "@mui/material/Box";
import { indigo } from "@mui/material/colors";
import NicheForm from "./NicheForm";
import PersonaForm from "./PersonaForm";
import Creatives from "./Creatives";
import PromiseForm from "./PromiseForm";
import RegistrationListForm from "./RegistrationListForm";
import EventForm from "./EventForm";
import SalesVideoScriptForm from "./SalesVideoScriptForm";
import RegistrationDomainForm from "./RegistrationDomainForm";
import ConfigForm from "./ConfigForm";
import NotificationsForm from "./NotificationForm";
import CreativesRecForm from "./CreativesRecForm";
import CapPageForm from "./CapPageForm";
import ThankPageForm from "./ThankPageForm";
import EmailMarketingForm from "./EmailMarketingForm"
import PixelForm from "./PixelForm"
import WhatsForm from "./WhatsForm";
import TestForm from "./TestForm";
import CampaignForm from "./CampaignForm";
import TestCampaignForm from "./TestCampaignForm";
import TrafficTable from "./TrafficTable";
import SalesPageForm from "./SalesPageForm";
import DeliverableForm from "./DeliverableForm"
import BonusForm from "./BonusForm";
import PriceForm from "./PriceForm";
import WarrantyForm from "./WarrantyForm";
import PixelSalesForm from "./PixelSalesForm";
import LinkCheckoutForm from "./LinkCheckoutForm";
import VerifyOneForm from "./VerifyOneForm";
import VerifyTwoForm from "./VerifyTwoForm";
import TextNotificationG from "./TextNotificationG";
import LaunchingScriptForm from "./LaunchingScriptForm";
import NotificationsPosForm from "./NotificationPosForm";
import RecordTrainingForm from "./RecordTrainingForm";
import OptimizationOneForm from "./OptimizationOneForm";
import OptimizationTwoForm from "./OptimizationTwoForm";
import TextNotification from "./TextNotification";
import useLauncherfyById from '@/queries/useLauncherfyById';
import useSharedLListId from "@/pages/Shared/queries/useSharedLListId";
// import AttributionsForm from "./AttributionsForm";
import { useParams } from "react-router-dom";
import CreatePagesForm from "./CreatePagesForm";
import CreateWhastsForm from "./CreateWhastsForm"
import Debriefing from "./Debriefing";

function RenderStep({steps}) {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const { option } = useParams();

  const apiHook = option === "launchings" ? useLauncherfyById : useSharedLListId;

  let launching = apiHook;
  let path

  if (option === "launchings") {
    path = "launcherfylaunchings"
  } else {
    path = "launcherfyshared"
  }

  switch (steps) {
    case 1:
      return (
        <Box sx={{ p: 1, borderRadius: 2, border: `solid 1px ${indigo[500]}`, backgroundColor: indigo[200] }}>
          {/* <AttributionsForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="1" /> */}
          <NicheForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="1" />
          <PersonaForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="2" />
          <PromiseForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="3"  />
          <EventForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="4" />
          <RegistrationListForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="5" />
        </Box>
      );
    case 2:
      return (
        <Box sx={{ p: 1, borderRadius: 2, border: `solid 1px ${indigo[500]}`, backgroundColor: indigo[200] }}>
          <Creatives path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="1" />
          <SalesVideoScriptForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="2" />
          <CapPageForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="3" />
          <ThankPageForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="4" />
        </Box>
      )
    case 3:
      return (
        <Box sx={{ p: 1, borderRadius: 2, border: `solid 1px ${indigo[500]}`, backgroundColor: indigo[200] }}>
          <RegistrationDomainForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="1" />
          <ConfigForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="2" />
          <CreateWhastsForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="3" />
        </Box>
      )
    case 4:
      return (
        <Box sx={{ p: 1, borderRadius: 2, border: `solid 1px ${indigo[500]}`, backgroundColor: indigo[200] }}>
          <NotificationsForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="1" />
        </Box>
      )
    case 5:
      return (
        <Box sx={{ p: 1, borderRadius: 2, border: `solid 1px ${indigo[500]}`, backgroundColor: indigo[200] }}>
          <CreativesRecForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="1" />
        </Box>
      )
    case 6:
      return (
        <Box sx={{ p: 1, borderRadius: 2, border: `solid 1px ${indigo[500]}`, backgroundColor: indigo[200] }}>
          <CreatePagesForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="2" />
          <EmailMarketingForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="3" />
          <PixelForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="4" />
          <WhatsForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="5" />
          <TestForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="6" />
        </Box>
      )
    case 7:
      return (
        <Box sx={{ p: 1, borderRadius: 2, border: `solid 1px ${indigo[500]}`, backgroundColor: indigo[200] }}>          
          <CampaignForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="1" />
          <TestCampaignForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="2" />
        </Box>
      )
    case 8:
      return (
        <Box sx={{ p: 1, borderRadius: 2, border: `solid 1px ${indigo[500]}`, backgroundColor: indigo[200] }}>
          <TextNotificationG text="Início da campanha"/>          
          <TrafficTable path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="1" />          
        </Box>
      )
    case 9:
      return (
        <Box sx={{ p: 1, borderRadius: 2, border: `solid 1px ${indigo[500]}`, backgroundColor: indigo[200] }}>
          <VerifyOneForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="1" />
          <DeliverableForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="2" />
          <BonusForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="3" />
          <PriceForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="4" />
          <WarrantyForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="5" />
        </Box>
      )
    case 10:
      return (
        <Box sx={{ p: 1, borderRadius: 2, border: `solid 1px ${indigo[500]}`, backgroundColor: indigo[200] }}>
          <LaunchingScriptForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="1" /> 
          <RecordTrainingForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="2" /> 
        </Box>
      )
    case 11:
      return (
        <Box sx={{ p: 1, borderRadius: 2, border: `solid 1px ${indigo[500]}`, backgroundColor: indigo[200] }}>
          <SalesPageForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="1" />
          <PixelSalesForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="2" />
          <LinkCheckoutForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="3" />
        </Box>
      )
    case 12:
      return (
        <Box sx={{ p: 1, borderRadius: 2, border: `solid 1px ${indigo[500]}`, backgroundColor: indigo[200] }}>
          <TrafficTable path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="1" />   
          <OptimizationOneForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="2" />
        </Box>
      )
    case 13:
      return (
        <Box sx={{ p: 1, borderRadius: 2, border: `solid 1px ${indigo[500]}`, backgroundColor: indigo[200] }}>
          <NotificationsPosForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="1" />        
        </Box>
      )
    case 14:
      return (
        <Box sx={{ p: 1, borderRadius: 2, border: `solid 1px ${indigo[500]}`, backgroundColor: indigo[200] }}>
          <VerifyTwoForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="1" />
        </Box>
      )
    case 15:
      return (
        <Box sx={{ p: 1, borderRadius: 2, border: `solid 1px ${indigo[500]}`, backgroundColor: indigo[200] }}>
          <TrafficTable path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="1" />
          <OptimizationTwoForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="2" />
          <TextNotification text="Faltam 7 dias" />
        </Box>
      )
    case 16:
      return (
        <Box sx={{ p: 1, borderRadius: 2, border: `solid 1px ${indigo[500]}`, backgroundColor: indigo[200] }}>
          <TextNotification text="Faltam 4 dias" />
        </Box>
      )
    case 17:
      return (
        <Box sx={{ p: 1, borderRadius: 2, border: `solid 1px ${indigo[500]}`, backgroundColor: indigo[200] }}>
          <TextNotification text="É amanhã" />
        </Box>
      )
    case 18:
      return (
        <Box sx={{ p: 1, borderRadius: 2, border: `solid 1px ${indigo[500]}`, backgroundColor: indigo[200] }}>
          <TextNotificationG text="É hoje!!" />          
        </Box>
      )
    default:
      return (
        <Box sx={{ p: 1, borderRadius: 2, border: `solid 1px ${indigo[500]}`, backgroundColor: indigo[200] }}>
          {/* <AttributionsForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="1" /> */}
          <NicheForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="1" />
          <PersonaForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="2" />
          <PromiseForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="3" />
          <EventForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="4" />
          <RegistrationListForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="5" />
        </Box>
      );
  }
}

export default RenderStep
