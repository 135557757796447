import { useState, useEffect, useCallback } from 'react';

export const useFieldCheckPages = (getValues, setValue, setBtnStatus, fieldName) => {
  const [totalCharacterCount, setTotalCharacterCount] = useState(0);
  const [allFieldsFilled, setAllFieldsFilled] = useState(false);

  const updateValues = useCallback(() => {
    const updatedValues = getValues();
    const updatedTextFieldValues = [Object.values(updatedValues)[0] || []]
    const totalCharCount = JSON.stringify(updatedTextFieldValues).length;
    setTotalCharacterCount(totalCharCount);
    const filledFields = updatedTextFieldValues.every(
      value => (typeof value === 'string' && value.trim().length > 0) || value === true
    );
    setAllFieldsFilled(filledFields);
  }, [getValues]);

  const handleFieldChange = useCallback((fieldPath, value) => {
    if (Array.isArray(fieldName) && fieldName[0] && fieldName[0] !== null && fieldPath === fieldName[0]) {
      setValue(fieldPath, [value, ...fieldName.slice(1)]);
    } else {
      setValue(fieldPath, value);
    }
    setBtnStatus(true);
    updateValues();
  }, [setValue, setBtnStatus, updateValues, fieldName]);

  useEffect(() => {
    updateValues();
  }, [updateValues]);

  return { handleFieldChange, totalCharacterCount, allFieldsFilled, updateValues };
}
