import React, { useState, useContext, useEffect } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import { indigo, green, red } from '@mui/material/colors';
import { useFieldChangeEsp } from './useFieldChangeEsp';
import api from '@/services/api';
import { useParams } from 'react-router-dom';
import AlertContext from '../Alert/AlertContext';
import { useCustomQueryClient } from './hooks/useCustomQueryClient';
import { useQueryClient } from '@tanstack/react-query';

function NicheForm({ initialNiches = [], handleChange, expanded, panel, path, useLauncherfyById}) {
  const [btnStatus, setBtnStatus] = useState(false);
  const { showAlert } = useContext(AlertContext);
  const { id } = useParams();
  const { data: launching, isSuccess } = useLauncherfyById(id);
  const queryClient = useQueryClient();
  const invalidate = useCustomQueryClient(queryClient)

  const { register, handleSubmit, control, setValue, getValues, reset } = useForm({
    defaultValues: {
      niches: initialNiches.length > 0
        ? initialNiches.map(niche => ({ niche }))
        : [{ niche: '' }],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'niches',
  });

  const { handleFieldChange, allFieldsFilled } = useFieldChangeEsp(getValues, setValue, setBtnStatus, 'niche');

  const handleAddSubNiche = () => {
    if (fields.length < 6) {
      append({ niche: '' });
    }
  };

  useEffect(() => {
  const fetchData = async () => {
    try {
      if (isSuccess && launching && launching.niche) {
        const nicheArray = JSON.parse(launching.niche);
        const niches = nicheArray.slice(0, 6).map(niche => ({ niche }));
        reset({ niches });
        handleFieldChange();
        setBtnStatus(false);
      }
    } catch (error) {
      console.error('Failed to fetch data:', error);
    }
  };
  fetchData();
  }, [id, reset, handleFieldChange, launching, isSuccess]);

  function shouldReplaceWithEmptyString(str) {
    const cleanedString = str.replace(/[\s\[\]'",]/g, "");
    return cleanedString === "";
  }

  const onSubmit = async (data) => {
    try {
      const values = data.niches.map(niche => niche.niche)
      let combinedString = JSON.stringify(values);
      if (shouldReplaceWithEmptyString(combinedString)) {
        combinedString = null
      }
      await api.patch(`/api/${path}/${id}/`, {
        niche: combinedString,
      });
      invalidate.invalidateQueries()

      setBtnStatus(false);
      showAlert("Dados atualizados com sucesso!", "success");

    } catch (error) {
      console.error("Erro ao atualizar os dados: ", error);
      showAlert("Ocorreu um erro ao tentar atualizar os dados. Por favor, tente novamente.", "error");
    }
  };

  return (
    <Accordion
      expanded={expanded === `panel${panel}`} onChange={handleChange(`panel${panel}`)}
      sx={{ borderRadius: 2, mb: 0.2, p: 0.5, position: 'initial', border: `solid 1px ${indigo[100]}` }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${panel}bh-content`}
        id={`panel${panel}bh-header`}
        sx={{
          // background: `linear-gradient(to right, ${indigo[200]}, ${indigo[400]})`,
          borderRadius: 2,
          minHeight: 0,
          ml:1,
          '&.Mui-expanded': {
            minHeight: 0,
          },
          '&::before': {content: '""', position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)', width: 10, height: 10, borderRadius: '50%', backgroundColor: allFieldsFilled ? green[500] : red[400]},
        }}
      >
        <Typography sx={{ width: '33%', flexShrink: 0 }}>
          Nicho
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 1, pt: 2 }}>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            gap: 1,
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", alignSelf: "center", gap: 1, backgroundColor: indigo[50], p: 2, borderRadius: 2, width: 'inherit' }}>
            {fields.map((item, index) => (
              <Box key={item.id} display="flex" alignItems="center" sx={{ gap: 1 }}>
                <TextField
                  key={`niche_${index}`}
                  {...register(`niches.${index}.niche`)}
                  label={index === 0 ? 'Nicho' : 'Subnicho'}
                  variant="outlined"
                  size="small"
                  sx={{
                    width: '100%', backgroundColor: '#fff', borderRadius: 2,
                    '& label.MuiInputLabel-shrink': {
                      backgroundColor: indigo[50],
                      borderRadius: 3, px: 0.8,
                    } }}
                  onChange={(event) => {
                    handleFieldChange(`niches.${index}.niche`, event.target.value);
                  }}

                />
                {index !== 0 && (
                  <Button variant="outlined" color="error" size="medium" onClick={() => {
                    remove(index)
                    setBtnStatus(true)
                    }}>
                    <Typography variant="h1">-</Typography>
                  </Button>
                )}
              </Box>
            ))}
          </Box>
          <Divider />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              gap: 1,
            }}
          >
            <Button variant="contained" onClick={handleAddSubNiche} disabled={fields.length === 6}>
              + Adicionar
            </Button>
            {btnStatus ?
              <Button type="submit">Salvar</Button>
              :
              <Button disabled>Salvar</Button>
            }
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

export default NicheForm;
