import React, {useContext} from "react";
import { useForm } from "react-hook-form";
import Modal from "@mui/material/Modal";
import Title from "@/components/Title";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import AlertContext from '@/components/Alert/AlertContext';
import api from '@/services/api';
import { useQueryClient } from '@tanstack/react-query';

function ModalTeam({handleClose, handleOpen, open, groupId}) {
  const { register, handleSubmit } = useForm({
    team: ''
  });
  
  const queryClient = useQueryClient();
  const { showAlert } = useContext(AlertContext);

  const onSubmit = async (data) => {
    try {
      await api.patch(`/api/groups/${groupId}`, data);

      queryClient.invalidateQueries('organization');
      showAlert("Usuário adicionado", "success");

      handleClose()
    } catch (error) {
      console.error(error.response.data.error);
      showAlert(error.response.data.error, "error");
    }
  };

  return(
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          width: 400,
          bgcolor: "background.paper",
          margin: "auto",
          marginTop: "10%",
          outline: "none",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          borderRadius: 3,
          boxShadow:
            "rgb(255 255 255 / 10 %) 0px 1px 24px, rgb(255 255 255 / 9 %) 0px 15px 12px",
        }}
      >
        <Title name="Adicionar membro ao grupo" />
        <Box sx={{ padding: 2, gap: 1, display: "flex", flexDirection: "column" }}>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: 1,
            }}>
            <TextField
              label="email"
              {...register("email")}
              fullWidth
              size="small"
            />
            <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
              <Button variant="outlined" color="primary" onClick={handleClose}>
                Fechar
              </Button>
              <Button variant="contained" color="primary" type="submit">
                Adicionar
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default ModalTeam