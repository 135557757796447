import React, { useState, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import { indigo, green, red } from '@mui/material/colors';
import { useFieldCheckPages } from './useFieldCheckPages';
import api from '@/services/api';
import { useParams } from 'react-router-dom';
import AlertContext from '../Alert/AlertContext';
import WysiwygPage from './Wysiwyg';

function ThankPageForm({ handleChange, expanded, panel, path, useLauncherfyById }) {
  const [btnStatus, setBtnStatus] = useState(false);
  const { showAlert } = useContext(AlertContext);
  const { id } = useParams();
  const { data: launching, isSuccess } = useLauncherfyById(id);
  const [editorContent, setEditorContent] = useState('');

  const { handleSubmit, setValue, getValues, reset } = useForm({
    defaultValues: {
      thankPage: ''
    },
  });

  const { handleFieldChange, totalCharacterCount, allFieldsFilled } = useFieldCheckPages(getValues, setValue, setBtnStatus, 'thankPage');

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isSuccess && launching) {
          const thankPage = launching.thankPage || `<h1 class="ql-align-center"><strong style="background-color: rgb(255, 0, 0); color: rgb(0, 0, 0);">IMPORTANTE&nbsp;&nbsp;</strong></h1><p><br></p><p><br></p><p class="ql-align-center"><strong style="background-color: transparent; color: rgb(0, 0, 0);">PÁGINA DE OBRIGADO</strong></p><p><br></p><p class="ql-align-center"><strong style="background-color: transparent; color: rgb(0, 0, 0);">FALTA APENAS 1 PASSO…&nbsp;</strong></p><p class="ql-align-center"><br></p><p class="ql-align-center"><span style="background-color: transparent; color: rgb(0, 0, 0);">Para você garantir que vai receber todo o material e os nossos avisos, você precisa entrar no grupo exclusivo de WhatsApp que eu criei para a Semana do Marketing Digital.&nbsp;</span></p><p class="ql-align-center"><br></p><p class="ql-align-center"><span style="background-color: transparent; color: rgb(0, 0, 0);">Fique tranquilo, o tempo é um ativo muito valioso para mim, não vou ficar mandando mensagens desnecessárias, por isso, esse grupo será fechado, apenas para que eu envie mensagens pontuais para você.&nbsp;</span></p><p class="ql-align-center"><br></p><p class="ql-align-center"><span style="background-color: transparent; color: rgb(0, 0, 0);">Clique no botão abaixo e acesse o grupo:&nbsp;</span></p><p><br></p><p class="ql-align-center"><strong style="background-color: transparent; color: rgb(0, 0, 0);"><u>QUERO ENTRAR NO GRUPO DE WHATSAPP</u></strong></p><p><br></p><p class="ql-align-center"><br></p>`;
          setEditorContent(thankPage);
          reset({ thankPage });
          handleFieldChange();
          setBtnStatus(false);
        }
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };
    fetchData();
  }, [id, reset, handleFieldChange, launching, isSuccess]);

  const onSubmit = async (data) => {
    try {

      await api.patch(`/api/${path}/${id}/`, 
        data
      );

      setBtnStatus(false);
      showAlert("Dados atualizados com sucesso!", "success");

    } catch (error) {
      console.error("Erro ao atualizar os dados: ", error);
      showAlert("Ocorreu um erro ao tentar atualizar os dados. Por favor, tente novamente.", "error");
    }
  };

  return (
    <Accordion expanded={expanded === `panel${panel}`} onChange={handleChange(`panel${panel}`)}
      sx={{ borderRadius: 2, mb: 0.2, p: 0.5, position: 'initial', border: `solid 1px ${indigo[100]}` }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${panel}bh-content`}
        id={`panel${panel}bh-header`}
        sx={{
          borderRadius: 2, minHeight: 0, ml:1, '&.Mui-expanded': {minHeight: 0, height: '40px',}, '&::before': {content: '""', position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)', width: 10, height: 10, borderRadius: '50%', backgroundColor: allFieldsFilled ? green[500] : red[400]},
        }}
      >
        <Typography sx={{ width: '100%', flexShrink: 0 }}>
          Página de obrigado
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 1, pt: 2 }}>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{display: 'flex', flexDirection: 'column', width: '100%', gap: 1}}
        >          
          <Box sx={{ display: "flex", flexDirection: "row", alignSelf: "center", gap: 1}} >
            <WysiwygPage content={editorContent} setContent={setEditorContent} setBtnStatus={setBtnStatus} handleFieldChange={handleFieldChange} fieldName="thankPage" sx={{height: 'fit-content'}}/>
          </Box>
          <Typography>
            Caracteres: {10000 - totalCharacterCount}
          </Typography>
          <Divider />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              gap: 1,
            }}
          >
            {btnStatus ? (
              <Button type="submit">Salvar</Button>
            ) : (
              <Button disabled>Salvar</Button>
            )}
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

export default ThankPageForm;
