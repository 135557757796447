import { useQuery } from "@tanstack/react-query";
import api from "@/services/api";

function useSharedLList() {
  return useQuery({
    queryKey: ["sharedLLists"],
    queryFn: () => api.get(`/sharedllists`).then((response) => response.data),
  });
}

export default useSharedLList;
