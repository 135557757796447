import React, {useState, useMemo} from 'react';
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { indigo } from "@mui/material/colors";
import CardStats from "./Card";
import FortIcon from '@mui/icons-material/Fort';
import SubtitleLaunchings from "@/components/SubtitleLaunchings";
import useSharedLList from '../queries/useSharedLList';
import moment from 'moment';
import { Link } from "react-router-dom";

function Launcherfy() {
  const [hideBaseLaunch, setHideBaseLaunch] = useState(false);
  const [hideCompleteLaunch, setHideCompleteLaunch] = useState(false);

  const fetchLaunchingList = useSharedLList();
  const list = useMemo(() => {
    if (fetchLaunchingList.isSuccess) {
      return fetchLaunchingList.data;
    }
    return [];
  }, [fetchLaunchingList]);


  const listBase = list && list.filter(item => item.launchType === "base");
  const listCompleto = list && list.filter(item => item.launchType === "completo");

  return(
    <Paper
        sx={{ borderRadius: 3, display: 'flex', flexDirection: 'column', gap: 2 }}
      >
        <SubtitleLaunchings name="Launcherfy" />
        <Box sx={{ width: '100%', display: 'flex', px: 1, pb: 1}}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} sx={{ display: 'flex', flexDirection: 'column', height: 'fit-content' }}>
              <Grid item xs={12} sx={{
                display: 'flex', justifyContent: 'space-between',
                bgcolor: '#fff',
                borderRadius: 2,
                border: `solid 1px ${indigo[300]}`,
                px: 2,
              }}>
                <Typography variant="h6" color={indigo[700]} sx={{alignSelf: 'center'}}>Lançamento Base</Typography>
                <Button sx={{ px: 1 }} onClick={() => setHideBaseLaunch(!hideBaseLaunch)}>
                  {hideBaseLaunch ? "Mostrar" : "Esconder"}
                </Button>
              </Grid>
              {!hideBaseLaunch && <Grid item xs={12}>  
              {listBase && listBase.length > 0 ? listBase.map((item, index) => (
                <Button key={`base_bt_${index}`}  component={Link} sx={{ width: '100%', p: 0, my: 1 }} to={`/shared/launcherfy/${item.id}`}>
                  <CardStats statTitle={item.name} date={moment(item.launchDate, "YYYY-MM-DD").format("DD/MM/YYYY")} progressValue={item.progress} />
                </Button>
              ))
                :
                <Box sx={{ width: '100%', p: 0, my: 1 }}>
                  <CardStats colorName="grey" progressValue={0} />
                </Box>
              }   
              </Grid>}
            </Grid>
            <Grid item xs={12} sm={6} sx={{ display: 'flex', flexDirection: 'column', height: 'fit-content' }}>
              <Grid item xs={12} sx={{
                display: 'flex', justifyContent: 'space-between', 
                bgcolor: indigo[300],
                borderRadius: 2,
                border: `solid 1px ${indigo[500]}`,
                px: 2,
              }}>
                <Typography variant="h6" color={indigo[900]}  sx={{ alignSelf: 'center' }}>Lançamento Completo</Typography>
                <Button sx={{ px: 1 }} onClick={() => setHideCompleteLaunch(!hideCompleteLaunch)}>
                  {hideCompleteLaunch ? "Mostrar" : "Esconder"}
                </Button>
              </Grid>
              {!hideCompleteLaunch && 
              <Grid item xs={12}>                
                {listCompleto && listCompleto.length > 0 ? listCompleto.map((item) => (
                  <Button sx={{ width: '100%', p: 0, my: 1 }}>
                    <CardStats StatIconComponent={FortIcon} statTitle={item.name} date={moment(item.launchDate, "YYYY-MM-DD").format("DD/MM/YYYY")} progressValue={item.progress} />
                  </Button>
                ))
                  :
                  <Box sx={{ width: '100%', p: 0, my: 1 }}>
                    <CardStats colorName="grey" StatIconComponent={FortIcon} progressValue={0} />
                  </Box>
                }              
              </Grid>
              }
            </Grid>
          </Grid>
        </Box>        
      </Paper>
  )
}

export default Launcherfy