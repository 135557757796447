import React, { useState, useContext, useEffect } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import Button from '@mui/material/Button';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import { indigo, green, red, grey } from '@mui/material/colors';
import { useFieldChangeEsp } from './useFieldChangeEsp';
import api from '@/services/api';
import { useParams } from 'react-router-dom';
import AlertContext from '../Alert/AlertContext';
import { useCustomQueryClient } from './hooks/useCustomQueryClient';
import { useQueryClient } from '@tanstack/react-query';

function CreativesRecForm({ initialCreatives = [], handleChange, expanded, panel, path, useLauncherfyById }) {
  const [btnStatus, setBtnStatus] = useState(false);
  const { showAlert } = useContext(AlertContext);
  const { id } = useParams();
  const { data: launching, isSuccess } = useLauncherfyById(id);
  const queryClient = useQueryClient();
  const invalidate = useCustomQueryClient(queryClient)

  const { register, handleSubmit, control, setValue, getValues, reset } = useForm({
    defaultValues: {
      creativeRecs: initialCreatives.length > 0 ? initialCreatives : [{ copy: '' }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'creativeRecs',
  });

  const { handleFieldChange, totalCharacterCount, allFieldsFilled } = useFieldChangeEsp(getValues, setValue, setBtnStatus, 'creativeRecs');

  const handleAddCreative = () => {
    if (fields.length < 15) {
      append({ copy: '' });
    }
  };

// eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isSuccess && launching && launching.creativeRecs) {
          const parsedData = JSON.parse(launching.creativeRecs);
          const creatives = parsedData.creativeRecs;

          // Comparando os valores atuais com os recebidos
          const currentValues = getValues().creativeRecs;
          const newValues = creatives.length > 0 ? creatives : [{ copy: '' }];

          const isDifferent = JSON.stringify(currentValues) !== JSON.stringify(newValues);

          if (isDifferent) {
            reset({ creativeRecs: newValues });
            handleFieldChange();
            setBtnStatus(false);
          }
        }
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };

    fetchData();
  }, [id, reset, launching, isSuccess, getValues, handleFieldChange]);

  function shouldReplaceWithEmptyString(str) {
    try {
      const parsedObject = JSON.parse(str);
      if (Array.isArray(parsedObject.creativeRecs) && parsedObject.creativeRecs.length === 1 && parsedObject.creativeRecs[0].copy === "") {
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  }

  const onSubmit = async (data) => {
    try {
      let combinedString = JSON.stringify(data);
      if (shouldReplaceWithEmptyString(combinedString)) {
        combinedString = null
      }

      await api.patch(`/api/${path}/${id}/`, {
        creativeRecs: combinedString,
      });

      invalidate.invalidateQueries()
      setBtnStatus(false);
      showAlert("Dados atualizados com sucesso!", "success");

    } catch (error) {
      console.error("Erro ao atualizar os dados: ", error);
      showAlert("Ocorreu um erro ao tentar atualizar os dados. Por favor, tente novamente.", "error");
    }
  };

  return (
    <Accordion expanded={expanded === `panel${panel}`} onChange={handleChange(`panel${panel}`)}
      sx={{ borderRadius: 2, mb: 0.2, p: 0.5, position: 'initial', border: `solid 1px ${indigo[100]}` }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${panel}bh-content`}
        id={`panel${panel}bh-header`}
        sx={{
          borderRadius: 2,
          minHeight: 0,
          ml:1,
          '&.Mui-expanded': {
            minHeight: 0,
          },
          '&::before': {content: '""', position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)', width: 10, height: 10, borderRadius: '50%', backgroundColor: allFieldsFilled ? green[500] : red[400]},
        }}
      >
        <Typography sx={{ width: '33%', flexShrink: 0 }}>
          Descrição e copy dos anúncios
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 1, pt: 2 }}>
        <Box
          component="form" onSubmit={handleSubmit(onSubmit)}
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: 1,
          }}>
          <Box sx={{ display: "flex", flexDirection: "column", alignSelf: "stretch", gap: 1, backgroundColor: indigo[50], p: 2, borderRadius: 2 }}>
          {fields.map((item, index) => {
            return (            
              <Box key={`creative_fields_${index}`} display="flex" alignItems="center" flexDirection="column" sx={{ gap: 1 }}>
              <Typography variant="h6" sx={{ alignSelf: 'flex-start' }}>{index === 0 ? "Copy do Criativo" : `Copy do criativo ${index + 1}`}</Typography>
              <TextareaAutosize
                {...register(`creativeRecs.${index}.copy`)}
                placeholder={index === 0 ? "Copy do Criativo" : `Copy do criativo ${index + 1}`}
                minRows={3}
                style={{
                  width: '100%',
                  height: '100px',
                  borderRadius: '4px',
                  borderColor: grey[400],
                  padding: '8px',
                  fontFamily: "Roboto",
                  font: 'inherit'
                }}
                onChange={(e) => {
                  // handleFieldChange(e, index, 'creativeRecs');
                  handleFieldChange(`creativeRecs.${index}.copy`, e.target.value);
                }}
              />
              {index !== 0 && (
                <Button variant="outlined" color="error" size="medium" onClick={() => {
                  remove(index)
                  setBtnStatus(true)
                }}>
                  <Typography variant="h1">-</Typography>
                </Button>
              )}
            </Box>
            )
            })}
          </Box>
          <Typography>
            Caracteres: {10000 - totalCharacterCount}
          </Typography>
          <Divider/>
          <Box
            sx={{
              display: "flex",
              flexDirection: {xs: "column", md: "row"},
              justifyContent: "space-between",              
              gap: 1,
            }}>
            <Button variant="contained" onClick={handleAddCreative} disabled={fields.length === 15}>
              + Adicionar
            </Button>
            <Typography variant='h6' color={red[500]}>
              Atenção! Ao clicar em adicionar, ele considerará a quantidade de criativos existentes
            </Typography>
            {btnStatus ?
              <Button type="submit">Salvar</Button>
              :
              <Button disabled>Salvar</Button>
            }
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default CreativesRecForm;
