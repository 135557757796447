import React, { useEffect, useContext, useState} from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import { styled } from "@mui/system";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import AlertContext from '@/components/Alert/AlertContext';
import api from '@/services/api';
import ModalTeam from "./ModalTeam";
import ModalDeleteTeam from "./ModalDeleteTeam";
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ClearIcon from '@mui/icons-material/Clear';
import { indigo } from "@mui/material/colors";
import { useUser } from "@/reducers/user";


function Team({org}) {
  const [currentUser] = useUser()
  const [btnStatus, setBtnStatus] = useState(false);
  const { showAlert } = useContext(AlertContext);
  const [groupId, setGroupId] = useState()
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openDelete, setOpenDelete] = React.useState(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);

  const StyledImg = styled('img')({
    width: '100%',
    height: '100%',
    display: 'block',
    objectFit: 'cover',
  });

  const [userToDelete, setUserToDelete] = useState(null);

  const handleDelete = (selectedUser) => {
    setUserToDelete(selectedUser);
    handleOpenDelete();
  }

  const [positions, setPositions] = useState([]);

  useEffect(() => {
    if (org && org.group && org.group.grouplist_set) {
      setGroupId(org.group.id)
      setPositions(org.group.grouplist_set);
    }
  }, [org]);

  const handleChange = async (event, index) => {
    const newPosition = event.target.value;
    const updatedPositions = positions.map((position, i) => {
      if (i === index) {
        return { ...position, position: newPosition };
      } else {
        return position;
      }
    });
    setPositions(updatedPositions);
    setBtnStatus(true);
  };

  const onSubmit = async () => {
    try {
      await api.patch(`/api/groupsUpdate/${groupId}`, {
        grouplist_set: positions
      });

      setBtnStatus(false);
      showAlert("Dados atualizados com sucesso!", "success");

    } catch (error) {
      console.error("Erro ao atualizar os dados: ", error);
      showAlert("Ocorreu um erro ao tentar atualizar os dados. Por favor, tente novamente.", "error");
    }
  }

  return(
    <Box sx={{ display: 'contents', flexDirection: 'column' }}>
      <Typography variant="h4" sx={{ pb: 2, color: indigo[700] }}>Equipe</Typography>
      <Box sx={{display: 'contents', flexDirection: 'column'}}>
        <Grid container sx={{display: 'flex', flexDirection: {xs:'column', sm: 'row', gap: 2}, justifyContent: 'center'}}> 
        {org && org.group.grouplist_set.map((user, index) => (  
            <Grid key={`temCard_${index}`} item sx={{borderRadius: 2, border: `solid 1px ${indigo[200]}`, backgroundColor: '#fff'}}> 
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right', mb: 0.2 }}>
              {user.user.email !== currentUser.email ?
                <IconButton aria-label="delete" sx={{
                  p: 0,
                  m: 0.6,
                  width: 20,
                  height: 20,
                  backgroundColor: indigo[300],
                  color: "#fff",
                  '&:hover': {
                    backgroundColor: indigo[700],
                  }                
                }}
                  onClick={() => handleDelete(user)}
                >
                  <ClearIcon sx={{ width: 24, height: 24}} />
                </IconButton>
                :
                <Box sx={{p: 0, m: 0.6, width: 20, height: 20}} ></Box>
              }           
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 1 }}>
                {user?.user.image && user.user.image !== "" ?
                  <Box                     
                    sx={{
                      width: "48px",
                      height: "48px",
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: "100%",
                      border: `2px solid ${indigo[300]}`,
                      overflow: "hidden",
                      mx:1
                    }}
                  >
                    <StyledImg
                      src={user.user.image}
                      alt=""
                    />
                  </Box>
                  :
                  <Avatar sx={{ width: 48, height: 48, fontSize: 30, fontWeight: 500 }}>{user.user.name}</Avatar>
                }
                
              </Box>
              <Box sx={{ alignItems: 'center', textAlign: 'center', mb: 2, px:1 }}>
                <Typography variant="h6" sx={{ pb: 0, textAlign: 'center' }}><b>Nome:</b> {user.user.name}</Typography>
                <Typography variant="h6" sx={{ pb: 0, textAlign: 'center' }}><b>Posição:</b> </Typography>
                <FormControl variant="standard" sx={{ m: 0, minWidth: 120}}>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={positions[index]?.position || ''}
                    onChange={(event) => handleChange(event, index)}
                    label="Posição"
                  >
                    <MenuItem value={"expert"}>Expert</MenuItem>
                    <MenuItem value={"launcher"}>Lançador(a)</MenuItem>
                    <MenuItem value={"copywriter"}>Copywriter</MenuItem>
                    <MenuItem value={"socialMedia"}>Social Media</MenuItem>
                    <MenuItem value={"trafficManager"}>Gestor(a) de Tráfego</MenuItem>
                    <MenuItem value={"webdesigner"}>Webdesigner</MenuItem>
                    <MenuItem value={"designer"}>Designer</MenuItem>
                    <MenuItem value={"videomaker"}>Videomaker</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
        ))}
          <Grid item sx={{ display: 'flex', justifyContent: 'center' }}>
            <IconButton color="success" aria-label="add" sx={{
              '&:hover': {
                backgroundColor: 'transparent',
              }
            }}
              onClick={handleOpen}
            >
              <AddCircleIcon sx={{ width: 32, height: 32, borderRadius: 'inherit', backgroundColor: '#fff' }} />
            </IconButton>
            <ModalTeam handleOpen={handleOpen} handleClose={handleClose} open={open} groupId={groupId} />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignSelf: 'end' }}>
        {org &&        
          btnStatus?
          <Button variant="contained"
            onClick={onSubmit}
          >
            Salvar
          </Button>
          :
          <Button disabled>Salvar</Button>            
            
        }
      </Box>
      <ModalDeleteTeam
        handleOpenDelete={handleOpenDelete}
        handleCloseDelete={handleCloseDelete}
        openDelete={openDelete}
        groupId={groupId}
        userId={userToDelete}
      />
    </Box>
  )
}

export default Team