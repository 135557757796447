import React from "react";
import Box from '@mui/material/Box'; 
import Typography from '@mui/material/Typography'; 
import Paper from '@mui/material/Paper'; 
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { green, indigo, orange, red, yellow } from "@mui/material/colors";
import { useStep } from "@/reducers/step";

function FormulaTimeline() {
  const [, setStep] = useStep();

  const handleClick = (e) => {
    setStep({
      type: "STEP_CHANGE_VALUE",
      payload: {
        step: e.target.value,
        title: e.currentTarget.innerText
      },
    });
  };

  return(
    <Paper sx={{ borderRadius: 3, flexGrow: 1 }}>
      <Box sx={{display: 'flex', margin: 0, padding:1}}>
        <Grid container spacing={0} sx={{ display: 'flex', flexDirection: { xs: 'row', sm: 'column', md: 'row'}}}>
          <Grid item xs={12} sm={2} md={2} sx={{ border: '1px solid #fff', maxWidth: { sm: 'none' }, backgroundColor: indigo[50] }}>
            <Box sx={{ height: '10px', background: `linear-gradient(to right, ${indigo[100]}, ${indigo[200]})` }} ></Box>
            <Button variant='contained' value="planejamento" onClick={handleClick} sx={{p:0, px:1, m:1}}>Planejamento</Button>
          </Grid>
          <Grid item xs={12} sm={2} md={2} sx={{ border: '1px solid #fff', maxWidth: { sm: 'none' }, backgroundColor: indigo[50] }}>
            <Box sx={{ height: '10px', background: `linear-gradient(to right, ${indigo[200]}, ${indigo[300]})` }} ></Box>
            <Button variant='contained' value="preparacao" onClick={handleClick} sx={{p:0, px:1, m:1}}>Preparação</Button>
          </Grid>
          <Grid item xs={12} sm={8} md={8} sx={{ border: '1px solid #fff', maxWidth: { sm: 'none' }, backgroundColor: indigo[50] }}>
            <Box sx={{ height: '10px', background: `linear-gradient(to right, ${indigo[300]}, ${indigo[400]})` }} ></Box>
            <Button variant='contained' value="execucao" onClick={handleClick} sx={{ p: 0, px: 1, m: 1 }}>Execução</Button>
            {/* <Typography sx={{ p: 0, px: 1, m: 1, color: indigo[500] }} variant="h5">Execução</Typography> */}
          </Grid>
          <Grid item xs={12} sm={4} md={4} sx={{ }}>
          </Grid>
          <Grid item xs={12} sm={4} md={4} sx={{ border: '1px solid #fff', maxWidth: 'none', backgroundColor: yellow[50] }}>          
            <Box sx={{ height: '10px', background: `linear-gradient(to right, ${yellow[300]}, ${yellow[500]})` }} ></Box>
            <Button variant='contained' 
              value="cli"
              sx={{p:0, px:1, m:1}} 
              onClick={handleClick}>CLI</Button>
          </Grid>
          <Grid item xs={12} sm={4} md={4} sx={{ }}>
          </Grid>
          <Grid item xs={12} sm={6} md={6} sx={{ }}>
          </Grid>
          <Grid item xs={12} sm={2} md={2} sx={{ border: '1px solid #fff', maxWidth: { sm: 'none' }, backgroundColor: orange[50] }}>
            <Box sx={{ height: '10px', background: `linear-gradient(to right, ${orange[300]}, ${orange[500]})` }} ></Box>
            {/* <Button variant='contained' sx={{p:0, px:1, m:1}}>Aquecimento</Button> */}
            <Typography sx={{ p: 0, px: 1, m: 1, color: orange[500] }} variant="h5">Aquecimento</Typography>

          </Grid>
          <Grid item xs={12} sm={2} md={2} sx={{ border: '1px solid #fff', maxWidth: { sm: 'none' }, backgroundColor: red[50] }}>
            <Box sx={{ height: '10px', background: `linear-gradient(to right, ${red[300]}, ${red[500]})` }} ></Box>
            {/* <Button variant='contained' sx={{p:0, px:1, m:1}}>Webnário</Button> */}
            <Typography sx={{ p: 0, px: 1, m: 1, color: red[500] }} variant="h5">Webnário</Typography>

          </Grid>
          <Grid item xs={12} sm={2} md={2} sx={{ border: '1px solid #fff', maxWidth: { sm: 'none' }, backgroundColor: green[50] }}>
            <Box sx={{ height: '10px', background: `linear-gradient(to right, ${green[300]}, ${green[500]})` }} ></Box>
            {/* <Button variant='contained' sx={{p:0, px:1, m:1}}>Carrinho</Button> */}
            <Typography sx={{ p: 0, px: 1, m: 1, color: green[500] }} variant="h5">Carrinho</Typography>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}

export default FormulaTimeline