import React from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { blue, pink } from "@mui/material/colors";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { indigo, green, red } from '@mui/material/colors';

function Positioning({handleChange, expanded, panel, }) {

  const allFieldsFilled = true

  return(
    <Accordion expanded={expanded === `panel${panel}`} onChange={handleChange(`panel${panel}`)}
      sx={{ borderRadius: 2, mb: 0.2, p: 0.5, position: 'initial', border: `solid 1px ${indigo[100]}` }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${panel}bh-content`}
        id={`panel${panel}bh-header`}
        sx={{
          borderRadius: 2,
          minHeight: 0,
          ml: 1,
          '&.Mui-expanded': {
            minHeight: 0,
          },
          '&::before': { content: '""', position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)', width: 10, height: 10, borderRadius: '50%', backgroundColor: allFieldsFilled ? green[500] : red[400] },
        }}
      >
        <Typography sx={{ width: '33%', flexShrink: 0 }}>
          Posicionamento
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 1, pt: 2 }}>
        <Box sx={{ backgroundColor: indigo[50], p: 1, borderRadius: 2 }}>
          <Typography variant="h5" sx={{ borderRadius: 2, backgroundColor: '#fff', border: `2px solid #CF2CF6`, p: 0.6, px: 2, m: 1, textAlign: 'center' }} >DEFINIR ATUAÇÃO - AMBOS</Typography>
          <Grid container>
            <Grid item xs={12} sm={6} sx={{ textAlign: 'center'}}>
              <Typography variant="h5" sx={{ borderRadius: 2, color: "#CF2CF6", p: 0.4, px: 1, m: 1, textTransform: 'uppercase' }} >Especialista</Typography>
              <Typography variant="h5" sx={{ borderRadius: 2, px: 1, m: 1 }} >Username massa</Typography>
              <Typography variant="h5" sx={{ borderRadius: 2, px: 1, m: 1 }} >Criar conteúdo</Typography>
              <Typography variant="h5" sx={{ borderRadius: 2, px: 1, m: 1 }} >Estar em movimento</Typography>
              <Typography variant="h5" sx={{ borderRadius: 2, px: 1, m: 1 }} >Jogar o jogo da sedução</Typography>
              <Typography variant="h5" sx={{ borderRadius: 2, px: 1, m: 1 }} >Não espere um lançador - <b>Rode um MVP</b></Typography>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ textAlign: 'center'}}>
              <Typography variant="h5" sx={{ borderRadius: 2, color: "#CF2CF6", p: 0.4, px: 1, m: 1, textTransform: 'uppercase' }} >Lançador</Typography>
              <Typography variant="h5" sx={{ borderRadius: 2, px: 1, m: 1 }} >Passar imagem profissional: fotos adequadas, bio explicativa</Typography>
              <Typography variant="h5" sx={{ borderRadius: 2, px: 1, m: 1 }} >Fotos trabalhando, em reuniões de negócio</Typography>
              <Typography variant="h5" sx={{ borderRadius: 2, px: 1, m: 1 }} >5 posts de conteúdo</Typography>
              <Typography variant="h5" sx={{ borderRadius: 2, px: 1, m: 1 }} >2 Posts em evento</Typography>
              <Typography variant="h5" sx={{ borderRadius: 2, px: 1, m: 1 }} >Se tiver, mostrar os cases de sucesso</Typography>
              <Typography variant="h5" sx={{ borderRadius: 2, px: 1, m: 1 }} >Domínio e e-mail profissional</Typography>
            </Grid>
          </Grid>
          
        </Box>
      </AccordionDetails>
    </Accordion>
    
  )
}

export default Positioning