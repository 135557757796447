import { useEffect, useState } from "react";
import { auth } from "@/services/firebase";
import { onAuthStateChanged } from "firebase/auth";
import { useUser } from "@/reducers/user";
import api from "@/services/api";

function useAuth() {
  const [, setUser] = useUser();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setLoading(true);
      if (user) {
        try {
          const token = await user.getIdToken();
          // Armazenar token de forma segura (considere outras abordagens ao invés do localStorage)
          localStorage.setItem("token", token);

          const response = await api.get("/users");
          const profile = response.data;
          setUser({
            type: "USER_CHANGE_VALUE",
            payload: {
              logged: true,
              uid: user.uid,
              name: profile.name,
              id: profile.id,
              email: profile.email,
              image: profile.image,
              subscriptionStatus: profile.subscription_status,
              nextInvoice: profile.next_invoice_date,
              lastPayment: profile.last_payment_date,
              trialEndDate: profile.trial_end_date,
              hasAccess: profile.has_access,
              cancelAtPeriodEnd: profile.cancel_at_period_end,
              emailVerified: user.emailVerified,
              support: profile.support
            },
          });
        } catch (error) {
          console.error("Error fetching user data:", error);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
        setUser({ type: "USER_RESET" });
      }
    });

    return unsubscribe;
  }, [setUser]);

  return { loading };
}

export default useAuth;
