import { useQuery } from "@tanstack/react-query";
import api from "@/services/api";

function useSharedLListId(list_id) {
  return useQuery({
    queryKey: ["sharedLListsId", list_id],
    queryFn: () => api.get(`/sharedllists/${list_id}`).then((response) => response.data),
    enabled: !!list_id
  });
}

export default useSharedLListId;
