function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function replaceWord(string) {
  return string.replace(/fundacao/gi, "Fundação");
}

function formatString(string) {
  let formattedString = capitalizeFirstLetter(string);
  formattedString = replaceWord(formattedString);
  return formattedString;
}

export default formatString