
export function useCustomQueryClient(queryClient) {
  function invalidateQueries() {
    queryClient.invalidateQueries({ queryKey: ["launchingById"] });
    queryClient.invalidateQueries({ queryKey: ["formulaById"] });
    queryClient.invalidateQueries({ queryKey: ["sharedLListsId"] });
    queryClient.invalidateQueries({ queryKey: ["sharedFListsId"] });
  }

  return { invalidateQueries };
}