import React, {useMemo} from "react";
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import api from '@/services/api';
import { useParams } from 'react-router-dom';

function ProgressBar({launching}) {
  const { id, option } = useParams();
  let path

  if (option === "launchings") {
    path = "mapalxlaunchings"
  } else {
    path = "mapalxshared"
  }

  function calculatePercentage(obj) {
    console.log(obj)
    let totalFields = 0;
    let filledFields = 0; 
    const ignoredKeys = ["id", "name", "launchType", "launchDate", "creationDate", "enabled", "launchingList", "avatar", "mtck", "ggck", "method", "schedule", "group_lists", "attributions", "blogLink", "blogPage", "bonus", "checkoutLink", "cplsLink", "cpcrck", "deliverable", "fanpageLink", "fbadck", "fbccampaignck", "fbccck", "fbcpck", "fbsack", "fbspck", "ggck", "mmcfck", "mmcgck", "mmcock", "mmcsck", "notificationsPos", "optBudget", "optBudget2", "optCreatives", "optCreatives2", "pagesLink", "plcack", "plcpck", "plewck", "pltpck", "price", "recordTraining", "spcrck", "testEmail", "testForm", "tpcrck", "verifySend", "verifySend2", "warranty", "youtubeLink", "ytchck", "ytltck", "ytttck", "TestCampaign", "bpcrck"];
    
    const keys = Object.keys(obj).filter(key => !ignoredKeys.includes(key));
    const values = Object.values(obj).filter(key => !ignoredKeys.includes(key));
    console.log(keys)
    console.log(values)

    keys.forEach(key => {
      totalFields++; 
      if (obj[key] !== "" && obj[key] !== null && obj[key] !== false ) {
        filledFields++;
      }
    });

    const percentage = (filledFields / totalFields) * 100;
    
    api.patch(`/api/${path}/${id}/`, {
      progress: percentage,
    });

    return percentage;
  }
  const progressValue = calculatePercentage(launching)
  const progressColor = useMemo(() => {
    if (progressValue <= 25) {
      return 'red'
    }

    if (progressValue <= 50) {
      return '#FF8C00'
    }

    if (progressValue <= 75) {
      return '#ffdf00'
    }

    return '#00a400'
  }, [progressValue]);

  const progressColorDarker = useMemo(() => {
    if (progressValue <= 25) {
      return 'darkred'
    }

    if (progressValue <= 50) {
      return '#8B4513'
    }

    if (progressValue <= 75) {
      return 'goldenrod'
    }

    return '#005F00'
  }, [progressValue]);

  return(
    <Paper sx={{ borderRadius: 3, display: 'flex', flexDirection: 'column', p: 2}}>
      <LinearProgress variant="determinate" value={progressValue}
        sx={{  
          p:0.3,        
          bgcolor: progressColorDarker,
          '& .MuiLinearProgress-bar': {
            bgcolor: progressColor,
          }
        }}
      />   
    </Paper>        
  )
}

export default ProgressBar