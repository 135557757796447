import { useQuery } from "@tanstack/react-query";
import api from "@/services/api";
import { refreshToken } from "@/hooks/refreshToken"; // Importe a função que você criou acima

function useOrganization() {
  return useQuery({
    queryKey: ["organization"],
    queryFn: () => api.get("/api/organization").then((response) => response.data),
    onError: async (error, variables, context) => {
      if (error.response?.data?.detail === "Token expired") {
        await refreshToken();
        // Se for necessário, você pode re-executar a query aqui, mas react-query geralmente tentará novamente por padrão
      }
    },
    retry: (failureCount, error) => {
      if (error.response?.data?.detail === "Token expired" && failureCount < 2) {
        return true; // Tenta novamente se o token estiver expirado e a tentativa de falha for menor que 2
      }
      return false; // Não tenta novamente para outros erros
    },
  });
}

export default useOrganization;
