import React, { useState, useContext, useEffect } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import Button from '@mui/material/Button';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import { indigo, green, red, grey } from '@mui/material/colors';
import { useFieldChangeEsp } from './useFieldChangeEsp';
import api from '@/services/api';
import { useParams } from 'react-router-dom';
import AlertContext from '../Alert/AlertContext';
import { useCustomQueryClient } from './hooks/useCustomQueryClient';
import { useQueryClient } from '@tanstack/react-query';

function CALsForm({ initialCreatives = [], handleChange, expanded, panel, path, useLauncherfyById }) {
  const [btnStatus, setBtnStatus] = useState(false);
  const { showAlert } = useContext(AlertContext);
  const { id } = useParams();
  const { data: launching, isSuccess } = useLauncherfyById(id);
  const defaultTextLabels = ["Nível", "Semana"];
  const levelOptions = ['Iniciante', 'Intermediário', 'Avançado', 'Pro'];
  const weekOptions = ['Semana 1', 'Semana 2', 'Semana 3'];
  const defaultValues = {
    calScripts: initialCreatives.length > 0
      ? initialCreatives.map(calScript => ({ calScript }))
      : [{ calScript: '' }],
    textFieldList: defaultTextLabels.map((item, index) => ({ item: 0 })),
  };
  const queryClient = useQueryClient();
  const invalidate = useCustomQueryClient(queryClient)

  const { register, handleSubmit, control, setValue, getValues, reset, watch } = useForm({
    defaultValues,
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'calScripts',
  });

  const { fields: textFieldFields } = useFieldArray({
    control,
    name: 'textFieldList',
  });

  const { handleFieldChange, totalCharacterCount, allFieldsFilled } = useFieldChangeEsp(getValues, setValue, setBtnStatus, 'calScript');

  const handleAddCreative = () => {
    if (fields.length < 15) {
      append({ calScript: '' });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isSuccess && launching) {
          if (launching.calScripts) {
            const calScriptArray = JSON.parse(launching.calScripts);
            const calScripts = calScriptArray.slice(0, 6).map(calScript => ({ calScript }));
            if (launching.calSelects) {
              const calSelectArray = JSON.parse(launching.calSelects);
              const textFieldList = calSelectArray.map(item => ({ item }));
              reset({ calScripts, textFieldList });
            } else {
              reset({ calScripts });
            }
            handleFieldChange();
            setBtnStatus(false);
          }
        }
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };
    fetchData();
  }, [id, reset, handleFieldChange, launching, isSuccess]);

  // const watchFields = watch(['textFieldList']);

  const onSubmit = async (data) => {
    try {
      const calScripts = data.calScripts.map(calScript => calScript.calScript)
      const combinedString = JSON.stringify(calScripts);
      const textFieldList = data.textFieldList.map(item => item.item)
      const combinedTextFieldList = JSON.stringify(textFieldList);
      await api.patch(`/api/${path}/${id}/`, {
        calScripts: combinedString,
        calSelects: combinedTextFieldList
      });

      invalidate.invalidateQueries()
      setBtnStatus(false);
      showAlert("Dados atualizados com sucesso!", "success");

    } catch (error) {
      console.error("Erro ao atualizar os dados: ", error);
      showAlert("Ocorreu um erro ao tentar atualizar os dados. Por favor, tente novamente.", "error");
    }
  };

  const levelOptionsValues = [3, 5, 10, 15]
  const levelIndex = watch('textFieldList.0.item', 0)
  const level = levelOptionsValues[levelIndex]
  const week = (watch('textFieldList.1.item', 0)+1)

  return (
    <Accordion expanded={expanded === `panel${panel}`} onChange={handleChange(`panel${panel}`)}
      sx={{ borderRadius: 2, mb: 0.2, p: 0.5, position: 'initial', border: `solid 1px ${indigo[100]}` }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${panel}bh-content`}
        id={`panel${panel}bh-header`}
        sx={{
          borderRadius: 2,
          minHeight: 0,
          ml: 1,
          '&.Mui-expanded': {
            minHeight: 0,
          },
          '&::before': { content: '""', position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)', width: 10, height: 10, borderRadius: '50%', backgroundColor: allFieldsFilled ? green[500] : red[400] },
          overflow: 'hidden',
        }}
      >
        <Typography sx={{ width: '100%', flexShrink: 0 }}>
          CALs - Conteúdo de Aquecimento de lançamento
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 1, pt: 2 }}>
        <Box
          component="form" onSubmit={handleSubmit(onSubmit)}
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: 1,
          }}>
          <Grid container gap={1} sx={{ display: "flex", flexDirection: "row", backgroundColor: indigo[50], p: 1, borderRadius: 2, gap:0}}>
            <Grid item xs={12} md={4} sx={{ p: 1 }}>
              {textFieldFields.map((field, index) => (
                <Box sx={{ gap: 1, display: 'flex', alignItems: 'center', flexDirection: 'column' }} key={field.id}>
                  <Typography variant="h6" sx={{ alignSelf: 'flex-start' }}>{defaultTextLabels[index]}</Typography>
                  <Select
                    key={`persona_${index}`}
                    {...register(`textFieldList.${index}.item`)}
                    value={watch(`textFieldList.${index}.item`, '')}
                    onChange={event => {
                      setValue(`textFieldList.${index}.item`, event.target.value)
                      setBtnStatus(true);
                    }}
                    variant="outlined"
                    size="small"
                    sx={{
                      width: '100%', backgroundColor: '#fff', borderRadius: 2,
                      '& label.MuiInputLabel-shrink': {
                        backgroundColor: indigo[50],
                        borderRadius: 3, px: 0.8,
                      }
                    }}
                  >
                    {(index === 0 ? levelOptions : weekOptions).map((option, optionIndex) => (
                      <MenuItem key={optionIndex} value={optionIndex}>{option}</MenuItem>
                    ))}
                  </Select>
                </Box>
              ))}
              <Box display="flex" alignItems="center" sx={{ gap: 1, mt:1 }}>
                <TextField
                  value={`${level} por semana | total: ${level*week}`}
                  label="Conteúdo"
                  disabled
                  variant="outlined"
                  size="small"
                  sx={{
                    mt: 1, width: '100%', borderRadius: 2, backgroundColor: grey[400], color: '#fff', '& .MuiFilledInput-root': { bgcolor: 'grey', }, '& .MuiInputLabel-filled': { color: 'white', }, '& label.MuiInputLabel-shrink': {
                      backgroundColor: indigo[50],
                      borderRadius: 3, px: 0.8,
                    }
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={8}  sx={{p:1}}>
              {fields.map((item, index) => (
                <Box key={item.id} display="flex" alignItems="center" flexDirection="column" sx={{ gap: 1 }}>
                  <Typography variant="h6" sx={{ alignSelf: 'flex-start' }}>{index === 0 ? "Roteiro da CAL" : `Roteiro da CAL ${index + 1}`}</Typography>
                  <TextareaAutosize
                    {...register(`calScripts.${index}.calScript`)}
                    placeholder={index === 0 ? "Roteiro da CAL" : `Roteiro da CAL ${index + 1}`}
                    minRows={3}
                    style={{
                      width: '100%',
                      height: '100px',
                      borderRadius: '4px',
                      borderColor: grey[400],
                      padding: '8px',
                      fontFamily: "Roboto",
                      font: 'inherit'
                    }}
                    onChange={(event) => {
                      handleFieldChange(`calScripts.${index}.calScript`, event.target.value);
                    }}
                  />
                  {index !== 0 && (
                    <Button variant="outlined" color="error" size="medium" onClick={() => {
                      remove(index)
                      setBtnStatus(true)
                    }}>
                      <Typography variant="h1">-</Typography>
                    </Button>
                  )}
                </Box>
              ))}
              </Grid>
            </Grid>          
          <Typography>
            Caracteres: {10000 - totalCharacterCount}
          </Typography>
          <Divider />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",

              gap: 1,
            }}>
            <Button variant="contained" onClick={handleAddCreative} disabled={fields.length === 15}>
              + Adicionar Roteiro
            </Button>
            {btnStatus ?
              <Button type="submit">Salvar</Button>
              :
              <Button disabled>Salvar</Button>
            }
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default CALsForm;
