import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CardMedia from '@mui/material/CardMedia';
import Divider from '@mui/material/Divider';
import Title from '@/components/Title';
import { indigo } from '@mui/material/colors';

export default function ModalLauncherfy() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen}>Detalhes</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            maxWidth: 900,
            bgcolor: "background.paper",
            margin: "auto",
            marginTop: "10%",
            outline: "none",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            borderRadius: 3,
            boxShadow:
              "rgb(255 255 255 / 10 %) 0px 1px 24px, rgb(255 255 255 / 9 %) 0px 15px 12px",
            heigh: '70vh'
          }}
        >
          <Title name="Plano Fundação"/>
          <Box sx={{ padding: 2, gap: 1, display: "flex", flexDirection: "column", height: '70vh', overflow: 'auto' }}>
            <Typography variant="h4" textAlign="center">
              Plano Fundação - Método de lançamento base 🚀
            </Typography>
            <Typography variant="h5" textAlign="center" color="text.secondary" sx={{py:2}}>
              Apresentamos o nosso inovador método de lançamento, fruto de extensas pesquisas e experiências práticas no universo do marketing digital. Projetado para ser executado ao longo de 34 dias, ele é estruturado de maneira inteligente e intuitiva para garantir que cada etapa de um lançamento seja conduzida com precisão.            </Typography>
            <Typography variant="h5" textAlign="center">
              <b>O que você pode esperar do nosso método?</b>
            </Typography>
            <Typography variant="h5" color="text.secondary" textAlign="center">
              <b>Planejamento Diário:</b> Em nosso plano base, cada dia do lançamento é meticulosamente planejado. Isso elimina adivinhações e proporciona um roteiro claro a seguir. A cada amanhecer, você terá uma nova tarefa para focar, levando-o de maneira estratégica até o dia do lançamento.
            </Typography>
            <Typography variant="h5" color="text.secondary" textAlign="center">
              <b>34 Dias de Ação:</b> Distribuídos ao longo de pouco mais de um mês, cada um dos 34 dias foi pensado para maximizar a eficiência de seu lançamento. Desde a pré-produção até o pós-lançamento, cada tarefa é crucial e desempenha um papel fundamental no sucesso do seu projeto.
            </Typography>    
            <Typography variant="h5" color="text.secondary" textAlign="center">
              <b>Maior Probabilidade de Sucesso:</b> Com tarefas bem definidas e um roteiro a ser seguido, o risco de se perder ou esquecer detalhes vitais é minimizado. Isso significa que, ao seguir nosso método, suas chances de realizar um lançamento de sucesso são significativamente ampliadas.
            </Typography>
            <Typography variant="h5" textAlign="center">
              <b>+</b>
            </Typography>
            <Typography variant="h5" textAlign="center">
              <b>Acesso ao Plano Semente</b>
            </Typography>
            <Typography variant="h5" color="text.secondary" textAlign="center">
              Além do roteiro estratégico de lançamento, ao adquirir o plano base, você também ganha acesso ao nosso Plano Semente. Uma ferramenta excepcional que potencializa as suas chances de sucesso, oferecendo recursos e estratégia alternativa ao laçamento base.
            </Typography>
            <Divider />
            <CardMedia sx={{ borderRadius: 2, border: `solid 1px ${indigo[300]}` }}
              component="img"
              image="/launcherfy.png"
              alt="plataforma"
            />
            <Divider/>
            <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
              <Button variant="outlined" onClick={handleClose}>
                Fechar
              </Button>
            </Box>
          </Box>  
        </Box>
      </Modal>
    </div>
  );
}