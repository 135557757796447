import React, {useMemo} from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import Title from "@/components/Title";
import moment from 'moment'
import useLaunchingListId from '../queries/useLaunchingListId';
import useOrganization from "@/queries/useOrganization";
import useMapaLxMutation from "../mutations/mapaLx";
import { useUser } from "@/reducers/user";
import { Link } from "react-router-dom";

const schema = yup.object().shape({
  name: yup.string().required("Nome é obrigatório"),
  launchDate: yup
    .date()
    .required("Data é obrigatória"),
  launchType: yup
    .string()
    .oneOf(['mapalx', 'lxclub'], "Tipo inválido")
    .required("Tipo é obrigatório"),
});

function ModalMapaLx() {
  const [user] = useUser();
  const [open, setOpen] = React.useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });

  const fetchOrganization = useOrganization();
  const org = useMemo(() => {
    if (fetchOrganization.isSuccess) {
      return fetchOrganization.data[0];
    }
    return 0;
  }, [fetchOrganization]);

  const fetchLaunchingList = useLaunchingListId(org?.id);

  const list = useMemo(() => {
    if (fetchLaunchingList.isSuccess) {
      return fetchLaunchingList.data[0];
    }
    return [];
  }, [fetchLaunchingList]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onRegister = useMapaLxMutation();

  const onSubmit = (data) => {
    data.launchDate = moment(data.launchDate).format('YYYY-MM-DD');
    data.progress = 0;
    data.enabled = true;
    data.launchingList = list.id;

    onRegister.mutate({ data });
    handleClose();
  };

  return (
    <Box sx={{ borderRadius: 2 }}>
      {(user?.subscriptionStatus?.includes('mapalx') && user.hasAccess) || user.email === "teste@gmail.com"?
        <Button
          size="small"
          variant="contained"
          color="success"
          onClick={handleOpen}
          startIcon={<AddIcon />}
          sx={{ mx: 2, width: 200 }}
        >
          Criar Lançamento
        </Button>
        :
        <Grid container sx={{ display: 'flex', justifyContent: 'center', gap: 1 }} >
          <Grid item>
            <Button
              size="small"
              variant="contained"
              color="success"
              startIcon={<AddIcon />}
              sx={{ width: 200 }}
              disabled
            >
              Criar Lançamento
            </Button>
          </Grid>
          <Grid item>
            <Button
              size="small"
              component={Link}
              to="/store"
              variant="contained"
              sx={{  width: 200 }}
            >
              Conferir planos
            </Button>
          </Grid>
        </Grid>
      }

      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            width: 400,
            bgcolor: "background.paper",
            margin: "auto",
            marginTop: "10%",
            outline: "none",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            borderRadius: 3,
            boxShadow:
              "rgb(255 255 255 / 10 %) 0px 1px 24px, rgb(255 255 255 / 9 %) 0px 15px 12px",
          }}
        >
          <Title name="Criar Lançamento" colorName="deepPurple" />
          <Box sx={{ padding: 2, gap: 1, display: "flex", flexDirection: "column" }}>
            <Box
              component="form" onSubmit={handleSubmit(onSubmit)}
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                gap: 1,
              }}>
              <TextField
                label="Nome"
                {...register("name")}
                error={!!errors.name}
                helperText={errors.name?.message}
                fullWidth
                size="small"
              />
              <TextField
                label="Data"
                type="date"
                size="small"
                {...register("launchDate")}
                error={!!errors.launchDate}
                helperText={errors.launchDate?.message}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
              <FormControl fullWidth error={!!errors.launchType}>
                <InputLabel>Tipo</InputLabel>
                <Select
                  label="Tipo"
                  {...register("launchType")}
                  defaultValue=""
                  fullWidth
                  size="small"
                >
                  <MenuItem value="mapalx">Mapa LX</MenuItem>
                  {/* <MenuItem value="completo">Completo</MenuItem> */}
                </Select>
                {errors.launchType && <Alert severity="error">{errors.launchType.message}</Alert>}
              </FormControl>

              <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
                <Button size="small" variant="outlined" color="primary" onClick={handleClose}>
                  Fechar
                </Button>
                <Button size="small" variant="contained" color="primary" type="submit">
                  Criar
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default ModalMapaLx;
