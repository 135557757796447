import React, {useMemo} from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Title from "@/components/Title";
import Typography from "@mui/material/Typography";
import ProductCard from './ProductCard';
import FoundationIcon from '@mui/icons-material/Foundation';
import SpaIcon from '@mui/icons-material/Spa';
import MpIcon from '@mui/icons-material/Mp';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import FortIcon from '@mui/icons-material/Fort';
import { blue, green, indigo, orange, red, yellow } from "@mui/material/colors";
import { useUser } from "@/reducers/user";
import SplashScreen from "@/components/SplashScreen";

function Component() {
  const fetchUser = useUser();

  const user = useMemo(() => {
    if (fetchUser[0].logged) {
      return fetchUser[0];
    }
    return 0;
  }, [fetchUser]);


  if (!user.logged) {
    return <SplashScreen />
  }

  return(
    <Box sx={{ display: "flex", gap: 1, flexDirection: "column" }}>
      <Paper sx={{ borderRadius: 3 }}>
        <Title name="Planos" />
        <Box
          sx={{
            padding: 1,
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: 'center',
            justifyContent: 'center',
            gap: 1,
          }}
        >
          {user?.emailVerified || user?.support ? 
          <Grid container sx={{display: 'flex', justifyContent: 'center'}}>
            <Grid item sx={{textAlign: 'center', p:2 }}>
              <Typography variant="h6">
                  <b>Nota Importante:</b> A aquisição refere-se ao acesso a uma plataforma especializada em roteirizar e automatizar processos.</Typography>
                {/* Nota Importante: A aquisição refere-se ao acesso a plataforma que roteiriza e facilita o compartilhamento das informações.</Typography> */}
              <Typography variant="h6" sx={{color: red[400]}}>Esta plataforma foi criada para estruturar e simplificar o compartilhamento de informações com sua equipe. Entretanto, ela não substitui ou elimina a necessidade de adquirir separadamente os cursos "Fórmula de Lançamento" ou "LXClub".</Typography>
            </Grid>
            {!user?.subscriptionStatus?.includes('fundacao') &&
            <Grid item> 
              <ProductCard
                icon={<SpaIcon sx={{ fontSize: 60, color: green[700], border: `solid 3px #b5a05d`, backgroundColor: '#000', borderRadius: 3 }} />}
                title="Plano semente"
                description="Acesso ao lançamento semente(Fórmula) roteirizado."
                // priceIdOne="price_1Ndvt7JxrhrOsLvXM6PInOgU"
                priceIdOne="price_1NftyLJxrhrOsLvXwyDoYru9"
                typeOne="semente"
                priceOne="59,90"
                // priceIdTwo="price_1NfNKcJxrhrOsLvXOoqOnAIk"
                priceIdTwo="price_1NftyLJxrhrOsLvXswRPjlpG"
                typeTwo="sementeTri"
                priceTwo="126,90"
                priceIdThree="price_1NajWwJxrhrOsLvX3LpJHx69"
                typeThree="sementeSem"
                priceThree="75,90"
                priceIdFour="price_1Nb53SJxrhrOsLvXQtsJiP7M"
                typeFour="sementeAno"
                priceFour="130,90"
                email={user?.email}
                subscription={user?.subscriptionStatus}
                cancelAtPeriodEnd={user?.cancelAtPeriodEnd}
                disabledAll={false}
              />
            </Grid>
            }
            <Grid item>
              <ProductCard
                  icon={<FoundationIcon sx={{ fontSize: 60, color: '#fff', border: `solid 3px #b5a05d`, backgroundColor: '#000', borderRadius: 3 }} />}
                title="Plano fundação"
                  description="Acesso ao lançamento base(Launcherfy) + plano semente(Fórmula)."
                // priceIdOne="price_1NfM2JJxrhrOsLvX73qoOWrL"
                priceIdOne="price_1NftyeJxrhrOsLvXuRX3GcdG"
                typeOne="fundacao"
                priceOne="79,90"
                // priceIdTwo="price_1NfM2JJxrhrOsLvXTEeUOOBO"
                priceIdTwo="price_1NftyeJxrhrOsLvXOgXUpqBw"
                typeTwo="fundacaoTri"
                priceTwo="146,90"
                priceIdThree=""
                typeThree="fundacaoSem"
                priceThree="94,90"
                priceIdFour=""
                typeFour="fundacaoAno"
                priceFour="163,90"
                email={user?.email}
                subscription={user?.subscriptionStatus}
                cancelAtPeriodEnd={user?.cancelAtPeriodEnd}
                disabledAll={false}
              />
            </Grid>
            <Grid item>
              <ProductCard
                  icon={<AccountBalanceIcon sx={{ fontSize: 60, color: blue[400], border: `solid 3px #b5a05d`, backgroundColor: '#000', borderRadius: 3, p: 0.5 }} />}
                title="Plano clássico"
                description="Acesso ao lançamento semente + lançamento Clássico(Fórmula)."
                priceIdOne=""
                typeOne="classico"
                priceOne="79,90"
                priceIdTwo=""
                typeTwo="classicoTri"
                priceTwo="146,90"
                priceIdThree=""
                typeThree="classicoSem"
                priceThree="106,90"
                priceIdFour=""
                typeFour="classicoAno"
                priceFour="183,90"
                email={user?.email}
                subscription={user?.subscriptionStatus}
                disabledAll={true}
              />
            </Grid>
            <Grid item>
              <ProductCard
                icon={<FortIcon sx={{ fontSize: 60, color: indigo[400], border: `solid 3px #b5a05d`, backgroundColor: '#000', borderRadius: 3, p:0.5 }} />}
                title="Plano completo"
                description="Acesso ao lançamento clássico(Fórmula) + lançamento completo(Launcherfy)."
                priceIdOne=""
                typeOne="classico"
                priceOne="-"
                priceIdTwo=""
                typeTwo="classicoTri"
                priceTwo="-"
                priceIdThree=""
                typeThree="classicoSem"
                priceThree="-"
                priceIdFour=""
                typeFour="classicoAno"
                priceFour="-"
                email={user?.email}
                subscription={user?.subscriptionStatus}
                disabledAll={true}
              />
            </Grid>
            <Grid item>
              <ProductCard
                  icon={<MpIcon sx={{ fontSize: 60, color: yellow[500], border: `solid 3px #b5a05d`, backgroundColor: '#000', borderRadius: 3, p:0.5 }} />}
                title="Plano LX30D"
                description="Acesso ao lançamento LX30D - Marcos Paulo"
                priceIdOne=""
                typeOne="classico"
                priceOne="-"
                priceIdTwo=""
                typeTwo="classicoTri"
                priceTwo="-"
                priceIdThree=""
                typeThree="classicoSem"
                priceThree="-"
                priceIdFour=""
                typeFour="classicoAno"
                priceFour="-"
                email={user?.email}
                subscription={user?.subscriptionStatus}
                disabledAll={true}
              />
            </Grid>
            
            {user?.support &&
              <Grid item>
                <ProductCard
                  icon={<FortIcon sx={{ fontSize: 60, color: yellow[500], border: `solid 3px #b5a05d`, backgroundColor: '#000', borderRadius: 3, p: 0.5 }} />}
                  title="Plano TESTE"
                  description="---------------------------------------------------"
                  priceIdOne="price_1NfuBCJxrhrOsLvXFToEzyLk"
                  typeOne="fundacaoFree"
                  priceOne="1,00"
                  priceIdTwo=""
                  typeTwo="classicoTri"
                  priceTwo="-"
                  priceIdThree=""
                  typeThree="classicoSem"
                  priceThree="-"
                  priceIdFour=""
                  typeFour="classicoAno"
                  priceFour="-"
                  email={user?.email}
                  subscription={user?.subscriptionStatus}
                  disabledAll={false}
                />
              </Grid>
              }
              
          </Grid>
          :
          <Grid sx={{ height: 'calc(100vh - 152px)', display: 'flex', alignItems: 'center' }}>
            <Typography variant="h3" sx={{ background: orange[100], p: 3, borderRadius: 2, border: `1px solid ${orange[400]}`, color: orange[900] }}>
              É necessário confirmar o e-mail
            </Typography>
          </Grid>
          }
        </Box>
      </Paper>
    </Box>
  )
}

export default Component