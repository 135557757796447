import React, { useMemo } from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Title from "@/components/Title";
import Grid from "@mui/material/Grid";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Team from "./Team";
import { deepPurple, indigo, pink } from "@mui/material/colors";
import FormulaTimeline from "./FormulaTimeline";
import ProgressBar from "./ProgressBar";
import Steps from "./Steps";
import useFormulaById from "@/queries/useFormulaById";
import useSharedFListId from "../../Shared/queries/useSharedFListId";
import { useParams } from "react-router-dom";
import moment from "moment";
import SplashScreen from "@/components/SplashScreen";
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ModalDelete from './ModalDelete';
import { useLink } from "@/reducers/link";
import { Link } from "react-router-dom";
import Drive from "@/components/Drive";
import EditLaunching from "@/components/EditLaunching";

function Container() {
  const { id, option } = useParams();
  const [, dispatch] = useLink();
  const apiHook = option === "launchings" ? useFormulaById : useSharedFListId;
  const { data: launching, refetch } = apiHook(id);

  let path = option;

  const fetchLaunchingList = launching;
  const memorizedLaunching = useMemo(() => {
    if (fetchLaunchingList && fetchLaunchingList.launchType === "seed") {
      const regex = /\[(.*?)\]: (https?:\/\/[^\s]+)/g;
      let match;
      let pagesLink = {};
      let whatsList = {};

      while ((match = regex.exec(fetchLaunchingList.pagesLink)) != null) {
        whatsList[match[1]] = match[2];
      }

      while ((match = regex.exec(fetchLaunchingList.whatsappLink)) != null) {
        pagesLink[match[1]] = match[2];
      }

      const linksList = [
        fetchLaunchingList.driveUrl,
        fetchLaunchingList.fanpageLink,
        // fetchLaunchingList.whatsappLink,
        whatsList,
        fetchLaunchingList.youtubeLink,
        fetchLaunchingList.checkoutLink,
        pagesLink
      ]
      dispatch({
        type: 'LINK_CHANGE_VALUE',
        payload: { links: linksList },
      });

      return fetchLaunchingList;
    }
    return null;
  }, [fetchLaunchingList, dispatch]);

  // console.log(memorizedLaunching)

  if (!memorizedLaunching) {
    return (
      <SplashScreen />
    )
  }

  return (
    <Box sx={{ display: "flex", gap: 1, flexDirection: "column" }}>
      <Paper
        sx={{ borderRadius: 3 }}
      >
        <Title name="Lançamentos > Fórmula de lançamento > Semente" />
        <Box sx={{ width: '100%' }}>
          <Grid container spacing={1} sx={{ p: 1 }}>
            <Grid item xs={6} sm={2} md={1.5} sx={{ order: { xs: 2, smm: 1 } }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: { sx: 'center', sm: 'start' }, justifyContent: 'center' }}>
                <Button
                  component={Link}
                  to={`/${path}/formula/${id}/method`}
                  variant="contained"
                  sx={{
                    background: `linear-gradient(to right, ${deepPurple[400]}, ${deepPurple[700]})`, py: 1.2, px: 1, borderRadius: 2, '&:hover': {
                      background: `linear-gradient(to right, ${deepPurple[600]}, ${deepPurple[800]})`,
                    },
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <AccountTreeIcon sx={{ fontSize: 15, mb: 0.5 }} launching={launching} path={path}/>
                  <Typography variant="h6">Método</Typography>
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} sm={8} md={9} sx={{ overflow: 'auto', order: { xs: 1, sm: 2 } }}>
              <Grid container spacing={0.5} display="flex" justifyContent="center">
                <Grid item sx={{ alignContent: "center" }}>
                  <Typography variant="h2" sx={{ textAlign: 'center', color: indigo[500], ml: '40px' }}>
                    {memorizedLaunching.name}
                    <EditLaunching launching={memorizedLaunching} />
                  </Typography>
                  <Typography variant="h5" sx={{ textAlign: 'center' }}>Data do Lançamento:</Typography>
                  <Typography variant="h6" sx={{ textAlign: 'center' }}>{moment(memorizedLaunching.launchDate).format('DD/MM/YYYY')}</Typography>
                </Grid>
              </Grid>
            </Grid>  
            <Grid item xs={6} sm={2} md={1.5} sx={{ order: 3 }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: {sx: 'center', sm: 'end'}, justifyContent: 'center' }}>
                <Button
                  component={Link}
                  to={`/${path}/formula/${id}/schedule`}
                  variant="contained"
                  sx={{
                    background: `linear-gradient(to right, ${pink[400]}, ${pink[700]})`, py: 1.2, px: 1, borderRadius: 2, '&:hover': {
                      background: `linear-gradient(to right, ${pink[600]}, ${pink[800]})`,
                    },
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <CalendarMonthIcon sx={{ fontSize: 15, mb: 0.5 }} />
                  <Typography variant="h6">Agenda</Typography>
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <Team launching={launching}/>
      <Drive launching={memorizedLaunching} />

      <ProgressBar launching={memorizedLaunching} refetchLaunching={refetch} />
      <FormulaTimeline />
      <Steps/>
      {option === "launchings" &&
        <ModalDelete launching={memorizedLaunching}/>    
      }
    </Box>
  )
}

export default Container