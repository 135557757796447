import React from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';
import { indigo } from '@mui/material/colors';
import CardMedia from '@mui/material/CardMedia';

function Layout({children}) {

  function Copyright(props) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {`© ${new Date().getFullYear()} `}
        <Link color="inherit" href="https://launcherfy.com/">
          Launcherfy
        </Link>{' '}
        {'todos os direitos reservados.'}
         - contato@launcherfy.com
      </Typography>
    );
  }

  const footers = [
    {
      // title: 'Legal',
      description: [
        {
          text: 'Termos de uso e política de privacidade.', link: '/privacy-policy' },
        // { text: 'Termos de Uso', link: '/terms-of-use' },
      ],
    },
  ];

  

  const defaultTheme = createTheme();

  return(
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
      >
        <Toolbar sx={{ flexWrap: 'wrap', backgroundColor: '#fff', borderBottom: `solid 1px ${indigo[200]}` }}>
          <Link
            variant="h5"
            href="/"
            sx={{
              my: 1, mx: 1.5, textDecoration: 'none', color: indigo[700], flexGrow: 1}}
          >
            <CardMedia sx={{ borderRadius: 2, width: '32px', float: 'left' }}
              component="img"
              image="/logo512.png"
              alt="method"
            />
          </Link>
          <Link
            variant="h5"
            href="/"
            sx={{ my: 1, mx: 1.5, textDecoration: 'none', color: indigo[700], flexGrow: 1,
          fontWeight: '700' }}
          >
            Launcherfy
          </Link>

          <nav>
            {/* <Link
              variant="button"
              color="text.primary"
              href="#"
              sx={{ my: 1, mx: 1.5 }}
            >
              Funcionalidades
            </Link> */}
            {/* <Link
              variant="button"
              color="text.primary"
              href="#"
              sx={{ my: 1, mx: 1.5 }}
            >
              Suporte
            </Link> */}
          </nav>
          <Button href="/profile" variant="outlined" sx={{
            my: 1, mx: 1.5, border: `solid 1px ${indigo[500]}`, color: indigo[500], '&:hover': {
              border: `solid 1px ${indigo[700]}`
            }
          }}>
            Entrar
          </Button>
        </Toolbar>
      </AppBar>
      <Grid sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', overflowY: 'auto', height: 'calc(100vh - 65px)', backgroundColor: '#fff'}} >
        {children}
        <Container
          maxWidth="md"
          component="footer"
          sx={{
            // borderTop: (theme) => `1px solid ${theme.palette.divider}`,
            mt: 2,
            py: [3, 6],
          }}
        >
          <Copyright sx={{ mt: 5 }} />
          <Grid container spacing={4} justifyContent="space-evenly">
            {footers.map((footer) => (
              <Grid item xs={6} sm={6} key={footer.title} sx={{textAlign: 'center'}}>
                <Typography  color="text.primary" gutterBottom>
                  {footer.title}
                </Typography>
                <ul>
                  {footer.description.map((item) => (
                    <li key={item.text}>
                      <Link
                        href={item.link}
                        variant="subtitle1"
                        color="text.secondary"
                      >
                        {item.text}
                      </Link>
                    </li>
                  ))}
                </ul>
              </Grid>
            ))}
          </Grid>
          
        </Container>
      </Grid>
    </ThemeProvider>
  )
}

export default Layout