import { UserProvider } from './user';
import { StepProvider } from './step';
import { OrganizationProvider } from './organization'
import { LinkProvider } from './link';

const providers = [
  UserProvider,
  StepProvider,
  OrganizationProvider,
  LinkProvider,
];

function ContextProviders({ children }) {
  return providers.reduceRight((children, Provider) => <Provider>{children}</Provider>, children);
}

export default ContextProviders;
