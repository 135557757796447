import { Box, Typography, Avatar } from '@mui/material';
import { indigo } from '@mui/material/colors';

function TeamCard({ statImg, statTitle, statSubtitle, statPosition }) {
  return (
    <Box sx={{
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      bgcolor: 'white',
      borderRadius: 2,
      background: `linear-gradient(to right, ${indigo[50]}, ${indigo[100]})`,
      boxShadow: 1,
      p: 2,
      m: 0.2
    }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
        <Box sx={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
          <Avatar src={statImg} sx={{ width: 55, height: 55, bgcolor: indigo[500] }} />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}>
          <Typography variant="h5" color={indigo[900]} noWrap>{statTitle}</Typography>
          <Typography variant="subtitle1" color={indigo[700]} fontWeight="bold" noWrap>{statSubtitle}</Typography>
          <Typography variant="subtitle2" color={indigo[700]} fontWeight="bold" noWrap>{statPosition}</Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default TeamCard;

TeamCard.defaultProps = {
  statTitle: "User",
  statSubtitle: "teste@gmail.com",
  statPosition: "Copywriter",
  statIconColor: "green",
};