import React, { useMemo, useState, useContext } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Input from "@mui/material/Input";
import FormControl from "@mui/material/FormControl";
import Skeleton from "@mui/material/Skeleton";
import useOrganization from "../../../queries/useOrganization";
import Subtitle from "@/components/Subtitle";
import { indigo } from "@mui/material/colors";
import api from "@/services/api";
import Team from "./Team";
import { useQueryClient } from '@tanstack/react-query';
import AlertContext from '@/components/Alert/AlertContext';

function OrganizationContent() {
  const queryClient = useQueryClient();
  const { showAlert } = useContext(AlertContext);

  const fetchOrganization = useOrganization();

  const [organizationName, setOrganizationName] = useState('');

  const [isEditing, setIsEditing] = useState(false);

  const org = useMemo(() => {
    if (fetchOrganization.isSuccess) {
      return fetchOrganization.data[0];
    }
    return 0;
  }, [fetchOrganization]); 
  
  const handleChange = (e) => {
    setIsEditing(true)
    setOrganizationName(e.target.value);

    if (!e.target.value) {
      setIsEditing(false)
    }
  }

  const handleSave = async () => {
    try {
      showAlert("Carregando...", "info");
      await api.patch(`/api/organization/${org.id}/`, {
        name: organizationName,
        owner: org.dono,
      })

      queryClient.invalidateQueries('organization');

      showAlert("Sucesso", "success");


    } catch (error) {
      showAlert("Erro ao alterar nome.", "error");
    }
    setIsEditing(false);
  }

  return (
    <Paper sx={{ borderRadius: 3 }}>
      <Subtitle name="Organização" />
      <Grid container sx={{ 
        direction: 'flex', 
        flexDirection: 'row', 
        p: 2,
      }}>
        <Grid item xs={12} md={3} sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 1,
          alignSelf: 'flex-start'
        }}>
          {fetchOrganization.isLoading ? (
            <Skeleton width={200} />
          ) : (
            <>
              <FormControl>
                <Typography variant="h4" sx={{ textAlign: 'center', color: indigo[700], mb:2 }}>
                  Nome
                </Typography>
                <Input
                  defaultValue={org.name}
                  placeholder="Nome da organização"
                  size="small"
                  label="Nome"
                  onChange={handleChange}
                  disableUnderline
                  sx={{ mb: 1 }}
                  inputProps={{ style: { textAlign: 'center', background: indigo[50], borderRadius: 4, padding: 4 } }}
                />
                {isEditing && <Button variant="contained" onClick={handleSave}>Salvar</Button>}
              </FormControl>
            </> 
          )}
        </Grid>
        
        <Grid item xs={12} md={9} >
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 1,
            border: `1px solid ${indigo[200]}`,
            backgroundColor: indigo[100],
            boxShadow: 1,
            borderRadius: 2,
            width: '100%',
            p: 2
          }}>
            <Team org={org}/>
          </Box>
        </Grid>
      </Grid>
      
    </Paper>
  );
}

export default OrganizationContent;
