import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Subtitle from "@/components/Subtitle";
import { useUser } from "@/reducers/user";
import { indigo } from "@mui/material/colors";
import Skeleton from "@mui/material/Skeleton";
import { Link } from "react-router-dom";
import moment from 'moment';
import ModalCancel from "./ModalCancel";
import ModalReactive from "./ModalReactive";
import formatString from "./FormatString"

function SubscriptionContent() {

  const fetchUser = useUser()

  const user = fetchUser[0].logged ? fetchUser[0] : 0;

  const inputDate = user.trialEndDate;
  const nextInv = user.nextInvoice;

  const now = moment().startOf('day');
  const target = inputDate ? moment(inputDate).startOf('day') : moment(nextInv).startOf('day');
  const invoiceTarget = moment(nextInv).startOf('day');

  const daysUntil = target.diff(now, 'days');
  const daysInvoiceUntil = invoiceTarget.diff(now, 'days');
  const nextInvoiceFormat = moment(user.nextInvoice).format('DD/MM/YY - HH:mm');

  return (
    <Box>
      <Subtitle name="Plano"/>
      <Box sx={{ alignSelf: { xs: 'center', md: 'auto' }, p:2 }}>
        <Grid container sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          {user.logged && user.trialEndDate && daysUntil > 0 && (
          <Grid item xs={12} sx={{ alignSelf: 'baseline', mb:1 }}>
              <Box sx={{ textAlign: "center", borderRadius: 2, backgroundColor: "#e5d8b0" }}>
              <Typography variant="h5" sx={{ p: 0.2 }}>
                Período de teste: <b>{daysUntil} dias</b> </Typography>
            </Box>    
          </Grid>     
          )}
          <Grid item xs={12} sm={6} sx={{alignSelf: 'baseline'}}>
            <Box sx={{ textAlign: { xs: 'center', md: 'left' } }}>
              {!user.logged ? (
                <Skeleton width={100} />
              ) : (
                <Typography variant="h5"><b style={{ color: indigo[700] }}>
                  Plano atual:</b> {!!user.subscriptionStatus && user.hasAccess ? formatString(user.subscriptionStatus) : "Padrão" }</Typography>
              )}
            </Box>
            <Box sx={{ textAlign: { xs: 'center', md: 'left' } }}>
              {!user.logged ? (
                <Skeleton width={100} />
              ) : (
                <Typography variant="h5"><b style={{ color: indigo[700] }}>
                  Próxima fatura:
                  </b> {user.nextInvoice == null || user.cancelAtPeriodEnd ? "-" : nextInvoiceFormat }</Typography>
              )}
            </Box>
            <Box sx={{ textAlign: { xs: 'center', md: 'left' } }}>
              {!user.logged ? (
                <Skeleton width={100} />
              ) : (
                <Typography variant="h5"><b style={{ color: indigo[700] }}>
                  Dias de acesso:
                  </b> {user.nextInvoice == null || user.cancelAtPeriodEnd || !user.hasAccess ? "-" : daysInvoiceUntil}</Typography>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} sx={{display: 'grid', gap: 1}}>
            <Box sx={{ textAlign: { xs: 'center', sm: 'right' } }}>
              {!!user.cancelAtPeriodEnd && daysInvoiceUntil > 0 ?
                <ModalReactive />
              :
                <Button component={Link} to="/store" variant="contained" size="small">
                  {!user.cancelAtPeriodEnd && user.subscriptionStatus ?  "Fazer upgrade do plano" : "Assinar plano"}
                </Button>
              }
            </Box>
            <Box sx={{ textAlign: { xs: 'center', sm: 'right' } }}>
              {!user.cancelAtPeriodEnd &&
                <ModalCancel />
              }
            </Box>
          </Grid>
        </Grid>  
      </Box>
    </Box>
  )
}

export default SubscriptionContent