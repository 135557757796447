import { useState, useEffect, useCallback } from 'react';

export const useFieldChange = (getValues, setValue, setBtnStatus) => {
  const [totalCharacterCount, setTotalCharacterCount] = useState(0);
  const [allFieldsFilled, setAllFieldsFilled] = useState(false);

  const updateValues = useCallback(() => {
    const updatedValues = getValues();

    const combinedValues = [];
    for (const key in updatedValues) {
      if (Array.isArray(updatedValues[key])) {
        const values = updatedValues[key].map(field =>
          'checked' in field ? field.checked : (field.item || '')
        );
        combinedValues.push(...values);
      }
    }
    const totalValues = [...combinedValues];
    const totalCharCount = JSON.stringify(totalValues).length;
    setTotalCharacterCount(totalCharCount);
    const filledFields = totalValues.every(value =>
      (typeof value === 'string' && value.trim().length > 0) || value === true
    );

    setAllFieldsFilled(filledFields);
  }, [getValues]);

  const handleFieldChange = useCallback((event, index, fieldList) => {
    if (event && index !== undefined && fieldList) {
      if (event.target.type === 'checkbox') {
        setValue(`${fieldList}.${index}.checked`, event.target.checked);
      } else if (event.target.type === 'text') {
        setValue(`${fieldList}.${index}.item`, event.target.value);
      }
      setValue(`${fieldList}.${index}.item`, event.target.value);
      setBtnStatus(true);
    }
    updateValues();
  }, [setValue, setBtnStatus, updateValues]);

  useEffect(() => {
    updateValues();
  }, [updateValues]);

  return { handleFieldChange, totalCharacterCount, allFieldsFilled, updateValues };
}
