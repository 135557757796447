import React, {useState, useMemo} from 'react';
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { yellow, deepPurple } from "@mui/material/colors";
import CardStats from "./Card";
import SubtitleLaunchings from "@/components/SubtitleLaunchings";
import moment from 'moment';
import { Link } from "react-router-dom";
import MpIcon from '@mui/icons-material/Mp';
import useSharedMList from '../queries/useSharedMList';

// import useLaunchingList from '../queries/useLaunchingList';
// import useOrganization from "@/queries/useOrganization";

function LxClub() {
  const [hideBaseLaunch, setHideBaseLaunch] = useState(false);
  const [hideCompleteLaunch, setHideCompleteLaunch] = useState(false);

  // const fetchOrganization = useOrganization();
  // const fetchOrganization = ""
  // const org = useMemo(() => {
  //   if (fetchOrganization.isSuccess) {
  //     return fetchOrganization.data[0];
  //   }
  //   return null;
  // }, [fetchOrganization]);

  // const fetchLaunchingList = useLaunchingList(org?.id);
  const fetchLaunchingList = useSharedMList();
  const list = useMemo(() => {
    if (fetchLaunchingList.isSuccess) {
      return fetchLaunchingList.data;
    }
    return [];
  }, [fetchLaunchingList]);


  const listBase = list && list.filter(item => item.launchType === "mapalx");
  const listCompleto = list && list.filter(item => item.launchType === "completo");

  return(
    <Paper
        sx={{ borderRadius: 3, display: 'flex', flexDirection: 'column', gap: 2 }}
      >
        <SubtitleLaunchings name="LX Club - Marcos Paulo" colorName="deepPurple" />
        {/* <ModalLauncherfy/> */}
        <Box sx={{ width: '100%', display: 'flex', px: 1, pb: 1}}>
          <Grid container spacing={2}>           
            <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', height: 'fit-content' }}>
              <Grid item xs={12} sx={{
                display: 'flex', justifyContent: 'space-between',
                backgroundImage: `linear-gradient(to right, #56179B, #000 )`,
                borderRadius: 2,
                border: `solid 1px ${deepPurple[500]}`,
                px: 2,
              }}>
                <Typography variant="h6" color="#fff" sx={{ alignSelf: 'center' }}>Mapa LX</Typography>
                <Button sx={{ px: 1, color: "#fff" }} onClick={() => setHideBaseLaunch(!hideBaseLaunch)}>
                  {hideBaseLaunch ? "Mostrar" : "Esconder"}
                </Button>
              </Grid>
              {!hideBaseLaunch && <Grid item xs={12}>  
              {listBase && listBase.length > 0 ? listBase.map((item, index) => (
                <Button key={`base_bt_${index}`} component={Link} sx={{ width: '100%', p: 0, my: 1 }} to={`/shared/mapalx/${item.id}`}>
                  <CardStats statTitle={item.name} date={moment(item.launchDate, "YYYY-MM-DD").format("DD/MM/YYYY")} progressValue={item.progress} />
                </Button>
              ))
                :
                <Box sx={{ width: '100%', p: 0, my: 1 }}>
                  {/* <CardStats colorName="grey" progressValue={0} /> */}
                  <CardStats colorName="grey" progressValue={0} StatIconComponent={MpIcon}  />
                </Box>
              }   
              </Grid>}
            </Grid>
          </Grid>
        </Box>        
      </Paper>
  )
}

export default LxClub