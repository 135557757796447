import React from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Title from "@/components/Title";
import Launcherfy from "./Launcherfy";
import Formula from "./Formula";
import LxClub from "./LxClub";

function Container() {
    return(
    <Box sx={{ display: "flex", gap: 1, flexDirection: "column" }}>
      <Paper
        sx={{ borderRadius: 3, pb: 2 }}
      >
        <Title name="Lançamentos compartilhados" />
      </Paper>
      <Launcherfy/>
      <Formula/>
      <LxClub/>        
    </Box>
  )
}

export default Container