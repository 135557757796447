import React, { useState, useEffect, useContext } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import { green, indigo, red } from '@mui/material/colors';
import { useFieldChange } from './useFieldChange';
import api from '@/services/api';
import { useParams } from 'react-router-dom';
import AlertContext from '../Alert/AlertContext';
import { useCustomQueryClient } from './hooks/useCustomQueryClient';
import { useQueryClient } from '@tanstack/react-query';

function EventForm({ handleChange, expanded, panel, path, useLauncherfyById }) {
  const { id } = useParams();
  const [btnStatus, setBtnStatus] = useState(false);
  const defaultTextLabels = ["Evento"];
  const { showAlert } = useContext(AlertContext);
  const { data: launching, isSuccess } = useLauncherfyById(id);
  const queryClient = useQueryClient();
  const invalidate = useCustomQueryClient(queryClient)

  const { handleSubmit, register, control, getValues, setValue, reset } = useForm({
    defaultValues: {
      textFieldList: defaultTextLabels.map(item => ({ item: '' })),
    },
  });
  const { handleFieldChange, totalCharacterCount, allFieldsFilled } = useFieldChange(getValues, setValue, setBtnStatus);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isSuccess && launching && launching.eventName) {
          const eventArray = JSON.parse(launching.eventName);
          const textFieldList = eventArray.slice(0, 6).map(item => ({ item }));
          reset({ textFieldList });
          handleFieldChange();
          setBtnStatus(false);
        }
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };
    fetchData();
  }, [id, reset, handleFieldChange, launching, isSuccess]);

  const { fields: textFieldFields } = useFieldArray({
    control,
    name: 'textFieldList',
  });

  function shouldReplaceWithEmptyString(str) {
    const cleanedString = str.replace(/[\s\[\]'",]/g, "");
    return cleanedString === "";
  }

  const onSubmit = async (data) => {
    try {
      const textFieldListValues = data.textFieldList.map(field => field.item);
      const combinedValues = [...textFieldListValues];
      let combinedString = JSON.stringify(combinedValues);
      if (shouldReplaceWithEmptyString(combinedString)) {
        combinedString = null
      }
      await api.patch(`/api/${path}/${id}/`, {
        eventName: combinedString,
      });
      invalidate.invalidateQueries()

      setBtnStatus(false);
      showAlert("Dados atualizados com sucesso!", "success");

    } catch (error) {
      console.error("Erro ao atualizar os dados: ", error);
      showAlert("Ocorreu um erro ao tentar atualizar os dados. Por favor, tente novamente.", "error");
    }
  };

  return (
    <Accordion expanded={expanded === `panel${panel}`} onChange={handleChange(`panel${panel}`)}
      sx={{ borderRadius: 2, mb: 0.2, p: 0.5, position: 'initial', border: `solid 1px ${indigo[100]}` }}
    >

      <AccordionSummary
        key={allFieldsFilled.toString()}
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${panel}bh-content`}
        id={`panel${panel}bh-header`}
        sx={{
          // background: `linear-gradient(to right, ${indigo[200]}, ${indigo[400]})`,
          borderRadius: 2,
          minHeight: 0,
          ml:1,
          '&.Mui-expanded': {
            minHeight: 0,
          },
          '&::before': {content: '""', position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)', width: 10, height: 10, borderRadius: '50%', backgroundColor: allFieldsFilled ? green[500] : red[400]},
        }}
      >
        <Typography sx={{ width: '33%', flexShrink: 0 }}>
          Evento
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 1, pt: 2 }}>
        <Box
          component="form" onSubmit={handleSubmit(onSubmit)}
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: 1,
          }}>
          <Box sx={{ display: "flex", flexDirection: "column", alignSelf: "center", gap: 1, backgroundColor: indigo[50], p: 2, borderRadius: 2, width: 'inherit' }}>
            <Grid container gap={1} display="flex" justifyContent="center">
              {textFieldFields.map((field, index) => (
                <Box display="flex" alignItems="center" sx={{ gap: 1 }} key={field.id}>
                  <TextField
                    key={`event_${index}`}
                    {...register(`textFieldList.${index}.item`)}
                    onChange={event => handleFieldChange(event, index, 'textFieldList')}
                    label={defaultTextLabels[index]}
                    variant="outlined"
                    size="small"
                    sx={{
                      width: '100%', backgroundColor: '#fff', borderRadius: 2,
                      '& label.MuiInputLabel-shrink': {
                        backgroundColor: indigo[50],
                        borderRadius: 3, px: 0.8,
                      } }}
                  />
                </Box>
              ))}
            </Grid>
          </Box>
          <Typography>
            Caracteres: {60 - totalCharacterCount}
          </Typography>
          <Divider />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              gap: 1,
            }}>
            {btnStatus ?
              <Button type="submit">Salvar</Button>
              :
              <Button disabled>Salvar</Button>
            }
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

export default EventForm;
