import React from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Subtitle from "@/components/Subtitle";
import RenderStep from "@/components/Steps";
import { useStep } from "@/reducers/step";

function Steps() {
  const [step] = useStep();
  return(
    <Paper sx={{ borderRadius: 3 }}>
      <Subtitle name={step?.title || "Início"} />
      <Box sx={{ p:2}}>
        <Grid container sx={{direction: 'flex', justifyContent: 'center'}}>
          <Grid item xs={12} lg={9}>
            <RenderStep steps={step.step + 1}/>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}

export default Steps