import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import { green, indigo, red } from '@mui/material/colors';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import api from '@/services/api';
import { useParams } from 'react-router-dom';
import AlertContext from '../Alert/AlertContext';
import { useFieldChange } from './useFieldChange';
import { useCustomQueryClient } from './hooks/useCustomQueryClient';
import { useQueryClient } from '@tanstack/react-query';

function Meta({ handleChange, expanded, panel, path, useLauncherfyById }) {
  const { id } = useParams();
  const [btnStatus, setBtnStatus] = useState(false);
  const queryClient = useQueryClient();
  const invalidate = useCustomQueryClient(queryClient)
  const accessCheckboxToKeyMap = useMemo(() => ({
    "Criar fanpage": "fbfpck",
    "Criar Perfil Comercial Instagram": "fbigck",
    "Criar conta de anúncio": "fbcack",
    "Criar pixel": "fbpxck",
    "Configurar pixel": "fbipck",
    "Configurar pagamento": "fbpyck",
    "Campanhas de nutrição (distribuição de conteúdo) (se houver) (alcance)": "fbntck",
  }), []);

  const defaultAccessCheckbox = useMemo(() => ([
    "Criar fanpage",
    "Criar Perfil Comercial Instagram",
    "Criar conta de anúncio",
    "Criar pixel",
    "Configurar pixel",
    "Configurar pagamento",
    "Campanhas de nutrição (distribuição de conteúdo) (se houver) (alcance)"
  ]), []);

  const { showAlert } = useContext(AlertContext);
  const { data: launching, isSuccess } = useLauncherfyById(id);

  const { handleSubmit, control, getValues, setValue, reset } = useForm({
    defaultValues: {
      accessFieldList: defaultAccessCheckbox.map(item => ({ label: item, checked: false })),
    },
  });
  const { handleFieldChange, allFieldsFilled, updateValues } = useFieldChange(getValues, setValue, setBtnStatus);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isSuccess && launching) {

          const accessFieldList = defaultAccessCheckbox.map(label => {
            return { label, checked: !!launching[accessCheckboxToKeyMap[label]] };
          });

          reset({ accessFieldList });

          setBtnStatus(false);
        }
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };

    fetchData();
    updateValues();
  }, [id, reset, updateValues, launching, isSuccess, defaultAccessCheckbox, accessCheckboxToKeyMap]);


  const { fields: accessFieldFields } = useFieldArray({
    control,
    name: 'accessFieldList',
  });

  const onSubmit = async (data) => {
    try {
      const accessFieldListValues = data.accessFieldList.map((field, index) => ({
        key: accessCheckboxToKeyMap[defaultAccessCheckbox[index]],
        value: field.checked
      }));

      const fieldData = Object.fromEntries([...accessFieldListValues].map(({ key, value }) => [key, value]));

      await api.patch(`/api/${path}/${id}/`, fieldData);

      invalidate.invalidateQueries()

      setBtnStatus(false);
      showAlert("Dados atualizados com sucesso!", "success");

    } catch (error) {
      console.error("Erro ao atualizar os dados: ", error);
      showAlert("Ocorreu um erro ao tentar atualizar os dados. Por favor, tente novamente.", "error");
    }
  };


  return (
    <Accordion expanded={expanded === `panel${panel}`} onChange={handleChange(`panel${panel}`)}
      sx={{ borderRadius: 2, mb: 0.2, p: 0.5, position: 'initial', border: `solid 1px ${indigo[100]}` }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${panel}bh-content`}
        id={`panel${panel}bh-header`}
        sx={{
          // background: `linear-gradient(to right, ${indigo[200]}, ${indigo[400]})`,
          borderRadius: 2,
          minHeight: 0,
          ml: 1,
          '&.Mui-expanded': {
            minHeight: 0,
          },
          '&::before': { content: '""', position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)', width: 10, height: 10, borderRadius: '50%', backgroundColor: allFieldsFilled ? green[500] : red[400] },
        }}
      >
        <Typography sx={{ width: '100%', flexShrink: 0 }}>
          Meta
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 1, pt: 2 }}>
        <Box
          component="form" onSubmit={handleSubmit(onSubmit)}
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: 1,
          }}>
          <Box sx={{ display: "flex", flexDirection: "row", alignSelf: "center", gap: 1, backgroundColor: indigo[50], p: 1, borderRadius: 2 }} >
            <Grid container display="flex" justifyContent="center" spacing={1}>
              <Grid item >
                <Grid container gap={1} display="flex" direction="column" sx={{ bgcolor: "#fff", borderRadius: 2, height: '100%' }}>
                  <Typography variant="h5" sx={{ bgcolor: '#000', p: 1, borderRadius: 2, color: '#CF2CF6', fontWeight: "800" }}>Organização</Typography>
                  {accessFieldFields.map((field, index) => (
                    <Box display="flex" alignItems="center" sx={{ gap: 1, px: 1 }} key={field.id}>
                      <Controller
                        name={`accessFieldList.${index}.checked`}
                        control={control}
                        defaultValue={field.checked}
                        render={({ field }) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={field.value}
                                onChange={(e) => {
                                  field.onChange(e.target.checked);
                                  handleFieldChange(e, index, "accessFieldList");
                                  setBtnStatus(true);
                                }}
                                sx={{
                                  color: '#CF2CF6',
                                  '&.Mui-checked': {
                                    color: '#CF2CF6',
                                  }
                                }}
                              />
                            }
                            label={defaultAccessCheckbox[index]}
                            sx={{ width: '100%' }}
                          />
                        )}
                      />
                    </Box>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              gap: 1,
            }}>
            {btnStatus ?
              <Button type="submit">Salvar</Button>
              :
              <Button disabled>Salvar</Button>
            }
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

export default Meta;
