import React, {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Toolbar from "@mui/material/Toolbar";
import { grey, indigo, red } from "@mui/material/colors";
import { useUser } from "@/reducers/user";
import { useLink } from "@/reducers/link";
import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { Link } from "react-router-dom";
import LinkIcon from '@mui/icons-material/Link';
import DescriptionIcon from '@mui/icons-material/Description';
import { useParams } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import CardMedia from '@mui/material/CardMedia';

function Container({ open, handleMenu, handleMenuLink, handleClose, handleCloseLink, handleDrawerOpen, handleLogout, anchorEl, anchorElLink, AppBar }) {
  const [user] = useUser();
  const [link] = useLink();
  const [userImage, setUserImage] = useState(user.image);
  const { option, id } = useParams();

  const location = useLocation();
  const pathParts = location.pathname.split('/');
  const product = pathParts[2];

  useEffect(() => {
    setUserImage(user.image);
  }, [user.image]);

  const StyledImg = styled('img')({
    width: '100%',
    height: '100%',
    display: 'block',
    objectFit: 'cover',
  });

  return (
    <AppBar position="fixed" open={open} color="white" >
      <Toolbar sx={{paddingRight: '0px'}}>
        <IconButton
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: 2,
            ...(open && { display: "none" }),
            color: indigo[600]
          }}
        >
          <MenuIcon type="button" />
        </IconButton>
        <Box
          sx={{ flexGrow: 1}}
        >
          <Box
            component={Link} to={`/`}
          >
            <CardMedia sx={{ borderRadius: 2, marginTop: '1px', width: '32px', float: 'left' }}
              component="img"
              image="/logo512.png"
              alt="method"
            />
          </Box>
          <Box
            component={Link}
            variant="h1"
            to="/"
            sx={{
              my: 1, textDecoration: 'none', color: indigo[700], flexGrow: 1,
              fontWeight: '700',
              fontSize: '1.5rem'
            }}
          >
            Launcherfy
          </Box>
        </Box>

        
        <Box sx={{display: 'flex', gap: 1, justifyContent: 'center'}}>     
          {link?.links && 
            <>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
              component={Link} to={`/${option}/${product}/${id}/contract`}
                color="inherit"
                sx={{ p: 0 }}
              >
                <DescriptionIcon fontSize="large" sx={{ color: indigo[600], height: '24px' }} />
              </IconButton>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenuLink}
                color="inherit"
                sx={{ p: 0 }}
              >
                <LinkIcon fontSize="large" sx={{ color: indigo[600], height: '24px' }} />

              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElLink}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElLink)}
                onClose={handleCloseLink}
              >
                <Box sx={{ px: 2, pb: 1 }}>
                  <Typography variant="h5" sx={{ color: indigo[900] }}> Links</Typography>
                </Box>
                <Divider />
                <Typography variant="h5" sx={{ pl: 1, mt: 1, ml: 2.5, position: 'relative', '&::before': { content: '""', position: 'absolute', left: -5, top: '50%', transform: 'translateY(-50%)', width: 8, height: 8, borderRadius: '50%', backgroundColor: grey[400] } }} >Drive</Typography>
                <MenuItem target="_blank" component={Link} to={link.links[0]} sx={{ '&:hover': { backgroundColor: grey[200], color: '#000' } }}>{link.links[0]}</MenuItem>
                <Divider />
                <Typography variant="h5" sx={{ pl: 1, mt: 1, ml:2.5, position: 'relative', '&::before': { content: '""', position: 'absolute', left: -5, top: '50%', transform: 'translateY(-50%)', width: 8, height: 8, borderRadius: '50%', backgroundColor: indigo[400] } }} >Páginas</Typography>
                {
                  Object.entries(link.links[2]).map(([key, value]) => {
                    return <MenuItem target="_blank" key={key} component={Link} to={value} sx={{ '&:hover': { backgroundColor: indigo[200], color: '#fff' } }}><b style={{marginRight: '4px'}}>{key}:</b> {value}</MenuItem>
                  })
                }
                <Divider sx={{my:0.5}}/>
                <Typography variant="h5" sx={{ pl: 1, ml: 2.5, position: 'relative', '&::before': { content: '""', position: 'absolute', left: -5, top: '50%', transform: 'translateY(-50%)', width: 8, height: 8, borderRadius: '50%', backgroundColor: "#4267B2"} }} >Facebook Fanpage</Typography>
                {link?.links[1] &&
                  <MenuItem target="_blank" component={Link} to={link.links[1]} sx={{ '&:hover': { backgroundColor: "#4267B299", color: '#fff' } }}>{link.links[1]}</MenuItem>
                }
                <Divider sx={{ my: 0.5 }} />
                <Typography variant="h5" sx={{ pl: 1, ml: 2.5, position: 'relative', '&::before': { content: '""', position: 'absolute', left: -5, top: '50%', transform: 'translateY(-50%)', width: 8, height: 8, borderRadius: '50%', backgroundColor: "#25D366"} }} >Grupos do whats</Typography>
                {
                  Object.entries(link.links[5]).map(([key, value]) => {
                    return <MenuItem target="_blank" key={key} component={Link} to={value} sx={{ '&:hover': { backgroundColor: "#25D36699", color: '#fff' } }}><b style={{ marginRight: '4px' }}>{key}:</b> {value}</MenuItem>
                  })
                }
                <Divider sx={{ my: 0.5 }}/>
                <Typography variant="h5" sx={{ pl: 1, ml: 2.5, position: 'relative', '&::before': { content: '""', position: 'absolute', left: -5, top: '50%', transform: 'translateY(-50%)', width: 8, height: 8, borderRadius: '50%', backgroundColor: "#ff0000"} }} >Link Youtube</Typography>
                  {link?.links[3] &&
                  <MenuItem component={Link} to={link.links[3]} sx={{ '&:hover': { backgroundColor: "#ff000077", color: '#fff' } }}>{link?.links[3]}</MenuItem>
                  }
                <Divider sx={{ my: 0.5 }} />
                <Typography variant="h5" sx={{ pl: 1, ml: 2.5, position: 'relative', '&::before': { content: '""', position: 'absolute', left: -5, top: '50%', transform: 'translateY(-50%)', width: 8, height: 8, borderRadius: '50%', backgroundColor: red[200]} }} >CPLS</Typography>
                  {link?.links[3] &&
                  <MenuItem target="_blank" component={Link} to={link.links[3]} sx={{ '&:hover': { backgroundColor: red[50], color: '#000' } }}>{link.links[3]}</MenuItem>
                  }
                <Divider sx={{ my: 0.5 }} />
                <Typography variant="h5" sx={{ pl: 1, ml: 2.5, position: 'relative', '&::before': { content: '""', position: 'absolute', left: -5, top: '50%', transform: 'translateY(-50%)', width: 8, height: 8, borderRadius: '50%', backgroundColor: "#FACA15"} }} >Link de checkout</Typography>
                {link?.links[4] &&
                <MenuItem target="_blank" component={Link} to={link.links[4]} sx={{ '&:hover': { backgroundColor: "#FACA1599", color: '#000' } }}>{link.links[4]}</MenuItem>
                }
              </Menu>
            </>
          }
          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
              sx={{ p: 0 }}
            >
              {userImage && userImage !== "" ?
                <Box
                  sx={{
                    width: "40px",
                    height: "40px",
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: "100%",
                    border: "2px solid #6366F1",
                    overflow: "hidden",
                  }}
                >
                  <StyledImg
                    src={userImage}
                    alt=""
                  />
                </Box>
                :
                <AccountCircle fontSize="large" sx={{ color: indigo[600], width: '40px', height: '40px' }} />
              }
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <Box sx={{ px: 2, pb: 1 }}>
                <Typography variant="h5" sx={{ color: indigo[900] }}> {user?.name}</Typography>
                <Typography variant="h6" sx={{ color: indigo[700] }}> {user?.email}</Typography>
              </Box>
              <Divider />
              <MenuItem component={Link} to="/profile" sx={{ '&:hover': { backgroundColor: indigo[200], color: '#fff' } }}>Perfil</MenuItem>
              <MenuItem onClick={handleLogout} sx={{ '&:hover': { backgroundColor: indigo[200], color: '#fff' } }}>Sair</MenuItem>
            </Menu>
          </div>
        </Box>
        
      </Toolbar>
    </AppBar>
  )
}

export default Container