import { useQuery } from "@tanstack/react-query";
import api from "@/services/api";

function useLaunchingList(org_id) {
  return useQuery({
    queryKey: ["launchingList"],
    queryFn: () => api.get(`/launchinglists/${org_id}/launchings`).then((response) => response.data),
    enabled: !!org_id
  });
}

export default useLaunchingList;
