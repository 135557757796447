import React, { useState, useEffect, useMemo } from "react";
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import moment from 'moment';
import * as colors from "@mui/material/colors";
import TimelineItem from './TimelineItem';
import { useStep } from '@/reducers/step';

function MyComponent({ date, creationDate, launching }) {
  const [numCreationCards, setNumCreationCards] = useState(0);
  const today = moment().format("DD.MM.YYYY");

  const [, dispatch] = useStep();

  useEffect(() => {
    const newComponentStatus = {
      0: { niche: !!launching.niche, persona: !launching?.persona?.includes(',""') && !!launching.persona, promise: !!launching.promise, event: !!launching.eventName, list: !!launching.costLead && !!launching.periodCapt && !!launching.budget },
      1: { creatives: !launching?.creatives?.includes(',""') && !!launching.creatives, salesVScript: !!launching.salesVScript, capPage: !!launching.capPage, thankPage: !!launching.thankPage  },
      2: {
        domain: !!launching.domain && !!launching.domainck, 
        platform: !!launching.fbfpck && !!launching.fbigck && !!launching.fbcack && !!launching.fbpxck && !!launching.ytchck && !!launching.ytltck && !!launching.ytttck
          && !!launching.plcack && !!launching.plcpck && !!launching.pltpck && !!launching.plewck && !!launching.mmcgck && !!launching.fanpageLink && !!launching.youtubeLink && !!launching.checkoutLink,
        whatsapp: !!launching.wpcack && !!launching.whatsappLink
        },
      3: { notifications: !!launching.notifications },
      4: { creativeRecs: !launching?.creativeRecs?.includes(',""') && !!launching.creativeRecs },
      5: { createPag: !!launching.cpcrck && !!launching.tpcrck && !!launching.pagesLink, emailMkt: !!launching.mmcfck && !!launching.mmcsck, meta: !!launching.fbipck, whatsapp: !!launching.wpigck, test: !!launching.testForm },
      6: { campaign: !!launching.fbsack && !!launching.fbccck && !!launching.fbcpck && !!launching.fbadck && !!launching.fbccampaignck, testCampaign: !!launching.testEmail && !!launching.TestCampaign },
      7: { traffic: !!launching.traffic },
      8: { verify: !!launching.verifySend, deliverable: !launching?.deliverable?.includes(',""') && !!launching.deliverable, bonus: !launching?.bonus?.includes(',""') && !!launching.bonus, price: !launching?.price?.includes(',""') && !!launching.price, warranty: !launching?.warranty?.includes(',""') && !!launching.warranty },
      9: { script: !!launching.launchingScript, recordTraining: !!launching.recordTraining },
      10: { salesPage: !!launching.salesPage, meta: !!launching.fbspck, eMarketing: !!launching.mmcock },
      11: { traffic: !!launching.traffic, otimization: !!launching.optBudget && !!launching.optCreatives },
      12: { notifications: !!launching.notificationsPos },
      13: { verify: !!launching.verifySend2 },
      14: { traffic: !!launching.traffic },
      15: { otimization: !!launching.optBudget2 && !!launching.optCreatives2 },
      16: {},
      17: {},
    };

    dispatch({ type: 'UPDATE_COMPONENT_STATUS', payload: newComponentStatus });
  }, [launching, dispatch]); 

  const array = ["1,2", "3", "4", "5", "6,7,8", "9,10", "11,12,13", "14", "15", "16,17,18", "19,20", "21", "22", "23", "-", "-", "-", "27", "-", "-", "-", "31", "-", "-", "34"];
  const reversedArray = [...array].reverse();

  const newArray = reversedArray.map((item, index) => {
    const days = item.split(',');
    if (days.length > 1) {
      return `Dias ${days.length > 2 ? `${days[0]} - ${days[days.length - 1]}` : `${days[0]} e ${days[1]}`}`;
    } else {
      return `Dia ${days[0]}`;
    }
  });

  const cartOpen = 5;
  const cartOpenDates = useMemo(() => {
    const dates = [];
    const momentDate = moment(); 
    for (let i = 1; i <= cartOpen; i++) {
      const currentDate = moment(momentDate).add(i, 'days').format("DD.MM.YYYY");
      dates.push(currentDate);
    }
    return dates;
  }, [cartOpen])

  const generateTimeline = () => {
    let momentDate = moment(date, "DD.MM.YYYY");
    return reversedArray.map((item, index) => {
      const days = item.split(',').length;

      momentDate = momentDate.subtract(days, 'days');
      if (!item.includes('-')) {
        return {
          date: momentDate.format("DD.MM.YYYY"),
          title: `${newArray[index]}`,
          description: `Acessar`,
          bgColor: colors['indigo'][100],
        };
      }
      return null;
    })
      .filter(item => item !== null)
      .reverse();
  };

  const [timelineData, setTimelineData] = useState(generateTimeline());
  
  useEffect(() => {
    if (creationDate) {
      const startCreationDate = moment(creationDate, "DD.MM.YYYY");
      const firstTimelineDate = moment(timelineData[0].date, "DD.MM.YYYY");
      const diff = firstTimelineDate.diff(startCreationDate, 'days');

      if (diff >= 0) {
        setNumCreationCards(Math.max(diff, 0));

        const newTimeline = [...timelineData];
        for (let i = diff - 1; i >= 0; i--) {
          const dateToAdd = startCreationDate.clone().add(i, 'days').format("DD.MM.YYYY");
          newTimeline.unshift({
            date: dateToAdd,
            title: "Criação de audiência ",
            title2: "Aquecimento",
            bgColor: colors['indigo'][50]
          });
        }

        newTimeline.push({
          date: date,
          title: "Lançamento",
          description: "Acessar",
          bgColor: colors['indigo'][300],
          color: "#fff"
        });

        cartOpenDates.forEach((currentDate) => {
          newTimeline.push({
            date: currentDate,
            title: "Carrinho aberto",
            bgColor: colors['deepPurple'][100]
          });
        });

        newTimeline.push({
          date: "-",
          title: "Debriefing",
          description: "Acessar",
          type: "debriefing",
          bgColor: colors['yellow'][200]
        });

        setTimelineData(newTimeline);
      }
    }
  }, [creationDate, date]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Paper sx={{ borderRadius: 3 }}>
      <Box sx={{ overflow: 'auto', maxWidth: '100vw' }}>
        <List sx={{ position: 'relative', display: 'flex', flexDirection: 'row', px: 1, gap: '6px', width: 'fit-content' }}>
          <Box sx={{ position: 'absolute', left: 22, right: 0, height: '2px', bgcolor: colors.grey[700], zIndex: 1, minWidth: '115rem' }} />
          {timelineData.map((item, index) => {
            const isPastDate = moment(item.date, "DD.MM.YYYY").isBefore(moment(today, "DD.MM.YYYY"));
            const bgcolor = item.bgColor;
            const props = { bgcolor, item, index, colors, isPastDate };
            return (
              <TimelineItem key={`timelineItem_${index}`} {...props} numCreationCards={numCreationCards} />
            );
          })}
        </List>
      </Box>
    </Paper>
  );
}

export default MyComponent;
