import React, {useMemo} from "react";
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import api from '@/services/api';
import { useParams } from 'react-router-dom';

function ProgressBar({launching}) {
  const { id, option } = useParams();
  let path

  if (option === "launchings") {
    path = "launcherfylaunchings"
  } else {
    path = "launcherfyshared"
  }

  function calculatePercentage(obj) {
    let totalFields = 0;
    let filledFields = 0; 
    const ignoredKeys = ["id", "name", "launchType", "launchDate", "creationDate", "enabled", "launchingList", "avatar", "mtck", "ggck", "method", "schedule", "group_lists"];

    const keys = Object.keys(obj).filter(key => !ignoredKeys.includes(key));
    keys.forEach(key => {
      totalFields++; 
      if (obj[key] !== "" && obj[key] !== null && obj[key] !== false ) {
        filledFields++;
      }
    });

    const percentage = (filledFields / totalFields) * 100;
    
    api.patch(`/api/${path}/${id}/`, {
      progress: percentage,
    });

    return percentage;
  }
  const progressValue = calculatePercentage(launching)
  const progressColor = useMemo(() => {
    if (progressValue <= 25) {
      return 'red'
    }

    if (progressValue <= 50) {
      return '#FF8C00'
    }

    if (progressValue <= 75) {
      return '#ffdf00'
    }

    return '#00a400'
  }, [progressValue]);

  const progressColorDarker = useMemo(() => {
    if (progressValue <= 25) {
      return 'darkred'
    }

    if (progressValue <= 50) {
      return '#8B4513'
    }

    if (progressValue <= 75) {
      return 'goldenrod'
    }

    return '#005F00'
  }, [progressValue]);


  return(
    <Paper sx={{ borderRadius: 3, display: 'flex', flexDirection: 'column', p: 2}}>
      <LinearProgress variant="determinate" value={progressValue}
        sx={{  
          p:0.3,        
          bgcolor: progressColorDarker,
          '& .MuiLinearProgress-bar': {
            bgcolor: progressColor,
          }
        }}
      />   
    </Paper>        
  )
}

export default ProgressBar