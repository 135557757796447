import * as React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { indigo } from '@mui/material/colors';
import Layout from '../layout';

export default function Privacity() {

  return (
    <Layout>
      <Container disableGutters maxWidth="lg" component="main" sx={{ pt: 8, pb: 6, px: 4 }}>
        <Typography
          variant="h4"
          align="center"
          color={indigo[700]}
          gutterBottom
        >
          POLÍTICA DE PRIVACIDADE
        </Typography>
        <Typography variant="h6" align="center" color="text.secondary" component="p">
          <hr/>Data da última atualização: 03/07/2023
          <hr/>
          Sua privacidade é importante para nós. É política da Launcherfy respeitar a sua privacidade em relação a qualquer informação sua que possamos coletar em nosso site, Launcherfy.com e outros sites que possuímos e operamos.
          <p/>
          Solicitamos informações pessoais apenas quando realmente precisamos delas para lhe fornecer um serviço. Fazemo-lo por meios justos e legais, com o seu conhecimento e consentimento. Também informamos por que estamos coletando e como será usado.
          <p />
          Apenas retemos as informações coletadas pelo tempo necessário para fornecer o serviço solicitado. Quando armazenamos dados, protegemos dentro de meios comercialmente aceitáveis para prevenir perdas e roubos, bem como acesso, divulgação, cópia, uso ou modificação não autorizados.
          <p />
          Não compartilhamos informações de identificação pessoal publicamente ou com terceiros, exceto quando exigido por lei.
          <p />
          O nosso site pode ter links para sites externos que não são operados por nós. Esteja ciente de que não temos controle sobre o conteúdo e práticas desses sites e não podemos aceitar responsabilidade por suas respectivas políticas de privacidade.
          <p />
          Você é livre para recusar a nossa solicitação de informações pessoais, entendendo que talvez não possamos fornecer alguns dos serviços desejados.
          <p />
          O uso continuado de nosso site será considerado como aceitação de nossas práticas em torno de privacidade e informações pessoais. Se você tiver alguma dúvida sobre como lidamos com dados do usuário e informações pessoais, entre em contato conosco.


        </Typography>
      </Container>
    </Layout>
  );
}
