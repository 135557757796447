import React from 'react';
import ReactQuill from 'react-quill';
import Box from '@mui/material/Box';
import 'react-quill/dist/quill.snow.css';

function WysiwygPage({ setBtnStatus, setContent, content, handleFieldChange, fieldName }) {
  const editorOptions = {
    modules: {
      toolbar: [
        [{ header: [1, 2, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ color: [] }, { background: [] }],
        [{ font: [] }],
        [{ align: [] }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link', 'image'],
        ['clean'],
      ],
    },
    formats: [
      'header',
      'bold',
      'italic',
      'underline',
      'strike',
      'color',
      'background',
      'font',
      'align',
      'list',
      'bullet',
      'link',
      'image',
    ],
  };

  const handleChange = (value,name) => {
    setContent(value);
    handleFieldChange(fieldName, value);
  };

  return (
    <Box className="wysiwyg-container" style={{ height: 'fit-content' }}>
      <ReactQuill value={content} name={fieldName} onChange={handleChange} modules={editorOptions.modules} formats={editorOptions.formats} />
    </Box>
  );
}

export default WysiwygPage;
