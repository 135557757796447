import React, {useContext} from "react";
import Modal from "@mui/material/Modal";
import Title from "@/components/Title";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AlertContext from '@/components/Alert/AlertContext';
import api from '@/services/api';
import { useQueryClient } from '@tanstack/react-query';

function ModalTeam({ handleCloseDelete, openDelete, groupId, userId }) {
  const queryClient = useQueryClient();
  const { showAlert } = useContext(AlertContext);

  const handleDeleteUser = async (data) => {
    try {
      await api.delete(`/api/groups/${groupId}/users/${data.user.email}`);

      queryClient.invalidateQueries('organization');
      showAlert("Usuário Removido", "success");

      handleCloseDelete()
    } catch (error) {
      console.error(error.response.data.error);
      showAlert(error.response.data.error, "error");
    }
  };

  return(
    <Modal open={openDelete} onClose={handleCloseDelete}>
      <Box
        sx={{
          width: 400,
          bgcolor: "background.paper",
          margin: "auto",
          marginTop: "10%",
          outline: "none",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          borderRadius: 3,
          boxShadow:
            "rgb(255 255 255 / 10 %) 0px 1px 24px, rgb(255 255 255 / 9 %) 0px 15px 12px",
        }}
      >
        <Title name="Remover membro do grupo" />
        <Box sx={{ padding: 2, gap: 1, display: "flex", flexDirection: "column" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: 1,
              alignItems: "center",
            }}>
            <Typography>Você tem certeza de que deseja remover <b>{userId?.user?.name}</b> - <b>{userId?.user?.email}</b>  do grupo?</Typography>
            <Box sx={{ display: "flex", justifyContent: "space-between",width: "100%", mt: 2, gap:1 }}>
              <Button variant="outlined" color="primary" onClick={handleCloseDelete}>
                Cancelar
              </Button>
              <Button variant="contained" color="error" onClick={() => handleDeleteUser(userId)}>
                Remover
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default ModalTeam