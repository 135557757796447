import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
// import { styled } from '@mui/system';
import Title from "@/components/Title";
import { indigo, red } from '@mui/material/colors';
import { useParams } from 'react-router-dom';
import useLauncherfyById from "@/queries/useLauncherfyById";
import useFormulaById from '@/queries/useFormulaById';
import useSharedLListId from "@/pages/Shared/queries/useSharedLListId";
import useSharedFListId from "@/pages/Shared/queries/useSharedFListId";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import { Link } from "react-router-dom";
import MultipleSelectCheckmarks from '@/components/Select';
// import AlertContext from '@/components/Alert/AlertContext';
// import api from '@/services/api';

const initialRows = [
  {
    id: 1,
    activity: "Definir nicho, avatar e roma",
    involved: "",
  },
  {
    id: 2,
    activity: "Definir a oferta",
    involved: "",
  },
  {
    id: 3,
    activity: "pesquisar avatar",
    involved: "",
  },
  {
    id: 4,
    activity: "Conteúdo Raiz (Roteiros)",
    involved: "",
  },
  {
    id: 5,
    activity: "Conteúdo Raiz (Temas)",
    involved: "",
  },
  {
    id: 6,
    activity: "Calendário de Publicação",
    involved: "",
  },
  {
    id: 7,
    activity: "Edição dos raízes",
    involved: "",
  },
  {
    id: 8,
    activity: "Distribuição dos Raízes",
    involved: "",
  },
  {
    id: 9,
    activity: "Preparação das URLS",
    involved: "",
  },
  {
    id: 10,
    activity: "Planejamento de tráfego",
    involved: "",
  },
  {
    id: 11,
    activity: "Configurações iniciais de tráfego",
    involved: "",
  },
  {
    id: 12,
    activity: "Pixels e Scripts",
    involved: "",
  },
  {
    id: 13,
    activity: "Configurar páginas do lan",
    involved: "",
  },
  {
    id: 14,
    activity: "Copy dos criativos",
    involved: "",
  },
  {
    id: 15,
    activity: "Gravar Criativos",
    involved: "",
  },
  {
    id: 16,
    activity: "Whatsapp ou Telegram",
    involved: "",
  },
  {
    id: 17,
    activity: "Criar e agendar emails e mensagens",
    involved: "",
  },
  {
    id: 18,
    activity: "Programar os emails",
    involved: "",
  },
  {
    id: 19,
    activity: "Programar disparos",
    involved: "",
  },
  {
    id: 20,
    activity: "Programar automações",
    involved: "",
  },
  {
    id: 21,
    activity: "Testar tudo",
    involved: "",
  },
  {
    id: 22,
    activity: "Fazer as lives",
    involved: "",
  },
  {
    id: 23,
    activity: "Editar as lives",
    involved: "",
  },
  {
    id: 24,
    activity: "Publicar as lives",
    involved: "",
  },
  {
    id: 25,
    activity: "Criar os nutellas",
    involved: "",
  },
  {
    id: 26,
    activity: "Publicar os nutellas",
    involved: "",
  },
  {
    id: 27,
    activity: "Distribuir os raizes e nutellas",
    involved: "",
  },
  {
    id: 28,
    activity: "Início da capta de leads",
    involved: "",
  },
  {
    id: 29,
    activity: "Otimiza da campanha",
    involved: "",
  },
  {
    id: 30,
    activity: "Preparar o roteiro do Lancamento",
    involved: "",
  },
  {
    id: 31,
    activity: "Criar o produto",
    involved: "",
  },
  {
    id: 32,
    activity: "Configurar transmissão",
    involved: "",
  },
  {
    id: 33,
    activity: "Disparar e-mails de PPL",
    involved: "",
  },
  {
    id: 34,
    activity: "Disparar mensagens de Whatsapp ou Telegram",
    involved: "",
  },
  {
    id: 35,
    activity: "Criar e agendar e-mails e mensagens",
    involved: "",
  },
  {
    id: 36,
    activity: "Disparos de e-mails e mensagens PL",
    involved: "",
  },
  {
    id: 37,
    activity: "Ensaio do LS",
    involved: "",
  },
];

function Attribution() {
  // const [btnStatus, setBtnStatus] = useState(false);
  // const { showAlert } = useContext(AlertContext);
  // const [error,] = useState();
  // const [success,] = useState()
  const { option, product, id } = useParams();
  // const [noAttribution, setNoAttribution] = useState(false);
  const [rows,] = useState(initialRows);

  const apiHook = option === "launchings" && product === "launcherfy" ? 
    useLauncherfyById : option === "launchings" && product === "formula" ?
      useFormulaById : option === "shared" && product === "formula" ? useSharedFListId : useSharedLListId;
  
  let launching = apiHook(id).data;

  if (!launching || launching.schedule === undefined) {
    return (
      <Box sx={{ display: "flex", gap: 1, flexDirection: "column" }}>
        <Paper sx={{ borderRadius: 3, p: 2, backgroundColor: red[100], border: `solid 1px ${red[200]}` }}>
          <Typography variant="h5" color="error">O método não existe.</Typography>
        </Paper>
      </Box>
    );
  }

  return (
      <Box sx={{ display: "flex", gap: 1, flexDirection: "column" }}>
        <Paper sx={{ borderRadius: 3 }}>
          <Title name="Atribuição das tarefas" />
          <Box sx={{ p: 2, backgroundColor: 'background.default', borderRadius: '0 0 5px 5px', display: 'flex', flexDirection: 'row' }}>
            <Button
              component={Link}
              to={`/${option}/${product}/${id}`}
              variant="outlined">
              Voltar
            </Button>
            <Box sx={{ width: '100%', alignSelf: 'center', px: 1 }}>
              <Typography variant="h6" color="text.secondary" align="center" sx={{ alignSelf: 'center' }}>
                Definição das atividades de cada membro da equipe
              </Typography>
            </Box>
          </Box>
          <Divider />
          <Box sx={{ p: 2, gap: 2 }}>

            <TableContainer
              sx={{ p: 1, pb: 2, width: '100%' }}
            >
              <Table aria-label="simple table">
                <TableBody>
                  <Grid container sx={{display: 'flex', flexDirection: 'row', textAlign: '-webkit-center'}} >
                    {rows.map((row) => (
                      <Grid item xs={12} lg={6} sx={{ borderLeft: `solid 1px ${indigo[400]}`, px: 2, mb: 1 }}>                      
                        <TableRow key={row.id} sx={{display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'baseline'}}>
                          <TableCell sx={{ p: 0, borderBottom: 'none', width: 'inherit' }}>
                            <Typography variant="h5" sx={{ textAlign: 'left', float: 'left', color: indigo[500] }}>
                              {row.id}.
                            </Typography>
                            <Typography variant="h5" sx={{ color: indigo[500] }}>
                              {row.activity}
                            </Typography>
                          </TableCell>
                          <TableCell sx={{ p: 0, borderBottom: 'none' }}>
                            <MultipleSelectCheckmarks variants={launching.group_lists} />

                          </TableCell>
                          {/* <TableCell sx={{ p: 0, borderBottom: 'none' }}>
                            <Button variant="contained">Acessar</Button>
                          </TableCell> */}
                        </TableRow>
                      </Grid>
                      
                    ))}                        
                  </Grid>                      
                </TableBody>
              </Table>
            </TableContainer>
            <Box>
                    {/* <Button variant="contained" color="primary" onClick={addRow}>
                Adicionar linha
              </Button> */}

            </Box>
          </Box>
        </Paper>
      </Box>
    )
  // );
}

export default Attribution;