import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      light: "#556cd6",
      main: "#304BC8",
      dark: "#21348c", 
    },
    secondary: {
      light: "#556cd6",
      main: "#304BC8",
      dark: "#21348c", 
    },
    white: {
      main: "#fff",
    },
  },
  typography: {
    h1: {
      fontSize: '1.5rem', // 24px
      fontWeight: 700,
    },
    h2: {
      fontSize: '1.375rem', // 22px
      fontWeight: 600,
    },
    h3: {
      fontSize: '1.25rem', // 20px
      fontWeight: 600,
    },
    h4: {
      fontSize: '1.125rem', // 18px
      fontWeight: 500,
    },
    h5: {
      fontSize: '1rem', // 16px
      fontWeight: 500,
    },
    h6: {
      fontSize: '0.875rem', // 14px
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: '0.75rem', // 12px
      fontWeight: 400,
    },
    body1: {
      fontSize: '1rem', // 16px
      fontWeight: 400,
    },
    body2: {
      fontSize: '0.875rem', // 14px
      fontWeight: 400,
    },
    caption: {
      fontSize: '0.75rem', // 12px
      fontWeight: 400,
    },
  },
});

export default theme;