import { useQuery } from "@tanstack/react-query";
import api from "@/services/api";

function useSharedMListId(list_id) {
  return useQuery({
    queryKey: ["sharedMListsId", list_id],
    queryFn: () => api.get(`/sharedmlists/${list_id}`).then((response) => response.data),
    enabled: !!list_id
  });
}

export default useSharedMListId;
