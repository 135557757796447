import { useContext, createContext, useReducer } from "react";

// Estado inicial
const initialState = {
  step: null,
  title: null,
  componentsStatus: {
    0: { niche: false, persona: false, promise: false, event: false, list: false},
    1: { creatives: false, salesVScript: false, capPage: false, thankPage: false },
    2: { domain: false, platform: false, whatsapp: false },
    3: { notifications: false },
    4: { creativeRecs: false },
    5: { createPag: false, emailMkt: false, meta: false, whatsapp: false, test: false },
    6: { campaign: false, testCampaign: false },
    7: { traffic: false },
    8: { verify: false, deliverable: false, bonus: false, price: false, warranty: false },
    9: { script: false, recordTraining: false},
    10: { salesPage: false, meta: false, eMarketing: false },
    11: { traffic: true, otimization: false },
    12: { notifications: false },
    13: { verify: false },
    14: { traffic: true },
    15: { otimization: false },
    16: { last: true },
    17: { last: true},
  } 
};

// Ações
const actions = {
  STEP_CHANGE_VALUE: (state, action) => {
    return { ...state, step: action.payload.step, title: action.payload.title };
  },
  STEP_RESET: () => {
    return initialState;
  },
  UPDATE_COMPONENT_STATUS: (state, action) => {
    const newComponentStatus = { ...state.componentsStatus };

    for (const [mainComponent, childComponents] of Object.entries(action.payload)) {
      newComponentStatus[mainComponent] = {
        ...newComponentStatus[mainComponent],
        ...childComponents
      };
    }

    return {
      ...state,
      componentsStatus: newComponentStatus
    };
  },

};

// Contexto
const StepContext = createContext();

// Redutor
function reducer(state, action) {
  const fn = actions[action.type];

  if (!fn) {
    console.warn("Reducer", action.type, "not found.");
    return state;
  }

  return fn(state, action);
}

// Hook personalizado
export function useStep() {
  return useContext(StepContext);
}

// Provedor
export function StepProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <StepContext.Provider value={[state, dispatch]}>{children}</StepContext.Provider>
  );
}
