import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { green } from "@mui/material/colors";
import React from "react";

function TextNotificationG({text}) {
  return(
    <Grid sx={{ p: 1, 
      background: `linear-gradient(to right, ${green[300]}, ${green[500]}, ${green[300]})`,
      borderRadius: 2, mb:1,
      border: `solid 1px ${green[600]}`,
      boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'      
    }}>
      <Typography variant="h4" sx={{textAlign: 'center', color: '#fff'}}>
        {text}
      </Typography>
    </Grid>
  )
}

export default TextNotificationG