import React, { useState, useMemo, useContext, useEffect } from "react";
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import TextField from '@mui/material/TextField';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { indigo, green, red, grey } from '@mui/material/colors';
import AlertContext from '../Alert/AlertContext';
import FormControlLabel from '@mui/material/FormControlLabel';
import Divider from '@mui/material/Divider';
import Checkbox from '@mui/material/Checkbox';
import { useFieldChange } from './useFieldChange';
import api from '@/services/api';
import Button from '@mui/material/Button';
import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { useCustomQueryClient } from './hooks/useCustomQueryClient';

function Search({ handleChange, expanded, panel, path, useLauncherfyById }) {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const invalidate = useCustomQueryClient(queryClient)
  const [btnStatus, setBtnStatus] = useState(false);
  const textFieldLabelToKeyMap = useMemo(() => ({
    "Analisar os principais conteúdos (YouTube / Instagram / Facebook)": "principalContent",
    "Analisar Produtos (preço / promessa / público)": "productAnalise",
  }), []);

  const siteCheckboxToKeyMap = useMemo(() => ({
    "Rodar pesquisa no Instagram": "igseck",
    "Comentários no YouTube / Insta / Podcast": "cmytck",
    "Google Trends": "ggtdck",
    "Principais livros e comentários do nicho (Amazon)": "bkcmck",
    "Analisar depoimentos (se houver)": "ttmnck",
  }), []);

  const defaultTextLabels = useMemo(() => (["Analisar os principais conteúdos (YouTube / Instagram / Facebook)", "Analisar Produtos (preço / promessa / público)"]), []);
  const defaultSiteCheckbox = useMemo(() => (["Rodar pesquisa no Instagram", "Comentários no YouTube / Insta / Podcast", "Google Trends", "Principais livros e comentários do nicho (Amazon)", "Analisar depoimentos (se houver)"]), []);

  const { showAlert } = useContext(AlertContext);
  const { data: launching, isSuccess } = useLauncherfyById(id);

  const { handleSubmit, register, control, getValues, setValue, reset } = useForm({
    defaultValues: {
      textFieldList: defaultTextLabels.map(item => ({ item: '' })),
      siteFieldList: defaultSiteCheckbox.map(item => ({ label: item, checked: false })),
    },
  });

  const { handleFieldChange, allFieldsFilled, updateValues } = useFieldChange(getValues, setValue, setBtnStatus);

  useEffect(() => {
    const fetchData = async () => {
      if (!isSuccess || !launching) return;

      try {
        const textFieldList = defaultTextLabels.map(label => {
          return { item: launching[textFieldLabelToKeyMap[label]] || '' }; 
        });

        const siteFieldList = defaultSiteCheckbox.map(label => {
          return { label, checked: !!launching[siteCheckboxToKeyMap[label]] || false }; 
        });

        reset({ textFieldList, siteFieldList });
        setBtnStatus(false);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };

    fetchData();
    updateValues();
  }, [id, reset, launching, isSuccess, defaultSiteCheckbox, siteCheckboxToKeyMap, defaultTextLabels, textFieldLabelToKeyMap, updateValues]);


  const { fields: textFieldFields } = useFieldArray({
    control,
    name: 'textFieldList',
  });

  const { fields: siteFieldFields } = useFieldArray({
    control,
    name: 'siteFieldList',
  });

  const onSubmit = async (data) => {
    try {
      const textFieldListValues = data.textFieldList.map((field, index) => ({
        key: textFieldLabelToKeyMap[defaultTextLabels[index]],
        value: field.item
      }));
      const siteFieldListValues = data.siteFieldList.map((field, index) => ({
        key: siteCheckboxToKeyMap[defaultSiteCheckbox[index]],
        value: field.checked
      }));
      const fieldData = Object.fromEntries([...textFieldListValues, ...siteFieldListValues].map(({ key, value }) => [key, value]));
      await api.patch(`/api/${path}/${id}/`, fieldData);

      invalidate.invalidateQueries()
      setBtnStatus(false);
      showAlert("Dados atualizados com sucesso!", "success");

    } catch (error) {
      console.error("Erro ao atualizar os dados: ", error);
      showAlert("Ocorreu um erro ao tentar atualizar os dados. Por favor, tente novamente.", "error");
    }
  };

  return(
    <Accordion expanded={expanded === `panel${panel}`} onChange={handleChange(`panel${panel}`)}
      sx={{ borderRadius: 2, mb: 0.2, p: 0.5, position: 'initial', border: `solid 1px ${indigo[100]}` }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${panel}bh-content`}
        id={`panel${panel}bh-header`}
        sx={{
          borderRadius: 2,
          minHeight: 0,
          ml: 1,
          '&.Mui-expanded': {
            minHeight: 0,
          },
          '&::before': { content: '""', position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)', width: 10, height: 10, borderRadius: '50%', backgroundColor: allFieldsFilled ? green[500] : red[400] },
        }}
      >
        <Typography sx={{ width: '33%', flexShrink: 0 }}>
          Pesquisa
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 1, pt: 2 }}>
        <Box
          component="form" onSubmit={handleSubmit(onSubmit)}

        >
          <Box sx={{ backgroundColor: indigo[50], borderRadius: 2 }}>
            <Box sx={{ display: "flex", flexDirection: "row", gap: 1, backgroundColor: indigo[50], p: 1, borderRadius: 2 }} >
              <Grid container display="flex" justifyContent="center" spacing={1}>
                <Grid item xs={12} md={4} >
                  <Grid container gap={1} display="flex" direction="column" sx={{ bgcolor: "#fff", borderRadius: 2, height: '100%' }}>
                    <Typography variant="h5" sx={{ bgcolor: '#000', p: 1, borderRadius: 2, color: '#CF2CF6', fontWeight: "800" }}>Público</Typography>
                    {siteFieldFields.map((field, index) => (
                      <Box display="flex" alignItems="center" sx={{ gap: 1, px: 1 }} key={field.id}>
                        <Controller
                          name={`siteFieldList.${index}.checked`}
                          control={control}
                          defaultValue={field.checked}
                          render={({ field }) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={field.value}
                                  onChange={(e) => {
                                    field.onChange(e.target.checked);
                                    handleFieldChange(e, index, "siteFieldList");
                                    setBtnStatus(true);
                                  }}
                                  sx={{
                                    color: '#CF2CF6',
                                    '&.Mui-checked': {
                                      color: '#CF2CF6',
                                    }
                                  }}
                                />
                              }
                              label={defaultSiteCheckbox[index]}
                              sx={{
                                width: '100%'
                              }}
                            />
                          )}
                        />
                      </Box>
                    ))}
                  </Grid>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Grid container gap={1} display="flex" justifyContent="start" direction="column" alignSelf="flex-start" sx={{ bgcolor: "#fff", borderRadius: 2, height: "100%" }}>
                    <Typography variant="h5" sx={{ bgcolor: '#000', p: 1, borderRadius: 2, color: '#CF2CF6', fontWeight: "800" }}>
                      CONCORRENTES (NO MÍNIMO 3) </Typography>
                    {textFieldFields.map((field, index) => (
                      <Box alignItems="center" sx={{ gap: 1, p: 1, display: 'flex', flexDirection: 'column', px: 2 }} key={field.id}>
                        <TextField
                          {...register(`textFieldList.${index}.item`)}
                          rows={4}
                          multiline
                          variant="outlined"
                          size="small"
                          label={defaultTextLabels[index]}
                          sx={{
                            width: '100%',
                            borderRadius: '4px',
                            borderColor: grey[400],
                            font: 'inherit'
                          }}
                          onChange={(event) => {
                            handleFieldChange(event, index, "textFieldList");
                            setBtnStatus(true);
                          }}
                        />
                      </Box>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            
          </Box>
          <Divider sx={{ my: 1 }} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              gap: 1,
            }}>
            {btnStatus ?
              <Button type="submit">Salvar</Button>
              :
              <Button disabled>Salvar</Button>
            }
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
    
  )
}

export default Search