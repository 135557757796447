import { useQuery } from "@tanstack/react-query";
import api from "@/services/api";

function useSharedMList() {
  return useQuery({
    queryKey: ["sharedMLists"],
    queryFn: () => api.get(`/sharedmlists`).then((response) => response.data),
  });
}

export default useSharedMList;
