import React from "react";
import List from "@mui/material/List";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import AccountCircle from "@mui/icons-material/AccountCircle";
import ShareIcon from '@mui/icons-material/Share';
import IconButton from "@mui/material/IconButton";
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import HelpIcon from '@mui/icons-material/Help';

import { Link } from "react-router-dom";
import { indigo } from "@mui/material/colors";

function DrawerComponent({DrawerHeader, handleDrawerClose, open, Drawer}) {

  return (
    <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        <List>
          <ListItem disablePadding sx={{ display: "block" }}>
            <Divider sx={{mt:0.5}}/>  
            <ListItemButton
              component={Link}
              to="/profile"
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                '&:hover': {
                  backgroundColor: indigo[200],
                  color: '#fff'
                },
                py:1
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
              <AccountCircle sx={{ '&:hover': { color: '#fff' }, color: indigo[600] }} />
              </ListItemIcon>
              <ListItemText primary="Perfil" sx={{ opacity: open ? 1 : 0,  }} />
            </ListItemButton>
            <Divider />  
            <ListItemButton
              component={Link}
              to="/launchings"
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                '&:hover': {
                  backgroundColor: indigo[200],
                  color: '#fff'
                },
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",                  
                }}
              >
              <RocketLaunchIcon sx={{ '&:hover': { color: '#fff' }, color: indigo[600] }} />
              </ListItemIcon>
              <ListItemText
                primary="Lançamentos"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>

            <ListItemButton
              component={Link}
              to="/shared"
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                '&:hover': {
                  backgroundColor: indigo[200],
                  color: '#fff'
                },
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <ShareIcon sx={{ '&:hover': { color: '#fff' }, color: indigo[600] }} />
              </ListItemIcon>
              <ListItemText
                primary="Lançamentos compartilhados"
                sx={{ opacity: open ? 1 : 0, whiteSpace: "pre-wrap" }}
              />
            </ListItemButton>

            <ListItemButton
              component={Link}
              to="/store"
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                '&:hover': {
                  backgroundColor: indigo[200],
                  color: '#fff'
                },
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <LocalGroceryStoreIcon sx={{ '&:hover': { color: '#fff' }, color: indigo[600] }} />
              </ListItemIcon>
              <ListItemText
                primary="Planos"
                sx={{ opacity: open ? 1 : 0, whiteSpace: "pre-wrap" }}
              />
            </ListItemButton>

          <ListItemButton
            component={Link}
            to="/profile/contact"
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              '&:hover': {
                backgroundColor: indigo[200],
                color: '#fff'
              },
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <HelpIcon sx={{ '&:hover': { color: '#fff' }, color: indigo[600] }} />
            </ListItemIcon>
            <ListItemText
              primary="Contato"
              sx={{ opacity: open ? 1 : 0, whiteSpace: "pre-wrap" }}
            />
          </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
  )
}

export default DrawerComponent