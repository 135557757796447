import React, { useState, useEffect, useContext } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import { deepPurple, green, indigo, red, yellow } from '@mui/material/colors';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import api from '@/services/api';
import { useParams } from 'react-router-dom';
import AlertContext from '../Alert/AlertContext';
import { useFieldChange } from './useFieldChange';
import { useQueryClient } from '@tanstack/react-query';
import { useCustomQueryClient } from './hooks/useCustomQueryClient';

function ConfigForm({ handleChange, expanded, panel, path, useLauncherfyById }) {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const invalidate = useCustomQueryClient(queryClient)
  const [btnStatus, setBtnStatus] = useState(false);
  const { showAlert } = useContext(AlertContext);
  const { data: launching, isSuccess } = useLauncherfyById(id);

  const textFieldLabelToKeyMap = {
    "Página Facebook": "fanpageLink",
    "Link da transmissão": "youtubeLink",
    "Link do checkout": "checkoutLink",
  };

  const metaCheckboxToKeyMap = {
    "Criar fanpage": "fbfpck",
    "Criar Perfil Comercial Instagram": "fbigck",
    "Criar conta de anúncio": "fbcack",
    "Criar Pixel": "fbpxck",
  };

  const youtubeCheckboxToKeyMap = {
    "Criar canal": "ytchck",
    "Habilitar transmissão ao vivo": "ytltck",
    "Testar transmissão": "ytttck",
  };

  const platformCheckboxToKeyMap = {
    "Criar conta": "plcack",
    "Criar produto": "plcpck",
    "Verificar aprovação": "pltpck",
    "Configurar e-mail de boas vindas": "plewck",
  };

  const emailMarketingCheckboxToKeyMap = {
    "Assinar email marketing": "mmcgck",
  };

  const defaultTextLabels = ["Página Facebook", "Link da transmissão", "Link do checkout"];
  const defaultMetaCheckbox = ["Criar fanpage", "Criar Perfil Comercial Instagram", "Criar conta de anúncio", "Criar Pixel"];
  const defaultYoutubeCheckbox = ["Criar canal", "Habilitar transmissão ao vivo", "Testar transmissão"];
  const defaultPlatformCheckbox = ["Criar conta", "Criar produto", "Verificar aprovação", "Configurar e-mail de boas vindas"];
  const defaultEmailMarketingCheckbox = ["Assinar email marketing"];

  const { handleSubmit, register, control, getValues, setValue, reset } = useForm({
    defaultValues: {
      textFieldList: defaultTextLabels.map(item => ({ item: '' })),
      metaFieldList: defaultMetaCheckbox.map(item => ({ label: item, checked: false })),
      youtubeFieldList: defaultYoutubeCheckbox.map(item => ({ label: item, checked: false })),
      platformFieldList: defaultPlatformCheckbox.map(item => ({ label: item, checked: false })),
      emailMarketingFieldList: defaultEmailMarketingCheckbox.map(item => ({ label: item, checked: false })),
    },
  });
  const { handleFieldChange, allFieldsFilled, updateValues } = useFieldChange(getValues, setValue, setBtnStatus);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isSuccess && launching) {
          const settings = [
            { name: "Página Facebook", value: launching.fanpageLink },
            { name: "Link da transmissão", value: launching.youtubeLink },
            { name: "Link do checkout", value: launching.checkoutLink },
          ];

          const textFieldList = settings.map(setting => ({ item: setting.value }));
          const metaFieldList = defaultMetaCheckbox.map(label => ({ label, checked: !!launching[metaCheckboxToKeyMap[label]] }));
          const youtubeFieldList = defaultYoutubeCheckbox.map(label => ({ label, checked: !!launching[youtubeCheckboxToKeyMap[label]] }));
          const platformFieldList = defaultPlatformCheckbox.map(label => ({ label, checked: !!launching[platformCheckboxToKeyMap[label]] }));
          const emailMarketingFieldList = defaultEmailMarketingCheckbox.map(label => ({ label, checked: !!launching[emailMarketingCheckboxToKeyMap[label]] }));

          reset({ textFieldList, metaFieldList, youtubeFieldList, platformFieldList, emailMarketingFieldList });
          setBtnStatus(false);
        }
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };

    fetchData();
    updateValues();
  }, [id, reset, launching, isSuccess, updateValues]);

  const { fields: textFieldFields } = useFieldArray({
    control,
    name: 'textFieldList',
  });

  const { fields: metaFieldFields } = useFieldArray({
    control,
    name: 'metaFieldList',
  });

  const { fields: youtubeFieldFields } = useFieldArray({
    control,
    name: 'youtubeFieldList',
  });

  const { fields: platformFieldFields } = useFieldArray({
    control,
    name: 'platformFieldList',
  });

  const { fields: emailMarketingFieldFields } = useFieldArray({
    control,
    name: 'emailMarketingFieldList',
  });

  const onSubmit = async (data) => {
    try {
      const textFieldListValues = data.textFieldList.map((field, index) => ({
        key: textFieldLabelToKeyMap[defaultTextLabels[index]],
        value: field.item
      }));
      const metaFieldListValues = data.metaFieldList.map((field, index) => ({
        key: metaCheckboxToKeyMap[defaultMetaCheckbox[index]],
        value: field.checked
      }));

      const youtubeFieldListValues = data.youtubeFieldList.map((field, index) => ({
        key: youtubeCheckboxToKeyMap[defaultYoutubeCheckbox[index]],
        value: field.checked
      }));

      const platformFieldListValues = data.platformFieldList.map((field, index) => ({
        key: platformCheckboxToKeyMap[defaultPlatformCheckbox[index]],
        value: field.checked
      }));

      const emailMarketingFieldListValues = data.emailMarketingFieldList.map((field, index) => ({
        key: emailMarketingCheckboxToKeyMap[defaultEmailMarketingCheckbox[index]],
        value: field.checked
      }));

      const fieldData = Object.fromEntries([...textFieldListValues, ...metaFieldListValues, ...youtubeFieldListValues, ...platformFieldListValues, ...emailMarketingFieldListValues].map(({ key, value }) => [key, value]));

      await api.patch(`/api/${path}/${id}/`, fieldData);

      invalidate.invalidateQueries()

      setBtnStatus(false);
      showAlert("Dados atualizados com sucesso!", "success");

    } catch (error) {
      console.error("Erro ao atualizar os dados: ", error);
      showAlert("Ocorreu um erro ao tentar atualizar os dados. Por favor, tente novamente.", "error");
    }
  };


  return (
    <Accordion expanded={expanded === `panel${panel}`} onChange={handleChange(`panel${panel}`)}
      sx={{ borderRadius: 2, mb: 0.2, p: 0.5, position: 'initial', border: `solid 1px ${indigo[100]}` }}
    >

      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${panel}bh-content`}
        id={`panel${panel}bh-header`}
        sx={{
          borderRadius: 2,
          minHeight: 0,
          ml:1,
          '&.Mui-expanded': {
            minHeight: 0,
          },
          '&::before': {content: '""', position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)', width: 10, height: 10, borderRadius: '50%', backgroundColor: allFieldsFilled ? green[500] : red[400]},
        }}
      >
        <Typography sx={{ width: '33%', flexShrink: 0 }}>
          Plataforma
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 1, pt: 2 }}>
        <Box
          component="form" onSubmit={handleSubmit(onSubmit)}
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: 1,
          }}>
          <Box sx={{ display: "flex", flexDirection: "row", alignSelf: "center", gap: 1, backgroundColor: indigo[50], p: 2, borderRadius: 2 }} >
            <Grid container display="flex" justifyContent="center" spacing={1}>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Grid container gap={1} display="flex" direction="column" sx={{ bgcolor: indigo[100], borderRadius: 2, height: '100%' }}>
                  <Typography variant="h5" sx={{ bgcolor: '#4267B2', p: 1, borderRadius: 2, color: '#fff' }}>Meta</Typography>
                  {metaFieldFields.map((field, index) => (
                    <Box display="flex" alignItems="center" sx={{ gap: 1, px: 1 }} key={field.id}>
                      <Controller
                        name={`metaFieldList.${index}.checked`}
                        control={control}
                        defaultValue={field.checked}
                        render={({ field }) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={field.value}
                                onChange={(e) => {
                                  field.onChange(e.target.checked);
                                  handleFieldChange(e, index, "metaFieldList");
                                  setBtnStatus(true);
                                }}
                                sx={{
                                  color: '#4267B2',
                                  '&.Mui-checked': {
                                    color: '#4267B2',
                                  }
                                }}
                              />
                            }
                            label={defaultMetaCheckbox[index]}
                            sx={{
                              width: '100%'
                            }}
                          />
                        )}
                      />
                    </Box>
                  ))}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Grid container gap={1} display="flex" direction="column" sx={{ bgcolor: red[50], borderRadius: 2, height: '100%' }}>
                  <Typography variant="h5" sx={{ bgcolor: '#ff0000', p: 1, borderRadius: 2, color: '#fff' }}>Youtube</Typography>
                  {youtubeFieldFields.map((field, index) => (
                    <Box display="flex" alignItems="center" sx={{ gap: 1, px: 1 }} key={field.id}>
                      <Controller
                        name={`youtubeFieldList.${index}.checked`}
                        control={control}
                        defaultValue={field.checked}
                        render={({ field }) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={field.value}
                                onChange={(e) => {
                                  field.onChange(e.target.checked);
                                  handleFieldChange(e, index, "youtubeFieldList");
                                  setBtnStatus(true);
                                }}
                                sx={{
                                  color: '#ff0000',
                                  '&.Mui-checked': {
                                    color: '#ff0000',
                                  },
                                }}
                              />
                            }
                            label={defaultYoutubeCheckbox[index]}
                            sx={{ width: '100%' }}
                          />
                        )}
                      />
                    </Box>
                  ))}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Grid container gap={1} display="flex" direction="column" sx={{ bgcolor: yellow[50], borderRadius: 2, height: '100%' }}>
                  <Typography variant="h5" sx={{ bgcolor: '#FACA15', p: 1, borderRadius: 2, color: '#fff' }}>
                    Hotmart / Eduzz / Kiwify / ...</Typography>
                  {platformFieldFields.map((field, index) => (
                    <Box display="flex" alignItems="center" sx={{ gap: 1, px: 1 }} key={field.id}>
                      <Controller
                        name={`platformFieldList.${index}.checked`}
                        control={control}
                        defaultValue={field.checked}
                        render={({ field }) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={field.value}
                                onChange={(e) => {
                                  field.onChange(e.target.checked);
                                  handleFieldChange(e, index, "platformFieldList");
                                  setBtnStatus(true);
                                }}
                                sx={{
                                  color: '#FACA15',
                                  '&.Mui-checked': {
                                    color: '#FACA15',
                                  },
                                }}
                              />
                            }
                            label={defaultPlatformCheckbox[index]}
                            sx={{ width: '100%' }}
                          />
                        )}
                      />
                    </Box>
                  ))}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Grid container gap={1} display="flex" direction="column" sx={{ bgcolor: deepPurple[50], borderRadius: 2, height: '100%' }}>
                  <Typography variant="h5" sx={{ bgcolor: '#9061F9', p: 1, borderRadius: 2, color: '#fff' }}>
                    MailChimp, Clicksend, ActiveCampaign</Typography>
                  {emailMarketingFieldFields.map((field, index) => (
                    <Box display="flex" alignItems="center" sx={{ gap: 1, px: 1 }} key={field.id}>
                      <Controller
                        name={`emailMarketingFieldList.${index}.checked`}
                        control={control}
                        defaultValue={field.checked}
                        render={({ field }) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={field.value}
                                onChange={(e) => {
                                  field.onChange(e.target.checked);
                                  handleFieldChange(e, index, "emailMarketingFieldList");
                                  setBtnStatus(true);
                                }}
                                sx={{
                                  color: '#9061F9',
                                  '&.Mui-checked': {
                                    color: '#9061F9',
                                  },
                                }}
                              />
                            }
                            label={defaultEmailMarketingCheckbox[index]}
                            sx={{ width: '100%' }}
                          />
                        )}
                      />
                    </Box>
                  ))}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Grid container gap={1} display="flex" justifyContent="center" direction="column" alignSelf="flex-start" sx={{ bgcolor: indigo[100], borderRadius: 2 }}>
                  <Typography variant="h5" sx={{ bgcolor: indigo[600], p: 1, borderRadius: 2, color: '#fff' }}>
                    Links </Typography>
                  {textFieldFields.map((field, index) => (
                    <Box display="flex" alignItems="center" sx={{ gap: 1, p:1 }} key={field.id}>
                      <TextField
                        {...register(`textFieldList.${index}.item`)}
                        label={defaultTextLabels[index]}
                        defaultValue={field.value}
                        variant="outlined"
                        size="small"
                        sx={{
                          width: '100%', backgroundColor: '#fff', borderRadius: 2,
                          '& label.MuiInputLabel-shrink': {
                            backgroundColor: indigo[100],
                            borderRadius: 3, px: 0.8,
                          } }}
                        onChange={(event) => {
                          handleFieldChange(event, index, "textFieldList");
                          setBtnStatus(true);
                        }}
                      />
                    </Box>
                  ))}
                </Grid>
              </Grid>
            </Grid>         
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              gap: 1,
            }}>
            {btnStatus ?
              <Button type="submit">Salvar</Button>
            :
              <Button disabled>Salvar</Button>
            }
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

export default ConfigForm;
