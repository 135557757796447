import { useState, useEffect, useCallback } from 'react';

export const useFieldChangeEsp = (getValues, setValue, setBtnStatus, fieldName) => {
  const [totalCharacterCount, setTotalCharacterCount] = useState(0);
  const [allFieldsFilled, setAllFieldsFilled] = useState(false);

  const updateValues = useCallback(() => {
    const updatedValues = getValues();
    let updatedTextFieldValues = Object.values(updatedValues)[0].map(field => field[fieldName]);
    const allUndefined = updatedTextFieldValues.every(val => val === undefined);

    if (allUndefined) {
      if (updatedValues.creativeRecs) {
        updatedTextFieldValues = updatedValues.creativeRecs.map(field => Object.values(field));
      } else if (updatedValues.launchingScript) {
        updatedTextFieldValues = updatedValues.launchingScript.map(field => Object.values(field));
      }
    }

    const totalCharCount = JSON.stringify(updatedTextFieldValues).length;
    setTotalCharacterCount(totalCharCount);

    if (Array.isArray(updatedTextFieldValues[0])) {
      const filledFields = updatedTextFieldValues.every(subArr => {
        const checkboxValue = subArr.some(value => typeof value === 'boolean' && value === true);
        const textValue = subArr.some(value => typeof value === 'string' && value.trim().length > 0);

        return checkboxValue || textValue;
      });

      setAllFieldsFilled(filledFields && updatedTextFieldValues.length > 0);
    } else {
      const filledFields = updatedTextFieldValues.every(value => {
        if (typeof value === 'boolean') {
          return value === true;
        }
        if (typeof value === 'string') {
          return value.trim().length > 0;
        }
        return false;
      });
      setAllFieldsFilled(filledFields);
    }    

  }, [getValues, fieldName]);

  const handleFieldChange = useCallback((fieldPath, value) => {
    setValue(fieldPath, value);
    setBtnStatus(true);
    updateValues();
  }, [setValue, setBtnStatus, updateValues]);

  useEffect(() => {
    updateValues();
  }, [updateValues]);

  return { handleFieldChange, totalCharacterCount, allFieldsFilled };
}
