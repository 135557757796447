import React, { useMemo } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import useLaunchingList from "../../Launchings/queries/useLaunchingList";
import useOrganization from "@/queries/useOrganization";
import { indigo } from "@mui/material/colors";
import Subtitle from "@/components/Subtitle";

function LaunchingContent() {
  const fetchOrganization = useOrganization();
  const org = useMemo(() => {
    if (fetchOrganization.isSuccess) {
      return fetchOrganization.data[0];
    }
    return 0;
  }, [fetchOrganization]); 



  const fetchLaunchingCount = useLaunchingList(org.id);

  const total = useMemo(() => {
    if (fetchLaunchingCount.isSuccess) {
      return fetchLaunchingCount.data.length;
    }
    return 0;
  }, [fetchLaunchingCount]);

  const completed = useMemo(() => {
    if (fetchLaunchingCount.isSuccess) {
      return fetchLaunchingCount.data.filter(data => data.progress === "100").length;
    }
    return 0;
  }, [fetchLaunchingCount]);

  return (
    <Box>
      <Subtitle name="Lançamentos" />
      <Box sx={{ alignSelf: { xs: 'center', md: 'auto' }, p: 2 }}>
        <Box sx={{textAlign: { xs: 'center', md: 'left' }}}>
          {fetchLaunchingCount.isLoading ? (
            <Skeleton width={100} />
          ) : (
              <Typography variant="h5"><b style={{ color: indigo[700] }}>Total:</b> {total}</Typography>
          )}
        </Box>
        <Box sx={{ textAlign: { xs: 'center', md: 'left' } }}>
          {fetchLaunchingCount.isLoading ? (
            <Skeleton width={120} />
          ) : (
              <Typography variant="h5"><b style={{ color: indigo[700] }}>Finalizados:</b> {completed}</Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default LaunchingContent;
