import { useContext, createContext, useReducer } from "react";

const initialState = {
  uid: null,
  logged: false,
  name: "",
  id: "",
  email: "",
  image: "",
  subscriptionStatus: null,
  nextInvoice: null,
  lastPayment: null,
  trialEndDate: null,
  hasAccess: false,
  cancelAtPeriodEnd: false,
  emailVerified: false,
  support: false,
};

const actions = {
  USER_CHANGE_VALUE: (state, action) => {
    return { ...state, ...action.payload };
  },
  USER_RESET: () => {
    return initialState;
  },
};

const UserContext = createContext();

function reducer(state, action) {
  const fn = actions[action.type];

  if (!fn) {
    console.warn("Reducer", action.type, "not found.");
    return state;
  }

  return fn(state, action);
}

export function useUser() {
  return useContext(UserContext);
}

export function UserProvider({ children }) {
  const provider = useProviderUser();

  return (
    <UserContext.Provider value={provider}>{children}</UserContext.Provider>
  );
}

function useProviderUser() {
  const [state, dispatch] = useReducer(reducer, initialState);

  return [state, dispatch];
}
