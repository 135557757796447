import React, { useContext } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Modal from "@mui/material/Modal";
import Title from "@/components/Title";
import moment from 'moment'
import { useUser } from "@/reducers/user";
import { red } from "@mui/material/colors";
import AlertContext from '@/components/Alert/AlertContext';
import api from '@/services/api';

function ModalDelete() {
  const [user,  setUser] = useUser();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const inputDate = user.nextInvoice;
  const now = moment().startOf('day');
  const target = moment(inputDate).startOf('day');
  const daysUntil = target.diff(now, 'days');

  const { showAlert } = useContext(AlertContext);
  const [, forceUpdate] = React.useReducer(x => x + 1, 0);

  const cancelSubscription = async (event) => {
    event.preventDefault();

    try {
      const data = {
        userEmail: user.email,
      };

      const response = await api.post(`${process.env.REACT_APP_API_URL}/cancel-subscription/`, data);

      if (response.data.message) {
        const results = await api.get("/users").then((response) => response.data);
        setUser({
          type: "USER_CHANGE_VALUE",
          payload: {
            subscriptionStatus: results.subscription_status,
            nextInvoice: results.next_invoice_date,
            lastPayment: results.last_payment_date,
            trialEndDate: results.trial_end_date,
            hasAccess: results.has_access,
            cancelAtPeriodEnd: results.cancel_at_period_end,
          },
        });

        forceUpdate();
        handleClose()
        showAlert(`${response.data.message}. Atualize a página caso as informações não tenham mudado`, "success");
        return;
      }
    } catch (error) {
      console.warn('Error:', error);
      if (error.response && error.response.data) {
        showAlert(error.response.data.error || "Erro ao cancelar a assinatura.", "error");
      } else {
        showAlert("Erro ao cancelar a assinatura.", "error");
      }
    }
  };

  return (
    <Box sx={{ borderRadius: 2 }}>
      {user?.hasAccess &&
        <Button
          size="small"
          variant="outlined" 
          color="error"          
          onClick={handleOpen}
        >
          Cancelar plano
        </Button>
      }
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            width: 400,
            bgcolor: "background.paper",
            margin: "auto",
            marginTop: "10%",
            outline: "none",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            borderRadius: 3,
            boxShadow:
              "rgb(255 255 255 / 10 %) 0px 1px 24px, rgb(255 255 255 / 9 %) 0px 15px 12px",
          }}
        >
          <Title name="Cancelar plano" colorName="red" />
          <Box sx={{ padding: 2, gap: 1, display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                gap: 1,
              }}
            >
              <Box>
                <Typography >
                  Tem certeza que deseja cancelar o plano ?
                </Typography>
                <Typography >
                  Você <b style={{color: red[600]}}>não</b> terá mais nenhuma cobrança.
                </Typography>
                <Typography >
                  Você ainda terá <b style={{ color: red[600] }}>{daysUntil} dias</b> de acesso.
                </Typography>
              </Box>        
              <Divider/>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Button size="small"  variant="outlined" color="error" 
                onClick={handleClose}
                >
                  Fechar
                </Button>
                <Button size="small" variant="contained" color="error" onClick={cancelSubscription}>
                  Cancelar Plano
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default ModalDelete;
