import { useQuery } from "@tanstack/react-query";
import api from "@/services/api";

function useSharedFListId(list_id) {
  return useQuery({
    queryKey: ["sharedFListsId", list_id],
    queryFn: () => api.get(`/sharedflists/${list_id}`).then((response) => response.data),
    enabled: !!list_id
  });
}

export default useSharedFListId;
