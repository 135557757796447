import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import api from "@/services/api";

function useFormulaMutation() {
  const navigate = useNavigate();
  
  return useMutation(
    async ({ data }) => {
      const response = await api.post("/api/formulalaunchings/", data);
      return response.data;      
    },
    {
      onSuccess: (data) => {
        const { id } = data;
        navigate(`/launchings/formula/${id}`);
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );
}

export default useFormulaMutation;
