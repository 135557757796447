import { useState, useEffect, useCallback } from 'react';

export const useFieldCheckChange = (getValues, setValue, setBtnStatus, fieldName) => {
  const [totalCharacterCount, setTotalCharacterCount] = useState(0);
  const [allFieldsFilled, setAllFieldsFilled] = useState(false);

  const updateValues = useCallback(() => {
    const updatedValues = getValues();
    const updatedTextFieldValues = Object.values(updatedValues).map(field => field) || [];
    const totalCharCount = JSON.stringify(updatedTextFieldValues).length;
    setTotalCharacterCount(totalCharCount);
    const filledFields = updatedTextFieldValues.every(
      value => (typeof value === 'string' && value.trim().length > 0) || value === true
    );
    setAllFieldsFilled(filledFields);
  }, [getValues]);

  const handleFieldChange = useCallback((fieldPath, value) => {
    setValue(fieldPath, value);
    setBtnStatus(true);
    updateValues();
  }, [setValue, setBtnStatus, updateValues]);

  useEffect(() => {
    updateValues();
  }, [updateValues]);

  return { handleFieldChange, totalCharacterCount, allFieldsFilled, updateValues };
}
