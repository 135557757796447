import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CardMedia from '@mui/material/CardMedia';
import Divider from '@mui/material/Divider';
import Title from '@/components/Title';
import { indigo } from '@mui/material/colors';

export default function ModalSeed() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen}>Detalhes</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            maxWidth: 900,
            bgcolor: "background.paper",
            margin: "auto",
            marginTop: "10%",
            outline: "none",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            borderRadius: 3,
            boxShadow:
              "rgb(255 255 255 / 10 %) 0px 1px 24px, rgb(255 255 255 / 9 %) 0px 15px 12px",
            heigh: '70vh'
          }}
        >
          <Title name="Lançamento base"/>
          <Box sx={{ padding: 2, gap: 1, display: "flex", flexDirection: "column", height: '70vh', overflow: 'auto'}}>
            <Typography variant="h4" textAlign="center">
              No plano semente você tem acesso a um roteiro automatizado baseado no lançamento semente.
            </Typography>
            <Typography variant="h5" textAlign="center">
              O plano contém todas as tarefas necessárias para realizar um lançamento de sucesso.
            </Typography>
            <Typography variant="h6" color="error" textAlign="center">
              🚨 Atenção: Complemento ao Método, Não Substituição! 🚨
            </Typography> 
            <Typography variant="h5" color="text.secondary" textAlign="center">
              O Plano semente foi desenvolvido para otimizar e facilitar a execução do Fórmula de lançamento. No entanto, é essencial entender que nossa plataforma não substitui o Fórmula em si.
            </Typography>
            <Typography variant="h5" color="text.secondary" textAlign="center">
              Ao usar o plano semente, você estará aprimorando sua experiência e eficácia ao aplicar o Fórmula de lançamento, mas a fundamentação e as práticas essenciais do método original devem continuar sendo seguidas para garantir o sucesso desejado.       
            </Typography>
            <Divider />
            <CardMedia sx={{ borderRadius: 2, border: `solid 1px ${indigo[300]}` }}
              component="img"
              image="/formula.png"
              alt="plataforma"
            />
            <Divider/>
            <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
              <Button variant="outlined" onClick={handleClose}>
                Fechar
              </Button>
            </Box>
          </Box>  
        </Box>
      </Modal>
    </div>
  );
}