import React, {useMemo} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as colors from "@mui/material/colors";

function Title({ name, colorName }) {
  const color = useMemo(() => colors[colorName] || colors["indigo"], [colorName]);
  return (
    <Box
      sx={{
        padding: "16px 32px",
        background: `linear-gradient(to right, ${color[600]}, ${color[800]})`,
        borderRadius: "10px 10px 0 20px",
      }}
    >
      <Typography variant="h4" sx={{ color: "#ffffff"}}>
        {name}
      </Typography>
    </Box>
  );
}

export default Title;
