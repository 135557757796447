import React, {useContext} from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from '@mui/material/IconButton';
import { indigo } from "@mui/material/colors";
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import Title from '@/components/Title';
import moment from 'moment'
import { useParams } from "react-router-dom";
import AlertContext from '@/components/Alert/AlertContext';
import api from '@/services/api';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  backgroundColor: '#fff',
  borderRadius: 3,
  boxShadow: 24,
};

function EditLaunching({launching}) {
  const { showAlert } = useContext(AlertContext);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { option, id } = useParams();
  let path

  if (option === "launchings") {
    path = "launcherfylaunchings"
  } else {
    path = "launcherfyshared"
  }

  const schema = yup.object().shape({
    name: yup.string().required("Nome é obrigatório"),
    launchDate: yup
      .date()
      .required("Data é obrigatória"),
  });
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    data.launchDate = moment(data.launchDate).format('YYYY-MM-DD');

    let value = {
      launchDate: data.launchDate,
      name: data.name
    }

    try {
      await api.patch(`/api/${path}/${id}/`, value);
      showAlert("Dados atualizados com sucesso!", "success");

    } catch (error) {
      console.error("Erro ao atualizar os dados: ", error);
      showAlert("Ocorreu um erro ao tentar atualizar os dados. Por favor, tente novamente.", "error");
    }
    handleClose();
  };

  return(
    <>
      <IconButton sx={{ ml: 1, padding: 0, '&:hover': { backgroundColor: indigo[50], padding: 0 } }} onClick={handleOpen}>
        <EditIcon sx={{ color: indigo[500], '&:hover': { color: indigo[900] } }} />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Title name="Editar Lançamento" />
          <Box sx={{display: 'flex', flexDirection: 'column', gap: 2 , p:2}}>
            <TextField
              size="small"
              label="Nome do Lançamento"
              {...register("name")}
              error={!!errors.name}
              helperText={errors.name?.message}
              fullWidth
              defaultValue={launching.name}
            />
            <TextField
              label="Data"
              type="date"
              size="small"
              defaultValue={launching.launchDate}
              {...register("launchDate")}
              error={!!errors.launchDate}
              helperText={errors.launchDate?.message}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </Box>
          <Divider/>
          <Box sx={{ gap: 1, p: 2, display: "flex", alignItems: "right", justifyContent: 'space-between' }} >
            <Button
              onClick={handleClose}
              variant="outlined">
              Sair
            </Button>
            <Button variant="contained" 
            onClick={handleSubmit(onSubmit)}
            >
              Salvar
            </Button>
          </Box> 
        </Box>
      </Modal>
    </>
  )
}

export default EditLaunching