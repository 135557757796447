import React from "react";
import Router from "./Router";
import useAuth from "./hooks/useAuth";
import SplashScreen from "./components/SplashScreen";

function App() {
  const { loading } = useAuth();

  if (loading) {
    return <SplashScreen />;
  }

  return <Router />;
}

export default App;
