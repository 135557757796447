import { useQuery } from "@tanstack/react-query";
import api from "@/services/api";

function useSharedFList() {
  return useQuery({
    queryKey: ["sharedFLists"],
    queryFn: () => api.get(`/sharedflists`).then((response) => response.data),
  });
}

export default useSharedFList;
