import React, { useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import { green, grey, indigo, orange, red } from '@mui/material/colors';
import api from '@/services/api';
import { useParams } from 'react-router-dom';
import AlertContext from '../Alert/AlertContext';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import InputAdornment from '@mui/material/InputAdornment';
import { useCustomQueryClient } from './hooks/useCustomQueryClient';
import { useQueryClient } from '@tanstack/react-query';

const initialRows = [
  {
    id: 1,
    plat: 'Meta',
    leads: "0",
    custPerLead: '0',
    investment: '0',
  },
  {
    id: 2,
    plat: 'Google',
    leads: "0",
    custPerLead: '0',
    investment: '0',
  }
];

function PastTrafficTable({ handleChange, expanded, panel, path, useLauncherfyById }) {
  const { id } = useParams();
  const [btnStatus, setBtnStatus] = useState(false);
  const { showAlert } = useContext(AlertContext);
  const { data: launching, isSuccess } = useLauncherfyById(id);
  const queryClient = useQueryClient();
  const invalidate = useCustomQueryClient(queryClient)

  const { handleSubmit } = useForm({
    defaultValues: {
      traffic: []
    },
  });

  const onSubmit = async () => {
    try {
      const combinedString = JSON.stringify(rows);

      await api.patch(`/api/${path}/${id}/`, {
        lastTraffic: combinedString,
      });

      invalidate.invalidateQueries()
      setBtnStatus(false);
      showAlert("Dados atualizados com sucesso!", "success");

    } catch (error) {
      console.error("Erro ao atualizar os dados: ", error);
      showAlert("Ocorreu um erro ao tentar atualizar os dados. Por favor, tente novamente.", "error");
    }
  };

  const [rows, setRows] = useState(initialRows);

  const [totalCharacterCount, setTotalCharacterCount] = useState(0);
  const [allFieldsFilled, setAllFieldsFilled] = useState(false);

  const handleChangeRow = (event, id) => {
    let name = event.target.name;
    let value = event.target.value;

    if (name === "investment") {
      value = value.replace(',', '.');
    }

    setRows(prevState => {
      return prevState.map(row =>
        row.id === id ? { ...row, [name]: value } : row
      );
    });
    const totalCharCount = JSON.stringify(rows).length;
    setTotalCharacterCount(totalCharCount)

    setBtnStatus(true)
    setAllFieldsFilled(rows.every(row =>
      Object.values(row).every(value => value !== '')
    ))
  };

  useEffect(() => {
    setTotalCharacterCount(JSON.stringify(rows).length);
    setAllFieldsFilled(rows.every(row =>
      Object.values(row).every(value => value !== '')
    ));
  }, [rows]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isSuccess && launching) {
          const trafficData = launching.lastTraffic || '';
          // console.log(trafficData)
          if (trafficData !== '') {
            const parsedTrafficData = JSON.parse(trafficData);
            setRows(parsedTrafficData);
            setTotalCharacterCount(JSON.stringify(parsedTrafficData).length);
            setAllFieldsFilled(parsedTrafficData.every(row =>
              Object.values(row).every(value => value !== '')
            ));
          }
          setBtnStatus(false);
        }
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };
    fetchData();
  }, [id, launching, isSuccess]);

  const calculateTotals = () => {
    let totalInvestment = 0;
    let totalLeads = 0;
    let totalCostPerLead = 0;

    rows.forEach(row => {
      totalInvestment += Number(row.investment || 0);
      totalLeads += Number(row.leads || 0);
      if (row.investment && row.leads) {
        totalCostPerLead += Number(row.investment) / Number(row.leads);
      }
    });

    return {
      totalInvestment,
      totalLeads,
      totalCostPerLead,
    };
  };

  return (
    <Accordion expanded={expanded === `panel${panel}`} onChange={handleChange(`panel${panel}`)}
      sx={{ 
        borderRadius: 2, mb: 0.2, p: 0.5, position: 'initial', border: `solid 1px ${indigo[100]}`, 
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${panel}bh-content`}
        id={`panel${panel}bh-header`}
        sx={{
          borderRadius: 2,
          minHeight: 0,
          ml:1,
          overflow: 'hidden',
          '&.Mui-expanded': {
            minHeight: 0,
          },
          '&::before': {content: '""', position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)', width: 10, height: 10, borderRadius: '50%', backgroundColor: allFieldsFilled ? green[500] : red[400]},
        }}
      >
        <Typography sx={{ flexShrink: 0 }}>
          Números do lançamento anterior
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 1, pt: 2}}>
        <Box
          component="form" onSubmit={handleSubmit(onSubmit)}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}>
            <Box
              component="div"
              sx={{
                overflow: 'auto',
                my: 0,
                p: 1,
                bgcolor: (theme) =>
                  theme.palette.mode === 'dark' ? '#101010' : indigo[50],
                color: (theme) =>
                  theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
                border: '1px solid',
                borderColor: (theme) =>
                  theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                borderRadius: 2,
                fontSize: '0.875rem',
                fontWeight: '700',
                display: 'flex',
                flexDirection: 'column',
                gap: 1
              }}
            >
              <TableContainer
                component={Paper}
                sx={{ p:1, pb:2, width: '100%' }}
              >
                <Table aria-label="simple table" sx={{ width: 'max-content' }}>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ p: 1, borderBottom: 'none', textAlign: 'center'}}></TableCell>
                      <TableCell sx={{ p: 1, borderBottom: 'none', textAlign: 'center' }}>INVESTIMENTO</TableCell>
                      <TableCell sx={{ p: 1, borderBottom: 'none', textAlign: 'center' }}>NÚMERO DE LEADS</TableCell>
                      <TableCell sx={{ p: 1, borderBottom: 'none', textAlign: 'center' }}>CUSTO POR LEAD</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell sx={{p:0, borderBottom: 'none', pr:1}}>
                        <Typography variant="h5" sx={{textAlign: "right"}}>
                         Total: 
                        </Typography> 
                      </TableCell>
                      <TableCell sx={{p:0, borderBottom: 'none'}}>
                        <TextField
                          sx={{
                            backgroundColor: indigo[100],
                            borderRadius: 2,
                          }}
                          disabled
                          size='small'
                          value={calculateTotals().totalInvestment.toFixed(2)}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                          }}
                        />
                      </TableCell>
                      <TableCell sx={{p:0, borderBottom: 'none'}}>
                        <TextField
                        sx={{
                          backgroundColor: indigo[100],
                          borderRadius: 2,
                        }}
                          disabled
                          size='small'
                        value={calculateTotals().totalLeads}
                        />
                      </TableCell>
                      <TableCell sx={{p:0, borderBottom: 'none'}}>
                        <TextField
                          sx={{
                            backgroundColor: indigo[100],
                            borderRadius: 2,
                          }}
                          disabled
                          size='small'
                        value={`R$ ${calculateTotals().totalCostPerLead.toFixed(2)}`}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <Divider sx={{my:1, border: 'none'}}/>
                    </TableRow>
                    {rows.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell sx={{p:0, borderBottom: 'none'}}>
                          <TextField
                            sx={{
                              backgroundColor: grey[200],
                              borderRadius: 2,
                            }}
                            size='small'
                            name="plataforma"
                            value={row.plat}
                            onChange={(event) => handleChangeRow(event, row.id)}
                            placeholder='01/01/2023'
                          />
                        </TableCell>
                        <TableCell sx={{p:0, borderBottom: 'none' }}>
                          <TextField
                            size='small'
                            name="investment"
                            value={row.investment}
                            onChange={(event) => handleChangeRow(event, row.id)}
                            InputProps={{
                              startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                            }}
                          />
                        </TableCell>
                        <TableCell sx={{p:0, borderBottom: 'none'}}>
                          <TextField
                            size='small'
                            name="leads"
                            value={row.leads}
                            onChange={(event) => handleChangeRow(event, row.id)}
                          />
                        </TableCell> 
                        <TableCell sx={{p:0, borderBottom: 'none'}}>
                          <TextField
                            size='small'
                            name="costPerLead"
                            disabled
                            value={`R$ ${(row.investment / row.leads).toFixed(2)}`}
                          />
                        </TableCell>
            
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              
            </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              gap: 1,
            }}>
            {btnStatus ?
              <Button type="submit">Salvar</Button>
              :
              <Button disabled>Salvar</Button>
            }
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

export default PastTrafficTable;
