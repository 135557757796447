import React from "react";
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { useStep } from '@/reducers/step'
import { useParams, useLocation, useNavigate } from "react-router-dom";

function TimelineItem({ bgcolor, item, index, colors, isPastDate, numCreationCards }) {
  const [step, setStep] = useStep(); 
  const { option, id } = useParams();
  const location = useLocation();
  const pathSegment = location.pathname.split('/')[2];
  const navigate = useNavigate();

  let allTrue = false
  
  if (step?.componentsStatus[index - numCreationCards]) {
    allTrue = Object.values(step.componentsStatus[index - numCreationCards]).every(value => value === true);
  }

  const handleClick = () => {
    if (item?.type === "debriefing") {
      const newPath = `/${option}/${pathSegment}/${id}/debriefing`;
      navigate(newPath, { replace: true });
    }

    setStep({ 
      type: "STEP_CHANGE_VALUE", 
      payload: {
        step: index - numCreationCards,
        title: item.title
      },
   }); 
  };

  return(
    <ListItem
      key={`TimelineItem_${index}`}
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        width: "115px",
        p: 1,
        bgcolor: index < numCreationCards ? isPastDate ? colors['green'][100] : bgcolor : isPastDate && allTrue ? colors['green'][100] : isPastDate ? colors['red'][100] : bgcolor,
        borderRadius: '8px 0px',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '-3px',
          left: 5,
          zIndex: 2,
          height: 9,
          width: 9,
          borderRadius: '50%',
          bgcolor: index < numCreationCards ? isPastDate ? colors['green'][500] : colors.grey[700] : isPastDate && allTrue ? colors['green'][500] : isPastDate ? colors['red'][500] : colors.grey[700]
        }}
      />
      <Typography variant="body2" color={item?.color ? item.color : colors.indigo[500]} sx={{ mt: 0 }}>{item.date}</Typography>
      <Box sx={{ ml: 0, mt: 0, pb: 0 }}>
        <Typography variant="h6" color={item?.color ? item.color : colors.indigo[800]} sx={{ mb: 0, textAlign: 'center' }}>{item.title}</Typography>
        {item.title2 && <Divider/> }
        <Typography variant="h6" color={item?.color ? item.color : colors.indigo[800]} sx={{ mb: 0, textAlign: 'center' }}>{item.title2}</Typography>
        {item?.description && 
        <Button variant="contained" sx={{ p: 0.2, px: 1, fontSize: 12 }} onClick={handleClick}>
          {item.description}
        </Button>}
      </Box>
    </ListItem>
  )
}

export default TimelineItem