import React, {useMemo} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import FoundationIcon from "@mui/icons-material/Foundation";
import * as colors from "@mui/material/colors";
import LinearProgress from '@mui/material/LinearProgress';

function CardStats({
  date,
  statTitle,
  StatIconComponent,
  colorName,
  bgColorName,
  progressValue,
}) {
  const color = useMemo(() => colors[colorName] || colors["indigo"], [colorName]);
  // const bgColor = useMemo(() => colors[bgColorName] || '#fff', [bgColorName]);

  const progressColor = useMemo(() => {
    if (progressValue <= 25) {
      return 'red'
    }

    if (progressValue <= 50) {
      return '#FF8C00'
    }

    if (progressValue <= 75) {
      return '#ffdf00'
    }

    return '#00a400'
  }, [progressValue]);

  const progressColorDarker = useMemo(() => {
    if (progressValue <= 25) {
      return 'darkred'
    }

    if (progressValue <= 50) {
      return '#8B4513'
    }

    if (progressValue <= 75) {
      return 'goldenrod'
    }

    return '#005F00'
  }, [progressValue]);


  return (
    <Card sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', overflow: 'hidden', my: 0, boxShadow: 2, border: `1px solid ${color[400]}`, width: '100%' }}>
      <CardContent sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', p: 2 }}>
        <StatIconComponent sx={{ fontSize: 48, border: `2px solid ${color[500]}`, padding: '8px', borderRadius: '50%', color: `${color[500]}`, background: '#fff' }} />
        <Box sx={{ pl: 2, overflow: 'hidden', flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <Typography variant="h4" component="div" noWrap sx={{ color: color[800] }}>
            {statTitle}
          </Typography>
          <Typography variant="subtitle2" color="text.secondary" gutterBottom>
            {date}
          </Typography>
        </Box>
      </CardContent>
      <LinearProgress variant="determinate" value={parseInt(progressValue)}
        sx={{
          bgcolor: progressColorDarker,
          '& .MuiLinearProgress-bar': {
            bgcolor: progressColor,
          }
        }}
      />    
    </Card>
  );
}

CardStats.defaultProps = {
  statTitle: "",
  date: "--/--/----",
  StatIconComponent: FoundationIcon,
  progressValue: 20,
};

export default CardStats
