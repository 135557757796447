import React from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { blue, pink } from "@mui/material/colors";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { indigo, green, red } from '@mui/material/colors';

function Approach({handleChange, expanded, panel, }) {

  const allFieldsFilled = true

  return(
    <Accordion expanded={expanded === `panel${panel}`} onChange={handleChange(`panel${panel}`)}
      sx={{ borderRadius: 2, mb: 0.2, p: 0.5, position: 'initial', border: `solid 1px ${indigo[100]}` }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${panel}bh-content`}
        id={`panel${panel}bh-header`}
        sx={{
          borderRadius: 2,
          minHeight: 0,
          ml: 1,
          '&.Mui-expanded': {
            minHeight: 0,
          },
          '&::before': { content: '""', position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)', width: 10, height: 10, borderRadius: '50%', backgroundColor: allFieldsFilled ? green[500] : red[400] },
        }}
      >
        <Typography sx={{ width: '33%', flexShrink: 0 }}>
          Abordagem
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 1, pt: 2 }}>
        <Box sx={{ backgroundColor: indigo[50], p: 1, borderRadius: 2 }}>
          <Typography variant="h5" sx={{ borderRadius: 2, backgroundColor: '#fff', border: `2px solid #CF2CF6`, p: 0.6, px: 2, m: 1, textAlign: 'center' }} >LANÇADOR ABORDANDO EXPERT</Typography>
          <Grid container>
            <Grid item xs={12} sx={{ textAlign: 'center'}}>
              <Typography variant="h5" sx={{ borderRadius: 2, color: "#CF2CF6", p: 0.4, px: 1, m: 1, textTransform: 'uppercase' }} >ESTUDO DO ESPECIALISTA</Typography>
              <Typography variant="h5" sx={{ borderRadius: 2, px: 1, m: 1 }} >Identificar o que o move (dinheiro, fama, família, reconhecimento)</Typography>
              <Typography variant="h5" sx={{ borderRadius: 2, px: 1, m: 1 }} >Identificar se já lançou, o nível de conhecimento de lançamento</Typography>
              <Typography variant="h5" sx={{ borderRadius: 2, px: 1, m: 1 }} >Análise de erros (pixel, página, rede social, promessa e etc)</Typography>
              <Typography variant="h5" sx={{ borderRadius: 2, px: 1, m: 1 }} >Procurar a melhor forma: pessoalmente, através de uma ponte - conhecido, amigo em comum - ou online. Preferencialmente direct ou email.</Typography>
              <Typography variant="h5" sx={{ borderRadius: 2, px: 1, m: 1 }} >Abordagem: tentar criar rapport, pontos em comum,
                identificação. Explique o que você faz, os seus melhores números, os melhores números do mercado na área dele dentro do digital, e como você vê o futuro dos dois, em curto, médio e longo prazo (3 meses, 1 ano, 2 anos)</Typography>
            </Grid>
          </Grid>
          <Typography variant="h5" sx={{ borderRadius: 2, backgroundColor: '#fff', border: `2px solid #CF2CF6`, p: 0.6, px: 2, m: 1, textAlign: 'center' }} >ESPECIALISTA ABORDANDO LANÇADOR</Typography>
          <Grid container>
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <Typography variant="h5" sx={{ borderRadius: 2, color: "#CF2CF6", p: 0.4, px: 1, m: 1, textTransform: 'uppercase' }} >ANALISAR O LANÇADOR</Typography>
              <Typography variant="h5" sx={{ borderRadius: 2, px: 1, m: 1 }} >Resultados. Conversar com as pessoas que ele já lançou, se tiver acesso. Ver se tem alguma ponte.</Typography>
              <Typography variant="h5" sx={{ borderRadius: 2, px: 1, m: 1 }} ><b>“Olá, meu nome é Marcos. Acompanho seu trabalho e gosto muito. Gostaria de falar com você. Qual seu contato?”</b></Typography>
              <Typography variant="h5" sx={{ borderRadius: 2, px: 1, m: 1 }} ><b>“Estou querendo escalar, gostaria da sua ajuda”.</b></Typography>
              
            </Grid>
          </Grid>
        </Box>
      </AccordionDetails>
    </Accordion>
    
  )
}

export default Approach