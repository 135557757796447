import React from "react";
import NicheForm from "./NicheForm";
import Creatives from "./Creatives";
import RegistrationListForm from "./RegistrationListForm";
import EventForm from "./EventForm";
import SalesVideoScriptForm from "./SalesVideoScriptForm";
import RegistrationDomainForm from "./RegistrationDomainForm";
import ConfigForm from "./ConfigForm";
import NotificationsForm from "./NotificationForm";
import CreativesRecForm from "./CreativesRecForm";
import CapPageForm from "./CapPageForm";
import ThankPageForm from "./ThankPageForm";
import EmailMarketingForm from "./EmailMarketingForm"
import PixelForm from "./PixelForm"
import WhatsForm from "./WhatsForm";
import TestForm from "./TestForm";
import CampaignForm from "./CampaignForm";
import TestCampaignForm from "./TestCampaignForm";
import TrafficTable from "./TrafficTable";
import SalesPageForm from "./SalesPageForm";
import DeliverableForm from "./DeliverableForm"
import BonusForm from "./BonusForm";
import PriceForm from "./PriceForm";
import WarrantyForm from "./WarrantyForm";
import PixelSalesForm from "./PixelSalesForm";
import LinkCheckoutForm from "./LinkCheckoutForm";
import VerifyOneForm from "./VerifyOneForm";
import VerifyTwoForm from "./VerifyTwoForm";
import RecordTrainingForm from "./RecordTrainingForm";
import OptimizationOneForm from "./OptimizationOneForm";
import OptimizationTwoForm from "./OptimizationTwoForm";
import LaunchingScriptForm from "./LaunchingScriptForm";
import NotificationsPosForm from "./NotificationPosForm";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { indigo } from "@mui/material/colors";
import AvatarForm from "./AvatarForm";
import RomeForm from "./RomeForm";
import useFormulaById from '@/queries/useFormulaById';
import useSharedFListId from "@/pages/Shared/queries/useSharedFListId";
import CALsForm from "./CALsForm";
import CreateWhastsForm from "./CreateWhastsForm";
import { useParams } from "react-router-dom";

function RenderStepFormula({steps}) {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const { option } = useParams();

  const apiHook = option === "launchings" ? useFormulaById : useSharedFListId;

  let launching = apiHook;
  let path

  if (option === "launchings") {
    path = "formulalaunchings"
  }else{
    path = "formulashared"
  }

  switch (steps) {
    case "preparacao":
      return (
        <Box sx={{ gap: 1, display: 'flex', flexDirection: "column" }}>
          <Box sx={{ p: 1, borderRadius: 2, border: `solid 1px ${indigo[500]}`, backgroundColor: indigo[200] }}>
            <Typography sx={{ p: 0.5 }}>Preliminar</Typography>
            <RegistrationDomainForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="1" />
            <ConfigForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="2" />
            <CreateWhastsForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="3" />            
          </Box>
          <Box sx={{ p: 1, borderRadius: 2, border: `solid 1px ${indigo[500]}`, backgroundColor: indigo[200] }}>
            <Typography sx={{ p: 0.5 }}>CLI - Construção de lista de inscritos</Typography>
            <CreativesRecForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="3" />
            <EmailMarketingForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="4" />
            <PixelForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="5" />
            <WhatsForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="6" />
            <TestForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="7" />
            <CampaignForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="8" />
            {/* <TestCampaignForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="9" /> */}
          </Box>
          <Box sx={{ p: 1, borderRadius: 2, border: `solid 1px ${indigo[500]}`, backgroundColor: indigo[200] }}>
            <Typography sx={{ p: 0.5 }}>A.I. Aquecimento de inscritos</Typography>
            <TestCampaignForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="9" />
          </Box>
          <Box sx={{ p: 1, borderRadius: 2, border: `solid 1px ${indigo[500]}`, backgroundColor: indigo[200] }}>
            <Typography sx={{ p: 0.5 }}>Lançamento (Webnário)</Typography>
          </Box>
          <Box sx={{ p: 1, borderRadius: 2, border: `solid 1px ${indigo[500]}`, backgroundColor: indigo[200] }}>
            <Typography sx={{ p: 0.5 }}>Carrinho</Typography>
            <PixelSalesForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="10" />
          </Box> 
        </Box>
      )
    case "execucao":
      return (
        <Box sx={{ gap: 1, display: 'flex', flexDirection: "column" }}>
          <Box sx={{ p: 1, borderRadius: 2, border: `solid 1px ${indigo[500]}`, backgroundColor: indigo[200] }}>
            <Typography sx={{ p: 0.5 }}>Preliminar</Typography>
            <RecordTrainingForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="1" />
          </Box>
          <Box sx={{ p: 1, borderRadius: 2, border: `solid 1px ${indigo[500]}`, backgroundColor: indigo[200] }}>
            <Typography sx={{ p: 0.5 }}>CLI - Construção de lista de inscritos</Typography>
            <OptimizationOneForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="2" />
            <OptimizationTwoForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="3" />
          </Box>
          <Box sx={{ p: 1, borderRadius: 2, border: `solid 1px ${indigo[500]}`, backgroundColor: indigo[200] }}>
            <Typography sx={{ p: 0.5 }}>A.I. Aquecimento de inscritos</Typography>
            <VerifyOneForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="4" />
          </Box>
          <Box sx={{ p: 1, borderRadius: 2, border: `solid 1px ${indigo[500]}`, backgroundColor: indigo[200] }}>
            <Typography sx={{ p: 0.5 }}>Lançamento (Webnário)</Typography>
            <VerifyTwoForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="5" />
          </Box>
          <Box sx={{ p: 1, borderRadius: 2, border: `solid 1px ${indigo[500]}`, backgroundColor: indigo[200] }}>
            <Typography sx={{ p: 0.5 }}>Carrinho</Typography>
            <LinkCheckoutForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="6" />            
          </Box>
        </Box>
      )
    case "cli":
      return (
        <Box sx={{ gap: 1, display: 'flex', flexDirection: "column" }}>
          <Box sx={{ p: 1, borderRadius: 2, border: `solid 1px ${indigo[500]}`, backgroundColor: indigo[200] }}>
            <Typography sx={{ p: 0.5 }}>Tabela de tráfego</Typography>
            <TrafficTable path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="12" />
          </Box>
        </Box>
      )  
    default:
      return (
        <Box sx={{ gap: 1, display: 'flex', flexDirection: "column" }}>
          <Box sx={{ p: 1, borderRadius: 2, border: `solid 1px ${indigo[500]}`, backgroundColor: indigo[200] }}>
            <Typography sx={{ p: 0.5 }}>Preliminar</Typography>
            <NicheForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="1" />
            <AvatarForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="2" />
            <RomeForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="3" />
            <EventForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="4" />
          </Box>
          <Box sx={{ p: 1, borderRadius: 2, border: `solid 1px ${indigo[500]}`, backgroundColor: indigo[200] }}>
            <Typography sx={{ p: 0.5 }}>CLI - Construção de lista de inscritos</Typography>
            <Creatives path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="5" />
            <RegistrationListForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="6" />
            <CapPageForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="7" />
            <ThankPageForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="8" />
          </Box>
          <Box sx={{ p: 1, borderRadius: 2, border: `solid 1px ${indigo[500]}`, backgroundColor: indigo[200] }}>
            <Typography sx={{ p: 0.5 }}>A.I. Aquecimento de inscritos</Typography>
            {/* <>Incluir CALs</> */}
            <CALsForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="9" />
            <NotificationsForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="10" />
          </Box>
          <Box sx={{ p: 1, borderRadius: 2, border: `solid 1px ${indigo[500]}`, backgroundColor: indigo[200] }}>
            <Typography sx={{ p: 0.5 }}>Lançamento (Webnário)</Typography>
            <LaunchingScriptForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="11" />
            <DeliverableForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="13" />
            <BonusForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="14" />
            <PriceForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="15" />
            <WarrantyForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="16" />
          </Box>
          <Box sx={{ p: 1, borderRadius: 2, border: `solid 1px ${indigo[500]}`, backgroundColor: indigo[200] }}>
            <Typography sx={{ p: 0.5 }}>Carrinho</Typography>
            <SalesVideoScriptForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="17" />
            <SalesPageForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="18" />
            <NotificationsPosForm path={path} useLauncherfyById={launching} handleChange={handleChange} expanded={expanded} panel="19" />
          </Box>
        </Box>
      );
  }
}

export default RenderStepFormula
