import React from "react";
import Title from "@/components/Title";
import UserContent from "./UserContent";
import LaunchingContent from "./LaunchingContent";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";
import OrganizationContent from "./OrganizationContent";
import { useUser } from "@/reducers/user";
import SubscriptionContent from "./SubscriptionContent";

function Container() {
  const [user] = useUser();

  const plans = [
    "semente",
    "fundacao",
    "mapalx"
  ]

  const hasPlan = plans.some(plan => (user?.subscriptionStatus ?? '').startsWith(plan));

  return (
    <Box sx={{display: "flex", gap: 1, flexDirection: "column"}}>
      <Paper sx={{ borderRadius: 3}}>
        <Title name="Perfil" />
        <Box
          sx={{
            padding: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            gap: 1,
          }}
        >          
          <UserContent />
          <Box sx={{alignSelf: {xs: 'center', md: 'flex-end'}}}>
            <Button 
              component={Link}
              to="/profile/edit"
              variant="outlined"
              size="small"  
            >
              Editar
            </Button>
          </Box>
        </Box>
        <Grid container>
          <Grid item xs={12} md={4}>
            <LaunchingContent />
          </Grid>
          <Grid item xs={12} md={8}>
            <SubscriptionContent />
          </Grid>
        </Grid>
      </Paper>
      {hasPlan && !user.cancelAtPeriodEnd &&
        <OrganizationContent/>     
      }
    </Box>
  );
}

export default Container;
