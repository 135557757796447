import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import { green, grey, indigo, red } from '@mui/material/colors';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import api from '@/services/api';
import { useParams } from 'react-router-dom';
import AlertContext from '../Alert/AlertContext';
import { useFieldChange } from './useFieldChange';
import { useQueryClient } from '@tanstack/react-query';
import { useCustomQueryClient } from './hooks/useCustomQueryClient';
import TextareaAutosize from '@mui/material/TextareaAutosize';

function CreatePagesForm({ handleChange, expanded, panel, path, useLauncherfyById }) {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const invalidate = useCustomQueryClient(queryClient)
  const [btnStatus, setBtnStatus] = useState(false);
  const textFieldLabelToKeyMap = useMemo(() => ({
    "Links": "pagesLink",
  }), []);

  const siteCheckboxToKeyMap = useMemo(() => ({
    "Criar página de captura": "cpcrck", 
    "Criar página de obrigado": "tpcrck"
  }), []);

  const defaultTextLabels = useMemo(() => (["Links"]), []);
  const defaultSiteCheckbox = useMemo(() => (["Criar página de captura", "Criar página de obrigado"]), []);

  const { showAlert } = useContext(AlertContext);
  const { data: launching, isSuccess } = useLauncherfyById(id);

  const { handleSubmit, register, control, getValues, setValue, reset } = useForm({
    defaultValues: {
      textFieldList: defaultTextLabels.map(item => ({ item: '' })),
      siteFieldList: defaultSiteCheckbox.map(item => ({ label: item, checked: false })),
    },
  });
  const { handleFieldChange, allFieldsFilled, updateValues } = useFieldChange(getValues, setValue, setBtnStatus);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isSuccess && launching) {
          const settings = [
            { name: "Links", value: launching.pagesLink },

          ];

          const textFieldList = settings.map(setting => {
            return { item: setting.value };
          });

          const siteFieldList = defaultSiteCheckbox.map(label => {
            return { label, checked: !!launching[siteCheckboxToKeyMap[label]] };
          });

          reset({ textFieldList, siteFieldList });
          setBtnStatus(false);
        }
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };

    fetchData();
    updateValues();
  }, [id, reset, launching, isSuccess, defaultSiteCheckbox, siteCheckboxToKeyMap, updateValues]);

  const { fields: textFieldFields } = useFieldArray({
    control,
    name: 'textFieldList',
  });

  const { fields: siteFieldFields } = useFieldArray({
    control,
    name: 'siteFieldList',
  });


  const onSubmit = async (data) => {
    try {
      const textFieldListValues = data.textFieldList.map((field, index) => ({
        key: textFieldLabelToKeyMap[defaultTextLabels[index]],
        value: field.item
      }));
      const siteFieldListValues = data.siteFieldList.map((field, index) => ({
        key: siteCheckboxToKeyMap[defaultSiteCheckbox[index]],
        value: field.checked
      }));
      const fieldData = Object.fromEntries([...textFieldListValues, ...siteFieldListValues].map(({ key, value }) => [key, value]));
      await api.patch(`/api/${path}/${id}/`, fieldData);

      invalidate.invalidateQueries()
      setBtnStatus(false);
      showAlert("Dados atualizados com sucesso!", "success");

    } catch (error) {
      console.error("Erro ao atualizar os dados: ", error);
      showAlert("Ocorreu um erro ao tentar atualizar os dados. Por favor, tente novamente.", "error");
    }
  };

  useEffect(() => {
    const defaultValues = `[Inscrição Orgânico]: https://${launching?.domain || "<seu_domínio>"}/inscrever-org \n[Inscrição Meta]: https://${launching?.domain || "<seu_domínio>"}/inscrever-ig \n[Inscrição Google]: https://${launching?.domain || "<seu_domínio>"}/inscrever-yt \n------------------------------------------------------------------------------------------- \n[Obrigado Orgânico]: https://${launching?.domain || "<seu_domínio>"}/obrigado-org \n[Obrigado Meta]: https://${launching?.domain || "<seu_domínio>"}/obrigado-ig \n[Obrigado Google]: https://${launching?.domain || "<seu_domínio>"}/obrigado-yt`

    reset({
      ...getValues(),
      textFieldList: textFieldFields.map((field, index) => ({ ...field, item: defaultValues })),
    });
  }, [launching?.domain]);

  return (
    <Accordion expanded={expanded === `panel${panel}`} onChange={handleChange(`panel${panel}`)}
      sx={{ borderRadius: 2, mb: 0.2, p: 0.5, position: 'initial', border: `solid 1px ${indigo[100]}` }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${panel}bh-content`}
        id={`panel${panel}bh-header`}
        sx={{
          borderRadius: 2,
          minHeight: 0,
          ml: 1,
          '&.Mui-expanded': {
            minHeight: 0,
          },
          '&::before': { content: '""', position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)', width: 10, height: 10, borderRadius: '50%', backgroundColor: allFieldsFilled ? green[500] : red[400] },
        }}
      >
        <Typography sx={{ width: '100%', flexShrink: 0 }}>
          Criar páginas
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 1, pt: 2 }}>
        <Box
          component="form" onSubmit={handleSubmit(onSubmit)}
          sx={{
            display: "flex",
            flexDirection: "column",
            // width: "100%",
            gap: 1,
          }}>
          <Box sx={{ display: "flex", flexDirection: "row", gap: 1, backgroundColor: indigo[50], p: 2, borderRadius: 2 }} >
            <Grid container display="flex" justifyContent="center" spacing={1}>
              <Grid item xs={12} sm={4} >
                <Grid container gap={1} display="flex" direction="column" sx={{ bgcolor: indigo[100], borderRadius: 2, height: '100%' }}>
                  <Typography variant="h5" sx={{ bgcolor: '#4267B2', p: 1, borderRadius: 2, color: '#fff' }}>Site</Typography>
                  {siteFieldFields.map((field, index) => (
                    <Box display="flex" alignItems="center" sx={{ gap: 1, px: 1 }} key={field.id}>
                      <Controller
                        name={`siteFieldList.${index}.checked`}
                        control={control}
                        defaultValue={field.checked}
                        render={({ field }) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={field.value}
                                onChange={(e) => {
                                  field.onChange(e.target.checked);
                                  handleFieldChange(e, index, "siteFieldList");
                                  setBtnStatus(true);
                                }}
                                sx={{
                                  color: '#4267B2',
                                  '&.Mui-checked': {
                                    color: '#4267B2',
                                  }
                                }}
                              />
                            }
                            label={defaultSiteCheckbox[index]}
                            sx={{
                              width: '100%'
                            }}
                          />
                        )}
                      />
                    </Box>
                  ))}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Grid container gap={1} display="flex" justifyContent="center" direction="column" alignSelf="flex-start" sx={{ bgcolor: indigo[100], borderRadius: 2 }}>
                  <Typography variant="h5" sx={{ bgcolor: indigo[600], p: 1, borderRadius: 2, color: '#fff' }}>
                    Links </Typography>
                  {textFieldFields.map((field, index) => (
                    <Box alignItems="center" sx={{ gap: 1, p: 1, display: 'flex', flexDirection: 'column'}} key={field.id}>
                      <TextareaAutosize
                        {...register(`textFieldList.${index}.item`)}
                        variant="outlined"
                        size="small"
                        style={{
                          width: '100%',
                          height: '200px',
                          borderRadius: '4px',
                          borderColor: grey[400],
                          padding: '8px',
                          fontFamily: "Roboto",
                          font: 'inherit'
                        }}
                        onChange={(event) => {
                          handleFieldChange(event, index, "textFieldList");
                          setBtnStatus(true);
                        }}
                      />
                    </Box>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              gap: 1,
            }}>
            {btnStatus ?
              <Button type="submit">Salvar</Button>
              :
              <Button disabled>Salvar</Button>
            }
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

export default CreatePagesForm;
