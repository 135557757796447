import React from "react";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Button from '@mui/material/Button';
import { useStep } from '@/reducers/step';
import '@/fonts.css';

const textColorMapping = {
  "#212121": "#fff",
  "#7CFB5D": "black",
  "#fff": "black",
  "#CF2CF6": "#fff",
  "#6310D6": "#fff",
  "#89E6FC": "black",
  "#F3AC3C": "black"
};

const stepList = [
  { name: 'FASE 00', color: "#212121" },
  { name: 'FASE 01', color: "#7CFB5D" },
  { name: 'FASE 02', color: "#fff" },
  { name: 'FASE 03', color: "#CF2CF6" },
  { name: 'FASE 04', color: "#6310D6" },
  { name: 'FASE 05', color: "#89E6FC" },
  { name: 'FASE 06', color: "#F3AC3C" },
];

function Stages() {
  const [step, setStep] = useStep();

  const handleClick = (index, step) => {
    setStep({
      type: "STEP_CHANGE_VALUE",
      payload: {
        step: index,
        title: step.name
      },
    });
  };

  return (
    <Paper sx={{ borderRadius: 3 }}>
      <Box sx={{ overflow: 'auto', width: '100%'}}>
        <List sx={{  display: 'flex', flexDirection: 'row', px: 1, gap: '6px', width: 'calc(100vw - 78px)', justifyContent: 'center' }}>
          {stepList.map((step, index) => {
            const bgColor = step.color;
            const textColor = textColorMapping[bgColor];

            return (
              <Button
                key={index}
                variant="contained"
                sx={{
                  p: 0.2,
                  px: 1,
                  fontSize: 12,
                  background: `linear-gradient(to right, ${bgColor}, ${bgColor})`,
                  py: 1.2,
                  px: 1,
                  borderRadius: 2,
                  '&:hover': {
                    background: `linear-gradient(to right, ${bgColor}, ${bgColor})`,
                  },
                  display: 'flex',
                  flexDirection: 'column'
                }}
                onClick={() => handleClick(index, step)}
              >
                <Typography
                  variant="h1"
                  sx={{
                    color: textColor,
                    fontFamily: "'Alaca Bold', sans-serif"
                  }}
                >
                  {step.name}
                </Typography>
              </Button>
            );
          })}
        </List>
      </Box>
    </Paper>
  );
}

export default Stages;
