import { useContext, createContext, useReducer } from "react";

const initialState = {
  uid: null,
  logged: false,
  links: "",
  subscriptionStatus: null,
};

const actions = {
  LINK_CHANGE_VALUE: (state, action) => {
    return { ...state, ...action.payload };
  },
  LINK_RESET: () => {
    return initialState;
  },
};

const LinkContext = createContext();

function reducer(state, action) {
  const fn = actions[action.type];

  if (!fn) {
    console.warn("Reducer", action.type, "not found.");
    return state;
  }

  return fn(state, action);
}

export function useLink() {
  return useContext(LinkContext);
}

export function LinkProvider({ children }) {
  const provider = useProviderLink();

  return (
    <LinkContext.Provider value={provider}>{children}</LinkContext.Provider>
  );
}

function useProviderLink() {
  const [state, dispatch] = useReducer(reducer, initialState);

  return [state, dispatch];
}
