import { useContext, createContext, useReducer } from "react";

const initialState = {
  organization: null,
};

const actions = {
  ORGANIZATION_CHANGE_VALUE: (state, action) => {
    return { ...state, organization: action.payload.organization };
  },
  ORGANIZATION_RESET: () => {
    return initialState;
  },
};

const OrganizationContext = createContext();

function reducer(state, action) {
  const fn = actions[action.type];

  if (!fn) {
    console.warn("Reducer", action.type, "not found.");
    return state;
  }

  return fn(state, action);
}

export function useOrganization() {
  return useContext(OrganizationContext);
}

export function OrganizationProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <OrganizationContext.Provider value={[state, dispatch]}>{children}</OrganizationContext.Provider>
  );
}
